import PropTypes from 'prop-types';
import { variant, compose } from 'styled-system';
import spaceVariants from './spaceVariants';

const responsiveVariantValues = {
  [spaceVariants.COMPACT]: {
    px: [0.5, 0.5, 0.5, 1, 1.5, 1.5],
    py: [0.5, 0.5, 0.5, 1, 1.5, 1.5],
  },
  [spaceVariants.NORMAL]: {
    px: [1, 1, 1, 1.5, 2, 2],
    py: [1, 1, 1, 1.5, 2, 2],
  },
  [spaceVariants.COMFORT]: {
    px: [1.5, 1.5, 1.5, 2, 2.5, 2.5],
    py: [1.5, 1.5, 1.5, 2, 2.5, 2.5],
  },
};

const padding = variant({
  prop: 'pv',
  variants: responsiveVariantValues,
});
padding.propTypes = {
  pv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const paddingX = variant({
  prop: 'pxv',
  variants: {
    [spaceVariants.COMPACT]: {
      px: responsiveVariantValues[spaceVariants.COMPACT].px,
    },
    [spaceVariants.NORMAL]: {
      px: responsiveVariantValues[spaceVariants.NORMAL].px,
    },
    [spaceVariants.COMFORT]: {
      px: responsiveVariantValues[spaceVariants.COMFORT].px,
    },
  },
});
paddingX.propTypes = {
  pxv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const paddingL = variant({
  prop: 'plv',
  variants: {
    [spaceVariants.COMPACT]: {
      pl: responsiveVariantValues[spaceVariants.COMPACT].px,
    },
    [spaceVariants.NORMAL]: {
      pl: responsiveVariantValues[spaceVariants.NORMAL].px,
    },
    [spaceVariants.COMFORT]: {
      pl: responsiveVariantValues[spaceVariants.COMFORT].px,
    },
  },
});
paddingL.propTypes = {
  plv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const paddingR = variant({
  prop: 'prv',
  variants: {
    [spaceVariants.COMPACT]: {
      pr: responsiveVariantValues[spaceVariants.COMPACT].px,
    },
    [spaceVariants.NORMAL]: {
      pr: responsiveVariantValues[spaceVariants.NORMAL].px,
    },
    [spaceVariants.COMFORT]: {
      pr: responsiveVariantValues[spaceVariants.COMFORT].px,
    },
  },
});
paddingR.propTypes = {
  prv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const paddingY = variant({
  prop: 'pyv',
  variants: {
    [spaceVariants.COMPACT]: {
      py: responsiveVariantValues[spaceVariants.COMPACT].py,
    },
    [spaceVariants.NORMAL]: {
      py: responsiveVariantValues[spaceVariants.NORMAL].py,
    },
    [spaceVariants.COMFORT]: {
      py: responsiveVariantValues[spaceVariants.COMFORT].py,
    },
  },
});
paddingY.propTypes = {
  pyv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const paddingT = variant({
  prop: 'ptv',
  variants: {
    [spaceVariants.COMPACT]: {
      pt: responsiveVariantValues[spaceVariants.COMPACT].py,
    },
    [spaceVariants.NORMAL]: {
      pt: responsiveVariantValues[spaceVariants.NORMAL].py,
    },
    [spaceVariants.COMFORT]: {
      pt: responsiveVariantValues[spaceVariants.COMFORT].py,
    },
  },
});
paddingT.propTypes = {
  ptv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const paddingB = variant({
  prop: 'pbv',
  variants: {
    [spaceVariants.COMPACT]: {
      pb: responsiveVariantValues[spaceVariants.COMPACT].py,
    },
    [spaceVariants.NORMAL]: {
      pb: responsiveVariantValues[spaceVariants.NORMAL].py,
    },
    [spaceVariants.COMFORT]: {
      pb: responsiveVariantValues[spaceVariants.COMFORT].py,
    },
  },
});
paddingB.propTypes = {
  pbv: PropTypes.oneOf(Object.values(spaceVariants)),
};

export default compose(
  padding,
  paddingX,
  paddingY,
  paddingT,
  paddingR,
  paddingB,
  paddingL
);
