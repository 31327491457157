import React, { useState } from 'react';
import { Spacer, pdfActions } from '@rmstransactions/components';
import { IconLabelWrapper } from '../GoodBehaviourPage.styled';
import {
  Button,
  IconPrint,
  IconDownload,
  InPageAlert,
  H3,
} from '@snsw/react-component-library';
import { capitalise } from 'utils/helper';
import errorMessages from 'components/Error/errorMessages';

const KeepAReceipt: React.FC<KeepAReceiptProps> = ({
  receiptFileName,
  receiptPDFBase64,
  onClick,
}) => {
  const [printDownloadError, setPrintDownloadError] = useState<
    null | 'Download' | 'Print'
  >(null);

  const handlePrint = () => {
    if (!receiptPDFBase64) {
      setPrintDownloadError('Print');
      return;
    }
    try {
      pdfActions.printBase64PDF(receiptPDFBase64);
      setPrintDownloadError(null);
      onClick('button_link_small', 'Print');
    } catch {
      setPrintDownloadError('Print');
    }
  };

  const handleDownloadReceipt = () => {
    if (!receiptPDFBase64) {
      setPrintDownloadError('Download');
      return;
    }
    try {
      pdfActions.downloadBase64PDF(receiptPDFBase64, receiptFileName);
      setPrintDownloadError(null);
    } catch {
      setPrintDownloadError('Download');
    }
  };

  return (
    <>
      <Spacer mt={1.5} mb={0} data-testid='downloadReceipt'>
        <H3>Download or share your receipt</H3>
        <Spacer mb={1} />
        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconPrint />
          </div>
          <Button variant='link' data-testid='Print' onClick={handlePrint}>
            Print
          </Button>
        </IconLabelWrapper>
        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconDownload />
          </div>
          <Button
            variant='link'
            data-testid='Download'
            onClick={handleDownloadReceipt}
          >
            Download transaction receipt
          </Button>
        </IconLabelWrapper>
      </Spacer>
      {printDownloadError && (
        <InPageAlert
          variant='error'
          title={capitalise(printDownloadError) + ' ' + 'failed'}
          data-testid='DownloadError'
        >
          <Spacer mt={0.5} />
          {errorMessages.download.failed}
        </InPageAlert>
      )}
    </>
  );
};

export default KeepAReceipt;

interface KeepAReceiptProps {
  receiptFileName: string;
  receiptPDFBase64: string;
  onClick: (eventAction: string, eventName: string) => void;
}
