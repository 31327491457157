import { Constants } from 'constants/constants';

const anchorTagLink = `<a href="LINK_URL"
    target='_blank'
    rel='noopener noreferrer'
    className='TextLink'  
  >LINK_NAME</a>`;

const serviceCentreAnchorTag = `<a href=${Constants.SERVICE_CENTER_LINK}
    target='_blank'
    rel='noopener noreferrer'
    className='TextLink'    
  >service centre</a>`;

export const getAccessibleMessage = (message: string) => {
  if (message) {
    return addAccessibleText(addServiceCentreLink(message));
  } else {
    return message;
  }
};

export const addAccessibleText = (message: string) => {
  if (message) {
    let newMessage = message;
    const linkSeparatorIndex = message.indexOf('|');
    if (linkSeparatorIndex > -1) {
      const linkStartPosition = message.indexOf('[');
      const linkEndPosition = message.indexOf(']');
      const linkName = message.substring(
        linkStartPosition + 1,
        linkSeparatorIndex
      );
      const linkUrl = message.substring(
        linkSeparatorIndex + 1,
        linkEndPosition
      );
      const anchorTagToBeReplaced = anchorTagLink
        .replace('LINK_URL', linkUrl)
        .replace('LINK_NAME', linkName);
      newMessage =
        message.substring(0, linkStartPosition) +
        anchorTagToBeReplaced +
        message.substring(linkEndPosition + 1);
    }
    return newMessage;
  }
  return message;
};

const addServiceCentreLink = (content: string) => {
  return content.replace('[service centre]', serviceCentreAnchorTag);
};
