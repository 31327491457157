const sessionStorageKey = 'x-token';

const UserToken = {
  getValue() {
    return sessionStorage.getItem(sessionStorageKey);
  },
  setValue(token) {
    sessionStorage.setItem(sessionStorageKey, token);
  },
  clear() {
    sessionStorage.setItem(sessionStorageKey, null);
  },
  getStorageKey: () => sessionStorageKey,
};
export default UserToken;
