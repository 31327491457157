import styled from 'styled-components';

const CardDetail = styled.div`
  border-radius: 6px;
  border: 2px solid rgb(222, 227, 229);
  margin: 10px 0;
  padding: 24px;
`;

export default CardDetail;
