import React from 'react';
import { colors } from '@snsw/react-component-library';

const Print = ({ color = colors.black, width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    focusable="false"
    aria-hidden="true"
  >
    <title>print</title>
    <path
      d="M48,5.33H16v13.33h32V5.33z M56,24c-1.47,0-2.67,1.2-2.67,2.67s1.2,2.67,2.67,2.67s2.67-1.2,2.67-2.67
      S57.47,24,56,24z M42.67,45.33V48H21.33v-2.67H42.67z M34.67,50.67v2.67H21.33v-2.67H34.67z M48,40H16v18.67h32V40z M53.33,0v18.67
      h8c1.47,0,2.67,1.2,2.67,2.67v29.33c0,1.47-1.2,2.67-2.67,2.67h-8V64H10.67V53.33h-8C1.2,53.33,0,52.14,0,50.67V21.33
      c0-1.47,1.2-2.67,2.67-2.67h8V0H53.33z"
    />
  </svg>
);

export default Print;
