import { HttpRequestHandler } from '@rmstransactions/components';

const UserSession = {
  isLoginUser() {
    return window.localStorage.getItem('isLoginUser') === 'Yes';
  },

  setLoggedInUser() {
    window.localStorage.setItem('isLoginUser', 'Yes');
  },

  resetLoggedInUser() {
    window.localStorage.removeItem('isLoginUser');
  },

  silentLogin(loginUrl: string) {
    window.location.href = HttpRequestHandler.appendApiKeyAsParam(loginUrl);
  },

  isLoggedOutUser() {
    return window.localStorage.getItem('isLoggedOutUser') === 'Yes';
  },

  setLoggedOutUser() {
    window.localStorage.setItem('isLoggedOutUser', 'Yes');
  },

  resetLoggedOutUser() {
    window.localStorage.removeItem('isLoggedOutUser');
  },

  login(loginUrl: string) {
    window.location.href = HttpRequestHandler.appendApiKeyAsParam(loginUrl);
  },

  setSilentLoginCalled() {
    window.sessionStorage.setItem('isSilentLoginCalled', 'Yes');
  },

  isSilentLoginCalled() {
    return window.sessionStorage.getItem('isSilentLoginCalled') === 'Yes';
  },

  resetSilentLoginCalled() {
    window.sessionStorage.removeItem('isSilentLoginCalled');
  },

  setRmsCustomerId(rmsCustomerId: string) {
    window.localStorage.setItem('rmsCustomerId', rmsCustomerId);
  },

  getRmsCustomerId() {
    return window.localStorage.getItem('rmsCustomerId');
  },

  resetRmsCustomerId() {
    window.localStorage.removeItem('rmsCustomerId');
  },

  setActiveSession() {
    window.sessionStorage.setItem('activeSession', 'yes');
  },

  hasActiveSession() {
    return window.sessionStorage.getItem('activeSession') === 'yes';
  },

  resetActiveSession() {
    window.sessionStorage.removeItem('activeSession');
  },

  clearUserSession(): void {
    window.localStorage.removeItem('isLoginUser');
    window.localStorage.removeItem('isLoggedOutUser');
    window.sessionStorage.removeItem('isSilentLoginCalled');
    window.localStorage.removeItem('rmsCustomerId');
    window.sessionStorage.removeItem('activeSession');
  },
};
export default UserSession;
