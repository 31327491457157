import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';

export const ContentWrapper = styled.div`
  max-width: 48rem;
`;

export const ButtonGroup = styled.div`
  padding-top: 3.5rem;
  .nextButton,
  .backButton {
    width: 100%;
  }
  .mobile-spacer {
    display: block;
    ${Breakpoint('sm')`
    display: none;
    `}
  }
`;
