import { getTimeElapsedInSeconds } from './AnalyticsService';
import AnalyticsSessionManager, {
  userLoginType,
} from './AnalyticsSessionManager';

declare global {
  interface Window {
    dataLayer: Record<string, any>[] | undefined;
    location: Location;
  }
}

// Google analytics variables
export const defaultAnalyticsNullValue = '(not set)';
type APIUserTypes = 'AuthenticatedLinked' | 'AuthenticatedUnLinked' | 'GUEST';
export const analyticsUserLoginMapedValues: Record<
  APIUserTypes,
  userLoginType
> = {
  AuthenticatedLinked: 'fully authenticated',
  AuthenticatedUnLinked: 'fully authenticated',
  GUEST: 'guest',
};
/**
 *  TODO: check and confirm things with other transaction for `transaction authenticated`
 *  for TLA transactions: guest => transaction authenticated
 */

const rmsTransactionTemplatePageInfo = {
  agency: 'Transport for NSW',
  product_team: 'RMS',
};

interface PushNewPageVisitedProps {
  pageName: string;
  transactionInfo: Omit<TransactionInfo, 'transaction_id'>;
  overridePageLocation?: string;
}
const pushNewPageVisited = ({
  pageName,
  transactionInfo,
  overridePageLocation,
}: PushNewPageVisitedProps) => {
  const userInfo = {
    user_id: AnalyticsSessionManager.getUserID() ?? defaultAnalyticsNullValue,
    user_login_status:
      AnalyticsSessionManager.getUserLoginType() ?? defaultAnalyticsNullValue,
    user_return_status:
      AnalyticsSessionManager.getUserReturnStatus() ??
      defaultAnalyticsNullValue,
  };

  const transactionStartTime =
    AnalyticsSessionManager.getTransactionStartTime() ?? new Date();
  const timeElapsed = getTimeElapsedInSeconds(transactionStartTime);
  const { origin, pathname } = window.location;
  const pageInfo = {
    ...rmsTransactionTemplatePageInfo,
    page_title: pageName,
    page_location: overridePageLocation ?? origin + pathname,
  };

  const newPageDataLayer: GoogleAnlayticsPageDataLayer = {
    event: 'v_page_info',
    user_info: userInfo,
    page_info: pageInfo,
    transaction_info: {
      ...transactionInfo,
      transaction_id:
        AnalyticsSessionManager.getSessionID() ?? defaultAnalyticsNullValue,
    },
    time_elapsed: timeElapsed,
  };
  window.dataLayer?.push(newPageDataLayer);
};

/**
 * run this function in jest tests if you are getting window.dataLater push errors
 * it is caused by a missing dataLayer only created in the static JS
 */
const jestSetGoogleAnalyticsStore = () => {
  window.dataLayer = window.dataLayer || [];
};

interface ButtonClickProps {
  action: string;
  buttonName: string;
  transactionStartTime : Date | null;
  destinationUrl: string;
}

const pushOnButtonClick = ({
  action,
  buttonName,
  transactionStartTime,
  destinationUrl
}: ButtonClickProps ) => {
  window.dataLayer?.push({
    event: 'component_interaction',
    component_type: action,
    component_text: buttonName,
    section_id: 'page_content',
    position_id: '1',
    destination_url: destinationUrl,
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime),
  });
};

const analyticsServiceV2 = {
  pushNewPageVisited,
  defaultAnalyticsNullValue,
  analyticsUserLoginMapedValues,
  jestSetGoogleAnalyticsStore,
  pushOnButtonClick
};

export default analyticsServiceV2;

interface GoogleAnlayticsPageDataLayer {
  event: string;
  page_info: PageInfo;
  user_info: UserInfo;
  transaction_info: TransactionInfo;
  time_elapsed: number;
}
interface PageInfo {
  page_title: string;
  page_location: string;
  agency: string;
  product_team: string;
}
interface UserInfo {
  user_id: string;
  user_login_status: string;
}
interface TransactionInfo {
  transaction_name: string;
  transaction_step: string;
  transaction_type: string;
  transaction_sub_type: string;
  transaction_id: string;
}
