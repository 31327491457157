import styled from 'styled-components';
import Size from './Size';

function getWidthString(span) {
  if (!span) return;
  const width = (span / 12) * 100;
  // eslint-disable-next-line consistent-return
  return `width: ${width}%`;
}

const Col = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 14px;
  padding-left: 14px;

  ${({ xs }) => (xs ? getWidthString(xs) : 'width: 100%')};

  @media only screen and (min-width: ${Size.sm}px) {
    ${({ sm }) => sm && getWidthString(sm)};
  }

  @media only screen and (min-width: ${Size.md}px) {
    ${({ md }) => md && getWidthString(md)};
  }

  @media only screen and (min-width: ${Size.lg}px) {
    ${({ lg }) => lg && getWidthString(lg)};
  }

  @media only screen and (min-width: ${Size.xl}px) {
    ${({ xl }) => xl && getWidthString(xl)};
  }
`;

export default Col;
