import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';

const inlineErrorMessage = [
  'CUSTOMER_NOT_FOUND',
  'LICENCE_NOT_FOUND',
  'CUSTOMER_SURNAME_NOT_MATCH',
  'DL_PC_NOT_FOUND',
  'NO_DL_PC_FOR_CARD_STOCK_NUMBER',
  'NO_LATEST_DL_PC',
  'LETTER_ID_NOT_CUR_SUSP_NOTICE',
  'LETTER_ID_CUSTOMER_ID_MISMATCH',
  'LETTER_NOT_FOUND',
  'CUSTOMER_TO_COMPLETE_DKT',
];

export const getInlineErrorMessages = (
  messages: DriveMessageDetails[] | null
): DriveMessageDetails | null => {
  if (!messages) return null;

  return (
    messages.find((message) =>
      inlineErrorMessage.includes(message.identifier)
    ) || null
  );
};

export const getUnsuccessfulMessages = (
  messages: DriveMessageDetails[] | null
): DriveMessageDetails | null => {
  if (!messages) return null;

  return (
    messages.find(
      (message) =>
        message.severity === 'ERROR' &&
        !inlineErrorMessage.includes(message.identifier)
    ) || null
  );
};
