import React from 'react';

const defaultWidth = 32;
const defaultHeight = 29;
const aspectRatio = defaultHeight / defaultWidth;

const BrandEyeTest = ({ size = 32 }) => (
  <svg
    width={size}
    height={size * aspectRatio}
    viewBox='0 0 32 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>eye test</title>
    <ellipse
      cx='15.9972'
      cy='14.6906'
      rx='3.45622'
      ry='3.45622'
      fill='#C63642'
    />
    <circle
      cx='15.9965'
      cy='14.6928'
      r='6.91935'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.9971 14.6914C30.9971 14.6914 24.2782 22.0116 15.9971 22.0116C7.71597 22.0116 0.99707 14.6914 0.99707 14.6914C0.99707 14.6914 7.71597 7.37109 15.9971 7.37109C24.2782 7.37109 30.9971 14.6914 30.9971 14.6914Z'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.30982 6.60363L3.69922 3.76953'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26.5107 6.60363L28.1213 3.76953'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.9062 4.32364V1.19922'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.30982 22.627L3.69922 25.4611'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26.5107 22.627L28.1213 25.4611'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.9062 24.9082V28.0326'
      stroke='#233060'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default BrandEyeTest;
