import React from 'react';
import { Constants } from 'constants/constants';
import { HeadingStyle } from './ReveiwDeclarations.styled';
import { Question } from 'redux/slice/declarationForm';

interface ReviewDeclarationsProp {
  businessTransaction: string;
  questions: Question[];
}

const ReviewDeclarations: React.FC<ReviewDeclarationsProp> = ({
  businessTransaction,
  questions,
}) => {
  const declaration1 = (
    <>
      I declare that{' '}
      {questions[0]?.text.replace(
        'NSW Driver Licence',
        'the NSW Driver Licence'
      )}
    </>
  );
  const declaration2 = (
    <>I declare that {questions[1]?.text.replace('elect', 'am electing')}</>
  );
  const declaration3 =
    businessTransaction == Constants.ITOP1 ? (
      <>I declare that {questions[2]?.text}</>
    ) : (
      <>{questions[2]?.text.replace('I understand that', 'I declare that')}</>
    );

  const declaration4 =
    questions.length === 4 ? <>I declare that {questions[3]?.text}</> : null;

  return (
    <>
      <HeadingStyle>Declaration</HeadingStyle>
      <ul>
        <li>{declaration1}</li>
        <li>{declaration2}</li>
        <li>{declaration3}</li>
        {declaration4 !== null && <li>{declaration4}</li>}
      </ul>
    </>
  );
};

export default ReviewDeclarations;
