import React from 'react';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import { StyledInPageAlertMessage } from './NotificationMessage.styled';
import { getAccessibleMessage } from './NotificationHelpers';

const NotificationMessage: React.FC<NotificationMessageProps> = ({
  message,
  marginTop = false,
  marginBottom = false,
}) => {
  if (!message) return <></>;

  const { messageTitle, messageDescription, severity } = message;
  const variant =
    severity in alertVariantsMap ? alertVariantsMap[severity] : 'error';
  const displayMessage = getAccessibleMessage(messageDescription ?? '');

  return (
    <StyledInPageAlertMessage
      variant={variant}
      title={messageTitle ?? message}
      role='alert'
      $marginTop={marginTop}
      $marginBottom={marginBottom}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: displayMessage,
        }}
      />
    </StyledInPageAlertMessage>
  );
};

export default NotificationMessage;

interface NotificationMessageProps {
  message: DriveMessageDetails | null;
  marginTop?: boolean;
  marginBottom?: boolean;
}

const alertVariantsMap: Record<string, string> = {
  INFORMATION: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};
