import styled from 'styled-components';
import { tokens } from '@snsw/react-component-library';

export const receiptIconSize = 'md';

export const ActionWrapper = styled.div`
  margin: 1.5rem 0;

  &:first-child {
    margin-top: 0rem;
  }

  &:last-child {
    margin-bottom: 0rem;
  }
`;

export const ActionRow = styled.span`
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
`;

export const IconWrapper = styled.span`
  min-width: ${tokens.spacing.icons[receiptIconSize]}px;
`;

export const EmailFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1.5rem;
  }
`;

export const EmailLabel = styled.label`
  > div {
    margin-bottom: 1rem;
  }
`;
