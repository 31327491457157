import React, { useEffect } from 'react';
import { QuickfeedStyleOverride } from './../../index';

const Quickfeed = ({
  placeholder = 'QuickfeedPlaceholder',
  label = 'How was your experience?',
  environment = 'uat', // UAT and PROD only
  sourceApplication,
  ...rest
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://onegov.nsw.gov.au/quickfeed/js/quickfeed.min.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      quickFeed.init({
        placeholder,
        label,
        environment,
        sourceApplication,
      });
    };

    // unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <QuickfeedStyleOverride>
      <div id={placeholder} {...rest} />
    </QuickfeedStyleOverride>
  );
};

export default Quickfeed;
