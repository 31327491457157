import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import tokens from './tokens';
import { pxToRem } from './utils';
import space from './space';
import externalSvg from './external-link.svg';

const { font } = tokens;

const EtdbButton = styled.button`
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  height: 2.75rem;
  padding-top: ${(props) => (!!props.as ? '0.7rem' : '0')};
  padding-bottom: ${(props) => (!!props.as ? '0.7rem' : '0')};
  padding-left: ${(props) => (props.width ? 'unset' : '2.5rem')};
  padding-right: ${(props) => (props.width ? 'unset' : '2.5rem')};
  width: ${(props) => props.width || 'unset'};
  min-width: 4.75rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.45' : '1.0')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  display: inline-block;
  ${(props) =>
    props.external &&
    css`
      text-decoration: none;
      :after {
        content: '';
        background-image: url(${externalSvg});
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 4px;
      }
    `}

  @media (max-width: 480px) {
    height: 2.375rem;
    line-height: 0.7;
    ${(props) =>
      props.fullwidthmobile === 'true' &&
      css`
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      `}
  }

  ${space};
`;

const SecondaryButton = styled(EtdbButton)`
  border-radius: 8px;
  background-color: #f9fdff;
  border: 2px solid #c4d0d6;
  color: #2e5299;

  ${(props) =>
    props.hasError &&
    css`
      border-color: #e2173d;
      background-color: rgba(184, 18, 55, 0.05);
      color: #e2173d;
    `}

  :hover {
    background-color: #2e5299;
    background-color: #ffffff;
    border-color: #deeaf0;
  }
  :active {
    color: #002664;
    background-color: #e0e4e6;
    border-color: #929fa4;
  }
  :focus {
    outline: none;
  }
`;

export const TriggerWrapper = styled.div`
  display: contents;
`;

const StyledModal = styled.div`
  position: relative;
  width: 60%;
  min-height: 4rem;
  max-width: ${(props) => (props.size === 'small' ? '34rem' : '50rem')};
  z-index: 2001;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: 80%;
    justify-content: flex-start;
  }

  @media (max-width: 480px) {
    background-color: #f4f6fa;

    ${(props) =>
      props.mobileFullScreen &&
      css`
        width: 100%;
        height: 100%;
        border-radius: 0;
      `};
  }
`;

StyledModal.propTypes = {
  mobileFullScreen: PropTypes.bool,
  isConfirmation: PropTypes.bool,
};

StyledModal.Wrapper = styled.div`
  overflow-y: auto;
`;

StyledModal.Header = styled.div`
  padding: 2rem 2rem 1rem 2rem;
  font-weight: 500;
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
  @media (max-width: 480px) {
    padding: 1.25rem 2.5rem 1.25rem 1.25rem;
  }

  ${(props) =>
    props.slidingPanel &&
    css`
      border-bottom: solid 1px rgba(200, 200, 200, 0.5);
    `}
`;

StyledModal.Header.propTypes = {
  slidingPanel: PropTypes.bool,
  justifyContent: PropTypes.string,
};

StyledModal.Title = styled.h1`
  font-size: 1.25rem;
  margin: 0;
  line-height: 1.4;
`;

StyledModal.Body = styled.div`
  padding: 1.5rem 2rem 1rem 2rem;
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `};

  @media (max-width: 480px) {
    padding: 1rem 1.25rem;
  }
`;

StyledModal.Footer = styled.div`
  padding: 1rem 2rem 2rem 2rem;
  display: ${(props) => props.display || 'flex'};
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  @media (max-width: 480px) {
    padding: 1.5rem;
  }
`;

StyledModal.Footer.propTypes = {
  justifyContent: PropTypes.string,
  display: PropTypes.string,
};

StyledModal.DismissButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
  height: 4rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
`;

StyledModal.ConfirmationButton = styled(SecondaryButton)`
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid #c4d0d6;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export default StyledModal;
