import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomerName, IValidateDetails } from 'interfaces/IValidateCustomer';

export interface ValidateState {
  customerId: string | null;
  licenceNumber: string | null;
  licenceExpiryDate: string | null;
  customerName: ICustomerName | null;
  emailAddress: string;
}

export const initialState: ValidateState = {
  customerId: null,
  licenceNumber: null,
  licenceExpiryDate: null,
  customerName: {
    familyName: '',
    givenName: '',
    otherGivenNames: '',
    unstructuredName: '',
  },
  emailAddress: '',
};

export const validateSlice = createSlice({
  name: 'validate',
  initialState,
  reducers: {
    setValidateData: (state, action: PayloadAction<IValidateDetails>) => {
      state.customerId = action.payload.customerId;
      state.licenceNumber = action.payload.licenceNumber;
      state.licenceExpiryDate = action.payload.licenceExpiryDate;
      state.customerName = action.payload.customerName;
      state.emailAddress = action.payload.emailAddress;
    },
    resetValidateDetails: () => initialState,
  },
});

export const validateSliceActions = validateSlice.actions;
export const validateReducer = validateSlice.reducer;
