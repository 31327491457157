import styled from "styled-components";
import {Size} from ".";

export const ErrorMessageContainer = styled.span`
     .sr-only {
        position:absolute;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
     }
`;


export const ButtonGroup = styled.div`
  .buttons {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: ${Size.md + 1}px) {
    .button-n-plus-1 {
      margin-left: 24px;
    }
  }

  @media only screen and (max-width: ${Size.md}px) {
    .buttons {
      width: 100%;
    }
    .button-first,
    .button-n-plus-1 {
      width: 100%;
      margin-top: 0px;
    }
  }
`;
