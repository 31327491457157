import React from 'react';
import { Quickfeed } from '@rmstransactions/components';

const ThumbsUpThumbsDown: React.FC<ThumbsUpThumbsDownProps> = ({
  applicationName,
  label,
}) => {
  return (
    <Quickfeed
      sourceApplication={applicationName}
      label={label}
      environment={process.env.REACT_APP_ENV === 'prod' ? 'production' : 'uat'}
    />
  );
};

export default ThumbsUpThumbsDown;

interface ThumbsUpThumbsDownProps {
  applicationName: string;
  label: string;
}
