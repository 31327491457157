import { validation } from '@rmstransactions/components';

export const validateAlphaNumericCharacters = (
  number: string,
  numberType: string
): ValidationErrors | null => {
  if (number === '') {
    if (numberType === 'LICENCE_NUMBER') return 'LICENCE_NUMBER_EMPTY';
    else if (numberType === 'SUSPENSION_ID') return 'SUSPENSION_ID_EMPTY';
  }
  if (invalidAlphaNumericCharacters(number)) {
    if (numberType === 'SUSPENSION_ID') return 'SUSPENSION_ID_INVALID_FORMAT';
    else return 'SPECIAL_CHARACTER';
  }
  if (invalidNumberMaxLength(number)) {
    return 'MAX_LENGTH_REACHED';
  }
  return null;
};

export const validateNumber = (
  number: string,
  numberType: string
): ValidationErrors | null => {
  if (number === '' && numberType === 'CARD_NUMBER') {
    return 'STOCK_NUMBER_EMPTY';
  }
  if (number === '' && numberType === 'PC_NUMBER') {
    return 'PC_NUMBER_EMPTY';
  }
  if (invalidNumberCharacters(number)) {
    return 'SPECIAL_CHARACTER';
  }
  if (invalidNumberMaxLength(number)) {
    return 'MAX_LENGTH_REACHED';
  }
  return null;
};

export const validateName = (name: string, nameType: string): string | null => {
  if (name === '' && nameType === 'LAST_NAME') {
    return 'LAST_NAME_EMPTY';
  }
  if (invalidNameCharacters(name)) {
    return 'SPECIAL_CHARACTER';
  }
  if (invalidNameMaxLength(name)) {
    return 'MAX_LENGTH_NAME';
  }
  return null;
};

export const validationData: ValidationErrorsData = {
  LAST_NAME_EMPTY: 'Enter your last name to continue',
  LICENCE_NUMBER_EMPTY: 'Enter your licence number to continue',
  PC_NUMBER_EMPTY: 'Enter your photo card number to continue',
  STOCK_NUMBER_EMPTY: 'Enter your stock number to continue',
  SPECIAL_CHARACTER:
    'Format not supported. Please check your details and try again.',
  MAX_LENGTH_REACHED:
    'The number entered contains too many characters, please enter 12 characters or less',
  MAX_LENGTH_NAME:
    'The name entered contains too many characters, please enter 60 characters or less',
  SUSPENSION_ID_EMPTY: 'Enter your suspension letter ID to continue',
  SUSPENSION_ID_INVALID_FORMAT:
    'Format not supported. Enter letter and numbers only.',
  SUSPENSION_ID_INVALID:
    "Letter ID is not valid. Enter the details exactly as they appear on your suspension letter, including the letter 'D'.",
  DECLARATION_CHECKBOX_UNCHECKED:
    'Please agree with this statement to continue',
  EMAIL_EMPTY: 'Please enter an email address to continue',
  EMAIL_INVALID: 'Please enter a valid email address',
  LETTER_ID_CUSTOMER_ID_MISMATCH:
    'Letter ID does not match our records. Check the details entered and try again.',
  LETTER_ID_NOT_CUR_SUSP_NOTICE:
    'Letter ID is not valid. Enter the details exactly as they appear on your suspension letter.',
  LETTER_NOT_FOUND:
    'Letter ID is not valid. Enter the details exactly as they appear on your suspension letter.',
};

export type ValidationErrors =
  | 'LAST_NAME_EMPTY'
  | 'LICENCE_NUMBER_EMPTY'
  | 'PC_NUMBER_EMPTY'
  | 'STOCK_NUMBER_EMPTY'
  | 'SUSPENSION_ID_EMPTY'
  | 'SUSPENSION_ID_INVALID_FORMAT'
  | 'SPECIAL_CHARACTER'
  | 'MAX_LENGTH_REACHED'
  | 'MAX_LENGTH_NAME'
  | 'EMAIL_EMPTY'
  | 'EMAIL_INVALID';

export type ValidationErrorsData = {
  [key: string]: string;
};

export const invalidAlphaNumericCharacters = (value: string) => {
  if (value !== null) return Boolean(value.match(/[^a-zA-Z0-9]/g));
  else return null;
};
export const invalidNumberCharacters = (value: string) => {
  if (value !== null) return Boolean(value.match(/[^ 0-9]/g));
  else return null;
};
export const invalidNameCharacters = (value: string) => {
  if (value !== null) return Boolean(value.match(/[^-' a-zA-Z]/g));
  else return null;
};
export const invalidNumberMaxLength = (value: string) => {
  if (value !== null) {
    value = value.replace(/\s/g, '');
    return value.length > 12;
  } else return null;
};
export const invalidNameMaxLength = (value: string) => {
  if (value !== null) return value.length > 60;
  else return null;
};

export const invalidEmailCharacters = (value: string) => {
  const emailAddress = value.split('@');
  if (emailAddress.length > 2) return true;
  let isInvalidEmail = false;
  const emailStartText = emailAddress[0];
  const emailEndText = emailAddress[1];
  isInvalidEmail = Boolean(emailStartText?.match(/[^a-zA-Z0-9._-]/g));
  isInvalidEmail =
    isInvalidEmail || Boolean(emailEndText?.match(/[^a-zA-Z.]/g));
  return isInvalidEmail;
};

export const capitalise = (str: string): string => {
  if (str === null || str === '') return '';
  else return str.charAt(0).toUpperCase() + str.slice(1);
};

export function emailAddressValidationError(
  email: string
): ValidationErrors | null {
  if (email === '') {
    return 'EMAIL_EMPTY';
  } else if (!validation.email(email)) {
    return 'EMAIL_INVALID';
  }
  return null;
}
