import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { declarationFormReducer } from './slice/declarationForm';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { eligibilityReducer } from './slice/eligibility';
import { validateReducer } from './slice/validate';
import { userTypeReducer } from './slice/userType';
import { confirmationReducer } from './slice/confirmation';
import { personalDetailsReducer } from './slice/personDetails';
import { emailDetailsReducer } from './slice/emailDetails';

const reducers = combineReducers({
  userType: userTypeReducer,
  personalDetails: personalDetailsReducer,
  validate: validateReducer,
  confirmation: confirmationReducer,
  eligibility: eligibilityReducer,
  declarationForm: declarationFormReducer,
  emailDetails: emailDetailsReducer,
});

export const store = configureStore({
  reducer: reducers,
});

export type ReducerRootState = ReturnType<typeof reducers>;
export type ReducersStoreType = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useStoreDispatch: () => AppDispatch = useDispatch;
export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector;
const storeInitialState = store.getState();

export const setupReduxStore = (
  preloadedState?: Partial<PreloadedState<ReducerRootState>>
): ReducersStoreType => {
  return configureStore({
    reducer: reducers,
    preloadedState: {
      ...storeInitialState,
      ...preloadedState,
    },
  });
};

export default store;
