import detectIOS from '../detectIOS/detectIOS';
const { isDeviceIOSSafari, isDeviceIOSFirefox } = detectIOS;

const convertBase64ToBinaryBlob = (base64Pdf: string): Blob => {
  const binaryString = atob(base64Pdf);
  const bytesArray = new Uint8Array(binaryString.length).map((_, idx) =>
    binaryString.charCodeAt(idx)
  );
  return new Blob([bytesArray], { type: 'application/pdf' });
};

/**
 * Firefox desktop was removing it before accessing the file,
 * added delay before revoke to open file
 */
const delayedRevokeObjectURL = (fileURL: string) => {
  setTimeout(() => {
    URL.revokeObjectURL(fileURL);
  }, 1000);
};

const downloadBase64PDF = (pdf: string, fileName: string): void => {
  // iOS Safari cannot use the download tag for links
  // instead we use the print command to let the user download it
  if (isDeviceIOSSafari()) {
    printBase64PDF(pdf);
    return;
  }

  const file = convertBase64ToBinaryBlob(pdf);
  const fileURL = URL.createObjectURL(file);

  const downloadLink = document.createElement('a');
  downloadLink.href = fileURL;
  downloadLink.download = fileName;

  downloadLink.click();
  downloadLink.remove();

  delayedRevokeObjectURL(fileURL);
};

const printBase64PDF = (pdf: string): void => {
  const file = convertBase64ToBinaryBlob(pdf);
  const fileURL = URL.createObjectURL(file);

  const printLink = document.createElement('a');
  printLink.href = fileURL;
  // iOS Firefox cannot open a new tab
  if (!isDeviceIOSFirefox()) {
    printLink.target = '_blank';
  }
  printLink.rel = 'noopener noreferrer';

  printLink.click();
  printLink.remove();

  delayedRevokeObjectURL(fileURL);
};

const pdfActions = {
  downloadBase64PDF,
  printBase64PDF,
};

export default pdfActions;
