import React from 'react';
import { H1, Icons, colors } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';
import HeaderProgressBar from './HeaderProgressBar';
import styled from 'styled-components';

const Header = ({
  title = '',
  text = '',
  linkText = null,
  backLink = () => {},
  headingIcon = null,
  progressBar = [],
  step = 0,
  ...rest
}) => {
  const HeaderStyled = styled.div`
    background-color: ${colors.greyBackground};

    .headerContainer {
      max-width: 76.5rem;
      padding: 24px 20px 0.75rem;
      margin-left: auto;
      margin-right: auto;

      ${Breakpoint('md')`
        padding: 2rem 30px 0.75rem;
      `}
    }

    .headerLink {
      position: relative;
      background-color: transparent;
      border: none;
      padding: 0;
      margin-bottom: 1.25rem;
      cursor: pointer;

      svg {
        position: absolute;
        top: 2px;
      }

      span {
        margin-left: 1.5rem;
        text-decoration: underline;
      }
    }

    h1 {
      line-height: 1.4;
      white-space: break-spaces;
    }

    p {
      margin-top: 0.25rem;
    }
  `;

  const HeadingContainer = styled.div`
    display: flex;
    align-items: top;
  `;

  const HeadingIconContainer = styled.div`
    display: inline-block;
    margin-right: 1rem;
    transform: translateY(3px);
  `;

  const HeadingIcon = headingIcon ? Icons[headingIcon] : null;

  return (
    <HeaderStyled>
      <div className='headerContainer' {...rest}>
        {linkText ? (
          <button type='button' className='headerLink' onClick={backLink}>
            <Icons.ChevronLeft color='#242934' height={15} width={15} />
            <span>{linkText}</span>
          </button>
        ) : null}
        {progressBar.length ? (
          <HeaderProgressBar progressBar={progressBar} step={step} />
        ) : null}
        <HeadingContainer>
          {!!HeadingIcon && (
            <HeadingIconContainer>
              <HeadingIcon height={34} width={34} />
            </HeadingIconContainer>
          )}
          <H1>{title}</H1>
        </HeadingContainer>
        <p>{text}</p>
      </div>
    </HeaderStyled>
  );
};

export default Header;
