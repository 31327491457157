import styled from 'styled-components';
import { tokens } from '@snsw/react-component-library';

interface FormRadioGroupProps {
  hasError?: boolean;
}

const FormRadioGroupStyleOverride = styled.div<FormRadioGroupProps>`
  .radio-item {
    border: 1px solid ${tokens.colors.grey.geyser};
    border-radius: 4px;
    padding: 0.85rem;
    margin-top: 0.25rem !important;

    &:first-child {
      margin-top: 1rem !important;
    }
  }

  .FormRadioGroup-helper-text {
    text-decoration: underline;
    display: flex;
    align-items: center;

    button {
      margin-left: 0.25rem;
    }
  }

  legend > span {
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

export const RadioGroupFieldset = styled.fieldset`
  border: none;
  padding: 0;
`;

export const FormLegend = styled.legend`
  padding: 0;
`;

export const RadioCheckboxLegendLabel = styled.span`
  display: block;
  color: #242934;
  font-size: 16px;
`;

export const Radio = styled.div``;

interface RadioProps {
  checked?: boolean;
  label?: string;
  hasErrorInline?: boolean;
  mt?: string;
}

Radio.Item = styled.div<RadioProps>`
  position: relative;
  margin-top: ${(props) => (props.mt ? props.mt : '1rem')};
  padding: 1rem;
  border-radius: 6px;
  border: ${(props) =>
    props.checked
      ? '2px solid rgb(46, 82, 153)'
      : props.hasErrorInline
      ? `2px solid ${tokens.colors.borders.error}`
      : `2px solid ${tokens.colors.grey.geyser}`};
`;

Radio.Input = styled.input<RadioProps>`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:checked + ${Radio.Label} {
    &::after {
      display: block;
    }
  }

  &:focus ~ ${Radio.Label} {
    &::before {
      outline: none;
    }
  }

  &:focus ~ ${Radio.Label} {
    &::before {
      box-shadow: 0 0 0 1px #fff, 0 0 0 3px #2e5299;
    }
  }
`;

Radio.Label = styled.label<RadioProps>`
  position: relative;
  padding: 2px;
  margin: 0;
  display: flex;
  color: ${(props) => (props.checked ? 'rgb(46, 82, 153)' : 'rgb(36, 41, 52)')};
  padding: 0.25rem 0rem 0.25rem 3rem;

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    border: ${(props) =>
      props.checked
        ? '0.125rem solid rgb(46, 82, 153)'
        : props.hasErrorInline
        ? `0.125rem solid  ${tokens.colors.borders.error}`
        : '0.125rem solid rgb(36, 41, 52)'};
    border-radius: 1rem;
    background-color: #ffffff;
    width: 2rem;
    height: 2rem;
  }

  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 0.313rem;
    transform: translate(0, -50%);
    border: 0.125rem solid transparent;
    border-radius: calc(1rem + 0.188rem);
    background-color: ${(props) =>
      props.checked ? 'rgb(46, 82, 153)' : '#646974'};
    width: 1.375rem;
    height: 1.375rem;
    display: none;
  }
`;

export const InlineErrorStyle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  gap: 0.5rem
  color: ${tokens.colors.borders.error};
`;

export default FormRadioGroupStyleOverride;
