import { UserToken } from '@rmstransactions/components';
import { sha256 } from 'js-sha256';
import { sessionIDStorageKey, userIDStorageKey } from './AnalyticsSessionManager'

const PageView = (pageName, pageUrl, userId, transactionStartTime) => {
  window.dataLayer.push({
    event: 'v_page_view',
    page_info: {
      page_title: pageName,
      page_url: pageUrl,
      isTransaction: true,
      site_section: 'Transaction',
    },
    user_info: {
      user_id: userId, //globalState.userId
      user_status: 'Authenticated', //logged in
      loginUserType: 'New',
    },
    applicationId: getApplicationId(), //Token value
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime),
    transaction_product: 'RMS',
    applicationType: 'Registrational Renewal',
    transaction_sub_type: 'Conditional Registration',
  });
  // }
  return null;
};

const PageViewUpgrade = (pageName, pageUrl, userId, transactionStartTime) => {
  const { transactionType, transactionSubType, transactionStep } =
    setTransactionTypeSubTypeAndStep(pageName, pageUrl);
  setSessionId();
  const sessionId = window.sessionStorage.getItem(sessionIDStorageKey);
  setUserId(userId);
  const userIdEncrypted = window.sessionStorage.getItem(userIDStorageKey);
  window.dataLayer.push({
    event: 'v_page_info',
    page_info: {
      page_title: pageName,
      page_location: pageUrl,
      agency: 'Transport for NSW',
      product_team: 'RMS',
      site_section: 'Transaction',
    },
    user_info: {
      user_id: userIdEncrypted,
      user_login_status: 'fully authenticated',
    },
    transaction_info: {
      transaction_name: transactionType,
      transaction_step: transactionStep,
      transaction_type: transactionType,
      transaction_sub_type: transactionSubType,
      transaction_id: sessionId,
    },
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime),
  });
};

const Constants = {
  TERMS_CONDITIONS_PAGE: 'Terms and Condition Page',
  DEMERITS_SUMMARY_PAGE: 'Demerit Summary Page',
  PURCHASED_SUCCESS_PAGE: 'Purchased Success Page',
  DRIVING_RECORD_PAGE: 'Driving Record Page',
  ACCEPT_TERMS_AND_CONDITIONS: 'Accept Terms and Conditions',
  DRIVING_RECORD: 'Driving Record',
  REQUEST_DRIVING_RECORD: 'Request Driving Record',
  DEMERIT_SUMMARY: 'Demerit Summary',
  CHECK_DEMERITS_SUMMARY: 'Check Demerits Summary',
  CHECK_DEMERIT_POINTS: 'Check Demerit Points',
  PURCHASED_SUCCESS: 'Purchased Success',
  PURCHASED_SUCCESSFULLY: 'Purchased Successfully',
  REGISTRATIONAL_RENEWAL: 'Registrational Renewal',
  CONDITIONAL_REGISTRATION: 'Conditional Registration',
};

function setTransactionTypeSubTypeAndStep(pageName, pageUrl) {
  let transactionType = '';
  let transactionSubType = '';
  let transactionStep = '';
  if (pageName === Constants.TERMS_CONDITIONS_PAGE) {
    transactionStep = Constants.ACCEPT_TERMS_AND_CONDITIONS;
    if (pageUrl.indexOf('/odr-cdr') > -1) {
      transactionType = Constants.DRIVING_RECORD;
      transactionSubType = Constants.REQUEST_DRIVING_RECORD;
    } else {
      transactionType = Constants.DEMERIT_SUMMARY;
      transactionSubType = Constants.CHECK_DEMERITS_SUMMARY;
    }
  } else if (pageName === Constants.DEMERITS_SUMMARY_PAGE) {
    transactionType = Constants.DEMERIT_SUMMARY;
    transactionSubType = Constants.CHECK_DEMERITS_SUMMARY;
    transactionStep = Constants.CHECK_DEMERIT_POINTS;
  } else if (pageName === Constants.DRIVING_RECORD_PAGE) {
    transactionType = Constants.DRIVING_RECORD;
    transactionSubType = Constants.REQUEST_DRIVING_RECORD;
    transactionStep = Constants.REQUEST_DRIVING_RECORD;
  } else if (pageName === Constants.PURCHASED_SUCCESS_PAGE) {
    transactionType = Constants.DRIVING_RECORD;
    transactionSubType = Constants.PURCHASED_SUCCESS;
    transactionStep = Constants.PURCHASED_SUCCESSFULLY;
  } else {
    transactionType = Constants.REGISTRATIONAL_RENEWAL;
    transactionSubType = Constants.CONDITIONAL_REGISTRATION;
  }
  return { transactionType, transactionSubType, transactionStep };
}
function setUserId(userId) {
  let userIdEncrypted = '';
  if (userId !== null && userId !== '') {
    userIdEncrypted = sha256(userId);
    window.sessionStorage.setItem(userIDStorageKey, userIdEncrypted);
  }
}

function setSessionId() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const sessionId = params.get('sessionId');
  if (sessionId !== null) window.sessionStorage.setItem(sessionIDStorageKey, sessionId);
}

export function getTimeElapsedInSeconds(transactionStartTime) {
  var timeNow = new Date();
  return Math.floor((((timeNow - transactionStartTime) % 60000) / 1000));
}

function getApplicationId() {
  if (UserToken.getValue() != null) {
    return UserToken.getValue();
  } else {
    return getSessionId();
  }
}
function getSessionId() {
  var dc = document.cookie;
  console.log('cookies => ', dc); //Print cookies
  var prefix = 'rms' + '=';
  var begin = dc.indexOf('; ' + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(';', begin);
    if (end == -1) {
      end = dc.length;
    }
  }

  var cookie = dc.substring(begin + prefix.length, end);
  var json = JSON.parse(decodeURIComponent(escape(window.atob(cookie))));
  return json['sessionId'];
}

const Accordion = (action, accordionName, transactionStartTime) => {
  window.dataLayer.push({
    event: 'transaction_form',
    event_action: action,
    event_label: accordionName,
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime), // check with Bec
  });
};

const ButtonClick = (action, buttonName, transactionStartTime) => {
  window.dataLayer.push({
    event: 'transaction_form',
    event_action: action,
    event_label: buttonName,
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime),
  });
};

const ButtonClickUpgrade = (
  action,
  buttonName,
  transactionStartTime,
  destinationUrl
) => {
  window.dataLayer.push({
    event: 'component_interaction',
    component_type: action,
    component_text: buttonName,
    section_id: 'page_content',
    position_id: '1',
    destination_url: destinationUrl,
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime),
  });
};

const ErrorReport = (type, action, label, transactionStartTime) => {
  window.dataLayer.push({
    event: type,
    event_action: action,
    event_label: label,
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime),
  });
};

const ErrorReportUpgrade = (
  type,
  action,
  label,
  transactionStartTime,
  position
) => {
  window.dataLayer.push({
    event: 'error',
    error_info: {
      error_type: type,
      error_text: action,
      error_name: label,
      error_position: position,
    },
    time_elapsed: getTimeElapsedInSeconds(transactionStartTime),
  });
};

export const AnalyticsService = {
  ErrorReport,
  ErrorReportUpgrade,
  ButtonClick,
  ButtonClickUpgrade,
  Accordion,
  PageView,
  PageViewUpgrade,
};
