import React, { useEffect } from 'react';

const RefreshAlert: React.FC = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', onUnloadShowWarning);
    return () => {
      window.removeEventListener('beforeunload', onUnloadShowWarning);
    };
  }, []);

  return <></>;
};

export default RefreshAlert;

export const onUnloadShowWarning = (event: BeforeUnloadEvent): void => {
  event.preventDefault();
  event.stopImmediatePropagation();
  event.returnValue = 'leavePage';
};
