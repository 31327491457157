import React from 'react';
import { Constants } from '../Constants/Constants';
import { isMobileOnly } from 'react-device-detect';
import { UserSession } from '@rmstransactions/components';
import { HttpRequestHandler } from '../Network/HttpRequestHandler';

const loggedOutUrl = '/error/logged-out';
const stripTelForNonMobileDevices = (errorMessage) => {
  const telLinkPositionStart = errorMessage.indexOf('tel:');
  if (telLinkPositionStart > 8) {
    const telAnchorOpentagEnd = errorMessage.indexOf('>', telLinkPositionStart);
    const telAnchorClosetagStart = errorMessage.indexOf(
      '</a>',
      telLinkPositionStart
    );
    return (
      errorMessage.substring(0, telLinkPositionStart - 9) +
      errorMessage.substring(telAnchorOpentagEnd + 1, telAnchorClosetagStart) +
      errorMessage.substring(telAnchorClosetagStart + 4)
    );
  }
  return errorMessage;
};
const nowrapTelephone = (errorMessage) => {
  const telLinkPositionStart = errorMessage.indexOf('tel:');
  if (telLinkPositionStart > 8) {
    const telAnchorClosetagStart = errorMessage.indexOf(
      '</a>',
      telLinkPositionStart
    );
    return (
      errorMessage.substring(0, telLinkPositionStart - 9) +
      '<span style="white-space: nowrap">' +
      errorMessage.substring(
        telLinkPositionStart - 9,
        telAnchorClosetagStart + 4
      ) +
      '</span>' +
      errorMessage.substring(telAnchorClosetagStart + 4)
    );
  }
  return errorMessage;
};
const getDeviceBasedDisplayMessage = (message) => {
  return !isMobileOnly
    ? stripTelForNonMobileDevices(nowrapTelephone(message))
    : nowrapTelephone(message);
};

const addAccessibleTextBeforeOpeningNewPages = (message) => {
  if (message) {
    let newMessage = message.slice();
    let anchorNewPageLinkStartPosition = newMessage.indexOf('_blank');
    if (anchorNewPageLinkStartPosition >= 3) {
      let anchorTagSearchPosition = 0;
      let anchorNewPageEndPosition = -1;
      let continueLoop = true;
      while (continueLoop) {
        anchorNewPageLinkStartPosition = newMessage.indexOf(
          '_blank',
          anchorTagSearchPosition
        );
        if (anchorNewPageLinkStartPosition >= 0) {
          const anchorOpentagStartPosition = newMessage.indexOf(
            '<a',
            anchorTagSearchPosition
          );
          const nextAnchorOpentagStartPosition = newMessage.indexOf(
            '<a',
            anchorOpentagStartPosition + 1
          );
          const foundBlankInAnchorOpentagStartPosition =
            nextAnchorOpentagStartPosition === -1 ||
            anchorNewPageLinkStartPosition < nextAnchorOpentagStartPosition;
          if (foundBlankInAnchorOpentagStartPosition) {
            anchorNewPageEndPosition = newMessage.indexOf(
              '</a>',
              anchorOpentagStartPosition
            );
            newMessage =
              newMessage.substring(0, anchorNewPageEndPosition) +
              '<div class="sr-only">Opens a new window</div>' +
              newMessage.substring(anchorNewPageEndPosition);
          }
          continueLoop = nextAnchorOpentagStartPosition >= 0;
          if (foundBlankInAnchorOpentagStartPosition) {
            anchorTagSearchPosition = anchorNewPageEndPosition + 31;
          } else {
            anchorTagSearchPosition = nextAnchorOpentagStartPosition;
          }
        } else {
          continueLoop = false;
        }
      }
      return newMessage;
    }
  }
  return message;
};
const getAccessibleMessage = (message) => {
  if (message) {
    return addAccessibleTextBeforeOpeningNewPages(
      getDeviceBasedDisplayMessage(message)
    );
  } else {
    return message;
  }
};

const loginContinueButton = (apiHost, transactionState) => {
  return {
    btnType: 'primary',
    btnText: 'Log in and continue',
    btnLink: HttpRequestHandler.appendApiKeyAsParam(
      `${apiHost}/login?state=${transactionState}`
    ),
  };
};
const quitButton = (drupalTransactionLandingPageURL) => {
  return {
    btnType: 'secondary',
    btnText: 'Quit',
    btnLink: drupalTransactionLandingPageURL,
  };
};

const handleAPIError = (
  error,
  history,
  apiHost,
  transactionState,
  drupalTransactionLandingPageURL
) => {
  let isLoggedIn = UserSession.isLoginUser();
  let previousActiveSession = sessionStorage.getItem('activeSession');
  if (
    error &&
    error.message &&
    (isLoggedIn || previousActiveSession) &&
    (error.message.indexOf(
      Constants.HTTP_RESPONSE_CODE_UNAUTHORIZED.toString()
    ) >= 0 ||
      error.message.indexOf(
        Constants.HTTP_RESPONSE_CODE_FORBIDDEN.toString()
      ) >= 0)
  ) {
    UserSession.resetLoggedInUser();
    sessionStorage.removeItem('activeSession');
    history.push({
      pathname: loggedOutUrl,
      state: {
        errorMessageObject: {
          messageTitle: Constants.LOGOUT_ERROR_MESSAGE_HEADER,
          messageDescription: Constants.LOGOUT_ERROR_MESSAGE_DESCRIPTION,
        },
        header: Constants.TECHNICAL_ERROR_PAGE_HEADER,
        firstButton: loginContinueButton(apiHost, transactionState),
        secondButton: quitButton(drupalTransactionLandingPageURL),
      },
    });
    window.location.href = window.location.origin + loggedOutUrl;
  } else {
    history.push('/error');
  }
};

const handleAPIErrorForAuthTrans = (
  error,
  history,
  apiHost,
  transactionState,
  drupalTransactionLandingPageURL
) => {
  let isLoggedIn = UserSession.isLoginUser();
  let previousActiveSession = sessionStorage.getItem('activeSession');
  if (
    error &&
    error.message &&
    (isLoggedIn || previousActiveSession) &&
    (error.message.indexOf(
      Constants.HTTP_RESPONSE_CODE_UNAUTHORIZED.toString()
    ) >= 0 ||
      error.message.indexOf(
        Constants.HTTP_RESPONSE_CODE_FORBIDDEN.toString()
      ) >= 0)
  ) {
    UserSession.resetLoggedInUser();
    sessionStorage.removeItem('activeSession');
    history.push({
      pathname: loggedOutUrl,
      state: {
        errorMessageObject: {
          messageTitle: Constants.LOGOUT_ERROR_MESSAGE_HEADER,
          messageDescription: Constants.LOGOUT_ERROR_MESSAGE_DESCRIPTION,
        },
        header: Constants.TECHNICAL_ERROR_PAGE_HEADER,
        firstButton: loginContinueButton(apiHost, transactionState),
        secondButton: quitButton(drupalTransactionLandingPageURL),
      },
    });
  } else {
    window.location.href = HttpRequestHandler.appendApiKeyAsParam(
      `${apiHost}/login?state=${transactionState}`
    );
  }
};

export const ErrorService = {
  handleAPIError,
  handleAPIErrorForAuthTrans,
  getAccessibleMessage,
};
