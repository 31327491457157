import React from 'react';

const HeavyVehicle = ({ width = 48, height = 36 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 48 48"
        width={width}
        height={height}
        xmlSpace="preserve"
        focusable="false"
        aria-hidden="true"
    >
        <title>HeavyVehicle</title>
        <polygon className="st0" points="39.9,24 31.1,24 31.1,17.1 33.8,17.1 39.9,23.3 "/>
        <circle className="st1" cx="35.4" cy="35.3" r="3.1"/>
        <circle className="st2" cx="12.7" cy="35.3" r="3.7"/>
        <path className="st1" d="M45.6,30.8h-1.1v-7.7c0-0.9-0.4-1.8-1-2.4l-7.1-7.1c-0.6-0.6-1.5-1-2.4-1h-3.1V9.2c0-1.9-1.5-3.4-3.4-3.4H4.7
	c-1.9,0-3.4,1.5-3.4,3.4v22.7c0,1.9,1.5,3.4,3.4,3.4h1.1c0,3.8,3,6.8,6.8,6.8c3.8,0,6.8-3,6.8-6.8h9.1c0,3.8,3,6.8,6.8,6.8
	c3.8,0,6.8-3,6.8-6.8h3.4c0.6,0,1.1-0.5,1.1-1.1v-2.3C46.7,31.3,46.2,30.8,45.6,30.8z"/>
    </svg>
);

export default HeavyVehicle;
