import React from 'react';
import { colors } from '@snsw/react-component-library';

const Email = ({ color = colors.black, width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    focusable="false"
    aria-hidden="true"
  >
    <title>email</title>
    <path
      d="M0,52.23V54h64v-1.81L43.19,36.29c-1.74,1.48-4.04,3.43-4.07,3.45c-2.34,1.98-4.87,2.72-7.12,2.72
      c-2.26,0-4.79-0.75-7.12-2.72c-0.1-0.08-2.38-2.02-4.07-3.45L0,52.23z M60.84,21.35c-0.89,0.75-5.78,4.89-7.2,6.09
      c-2.47,2.09-4.49,3.79-6.24,5.28L64,45.43V18.67C63.12,19.42,62.08,20.3,60.84,21.35z M0,45.46l16.61-12.75
      c-1.75-1.48-3.77-3.19-6.43-5.43c-3.64-3.08-5.49-4.64-7.02-5.94C1.94,20.32,0.9,19.43,0,18.67V45.46z M0,10v1.6
      c2.04,1.72,6.81,5.77,13.74,11.64c4.02,3.4,14.34,12.16,14.7,12.46c1.23,1.04,2.47,1.41,3.56,1.41s2.33-0.37,3.56-1.41
      c0.44-0.37,2.09-1.77,5.08-4.3c4.36-3.69,4.36-3.69,9.44-8C57.85,16.81,62.34,13.01,64,11.6V10H0z"
    />
  </svg>
);

export default Email;
