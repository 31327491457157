import { Config } from '../config/env';

const { logLevel } = Config;

export const debugLogger = (...logDetails: any[]) => {
  if (logLevel === 'DEBUG') {
    console.log('DEBUG', ...logDetails);
  }
};

export const infoLogger = (...logDetails: any[]) => {
  console.log('INFO', ...logDetails);
};
