import { matchPath } from 'react-router-dom';
import {
  analyticsServiceV2,
  AnalyticsSessionManager,
} from '@rmstransactions/components';
import {
  googleAnalyticsDefaultInfo,
  googleAnalyticsTransactionInfo,
  googleAnalyticsViewReceiptInfo,
} from 'utils/route/urlParams';

const { getSessionID, getUserLoginType, getTransactionStartTime } =
  AnalyticsSessionManager;

const pushAnalyticsNewPage = (): void => {
  const hasRequiredSessionFields =
    getSessionID() && getUserLoginType() && getTransactionStartTime();
  if (!hasRequiredSessionFields) return;

  const transactionInfo = getNewPageTransactionInfo();
  const overridePageLocation = getOverridePageLocation();

  analyticsServiceV2.pushNewPageVisited({
    pageName: document?.title,
    transactionInfo: transactionInfo,
    overridePageLocation,
  });
};

export default pushAnalyticsNewPage;

export const getNewPageTransactionInfo = (): NewPageTransactionInfo => {
  const { pathname } = window.location;

  const isViewReceiptURL = matchPath(pathname, {
    path: '/view-receipt/:receiptUUID',
  });
  if (isViewReceiptURL) return googleAnalyticsViewReceiptInfo;

  return Object.keys(googleAnalyticsTransactionInfo).includes(pathname)
    ? googleAnalyticsTransactionInfo[pathname]
    : googleAnalyticsDefaultInfo;
};

interface NewPageTransactionInfo {
  transaction_name: string;
  transaction_step: string;
  transaction_type: string;
  transaction_sub_type: string;
}

/**
 *  we don't want to send the "receiptUUID" value to Google Analytics
 *  therefore we will replace it manually
 */
export const getOverridePageLocation = (): string | undefined => {
  const { origin, pathname } = window.location;

  const isViewReceiptURL = matchPath(pathname, {
    path: '/view-receipt/:receiptUUID',
  });
  if (isViewReceiptURL) return `${origin}/view-receipt/uuid`;

  return undefined;
};
