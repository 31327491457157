import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPersonalDetails {
  cardSelection: string;
  lastName: string;
  licenceNumber: string;
  cardNumber: string;
  pcNumber: string;
}

export const initialState: IPersonalDetails = {
  cardSelection: 'NSW Driver Licence',
  lastName: '',
  licenceNumber: '',
  cardNumber: '',
  pcNumber: '',
};

export const personalDetailsSlice = createSlice({
  name: 'personalDetails',
  initialState,
  reducers: {
    setCardSelection: (state, action: PayloadAction<string>) => {
      state.cardSelection = action.payload;
    },
    setDetails: (
      state,
      action: PayloadAction<{
        lastName: string;
        cardNumber: string;
        licenceNumber: string;
        pcNumber: string;
      }>
    ) => {
      state.lastName = action.payload.lastName;
      state.cardNumber = action.payload.cardNumber;
      state.licenceNumber = action.payload.licenceNumber;
      state.pcNumber = action.payload.pcNumber;
    },
    setLicenceNumber: (state, action: PayloadAction<string>) => {
      state.licenceNumber = action.payload;
    },
    setPCNumber: (state, action: PayloadAction<string>) => {
      state.pcNumber = action.payload;
    },
    resetPersonalDetails: () => initialState,
  },
});

export const personalDetailsActions = personalDetailsSlice.actions;
export const personalDetailsReducer = personalDetailsSlice.reducer;
