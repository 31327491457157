import { Constants } from 'constants/constants';
import { History } from 'history';

import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import {
  getInlineErrorMessages,
  getUnsuccessfulMessages,
} from 'utils/error/ErrorHelpers';
import { UserSession } from '@rmstransactions/components';

export const handleEGBAPIErrors = (error, history) => {
  const isLoggedIn = UserSession.isLoginUser();
  const previousActiveSession = sessionStorage.getItem('activeSession');
  if (error && error.message && (isLoggedIn || previousActiveSession) && (error.message.indexOf(Constants.HTTP_RESPONSE_CODE_UNAUTHORIZED.toString()) >= 0 ||
      error.message.indexOf(Constants.HTTP_RESPONSE_CODE_FORBIDDEN.toString()) >= 0
  )) {
    UserSession.resetLoggedInUser();
    sessionStorage.removeItem('activeSession');
    history.push({
      pathname: Constants.loggedOutUrl,
      state: {
        errorMessageObject: {
          messageTitle: Constants.LOGOUT_ERROR_MESSAGE_HEADER,
          messageDescription: Constants.LOGOUT_ERROR_MESSAGE_DESCRIPTION
        },
        header: Constants.LOGOUT_ERROR_PAGE_HEADER,
        firstButton: startNewApplicationBtn(),
        secondButton: quitButton(),
      },
    });
    //window.location.href = window.location.origin+Constants.loggedOutUrl
  } else {
    history.push('/error');
  }
};

export const redirectToErrorPage = (
  history: History<unknown>,
  systemMessages?: DriveMessageDetails
) => {
  if (systemMessages) {
    history.push({
      pathname: Constants.ERROR_PAGE_URL,
      state: {
        errorMessage: systemMessages,
      },
    });
  } else {
    history.push(Constants.ERROR_PAGE_URL);
  }
};

export const handleErrorInResponse = (
  history: History<unknown>,
  systemMessages: DriveMessageDetails[]
): DriveMessageDetails | null => {
  const error = getUnsuccessfulMessages(systemMessages);
  if (error) {
    redirectToErrorPage(history, error);
    return null;
  }
  return getInlineErrorMessages(systemMessages);
};

const startNewApplicationBtn = () => {
  return {btnType: 'primary', btnText:'Start new application', btnLink: Constants.BRIDGING_PAGE_URL};
};

const quitButton = () => {
  return {btnType: 'secondary', btnText:'Quit', btnLink: Constants.DRUPAL_LINK_EGB};
};