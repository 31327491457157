import { Config } from 'config/env';

export const Constants = {
  DRUPAL_LINK_EGB:
    'https://www.service.nsw.gov.au/transaction/apply-good-behaviour-period',
  BOOK_LICENCE_TEST_LINK:
    'https://www.service.nsw.gov.au/transaction/book-driver-or-rider-licence-test',
  RENEW_UPGRADE_LICENCE_LINK:
    'https://www.service.nsw.gov.au/transaction/renew-or-upgrade-nsw-driver-licence',
  SERVICE_CENTER_LINK: 'https://www.service.nsw.gov.au/service-centre',
  REQUEST_DRIVING_RECORD:
    'https://www.service.nsw.gov.au/transaction/request-a-driving-record',
  SERVICE_NSW_SHARING_CENTER_LINK: 'https://sharing-center.service.nsw.gov.au/',
  UPDATE_EMAIL_ADDRESS_LINK:
    'https://account.service.nsw.gov.au/manage/personal-details/update-primary-email',
  UPDATE_DETAILS_LINK:
    'https://www.service.nsw.gov.au/services/driving-and-transport/update-details-transport-for-nsw',
  UPDATE_EMAIL_LINK: '/manage/personal-details/update-primary-email',
  UPDATE_EMAIL_MYACCT_LINK:
    process.env.REACT_APP_MY_ACCOUNT_BASE_URL +
    '/manage/personal-details/update-primary-email',
  TRANSACTION_STATE: 'EGB',
  BRIDGING_PAGE: 'Bridging Page',
  PERSONAL_DETAILS_PAGE: 'Personal Details Page',
  SUSPENSION_DETAILS_PAGE: 'Suspension details Page',
  DECLARATION_DETAILS_PAGE: 'Declaration Page',
  TERMS_CONDITIONS_PAGE: 'Review and submit Page',
  CONFIRMATION_PAGE: 'Confirmation Page',
  LANDING_PAGE_URL: '/',
  BRIDGING_PAGE_URL: '/bridging-page',
  PERSONAL_DETAILS_PAGE_URL: '/personal-details',
  SUSPENSION_DETAILS_PAGE_URL: '/suspension-details',
  DECLARATION_PAGE_URL: '/declaration-details',
  REVIEW_AND_SUBMIT_PAGE_URL: '/review-submit',
  GOOD_BEHAVIOUR_PAGE_URL: '/good-behaviour',
  ERROR_PAGE_URL: '/error',
  FORWARD_DOUBLE_SLASH: '//',
  BUTTON_PRIMARY: 'button_primary',
  BUTTON_SECONDARY: 'button_secondary',
  NEXT_BUTTON: 'Next',
  QUIT_BUTTON: 'Quit',
  BACK_BUTTON: 'Back',
  SYSTEMS_ERROR: 'System Error',
  TECHNICAL_ERROR: 'Technical Error',
  BUISNESS_ERROR: 'Buisness Error',
  ERROR_POSITION_BODY: 'body',
  AUTHENTICATED_UNLINKED: 'AuthenticatedUnLinked',
  AUTHENTICATED_LINKED: 'AuthenticatedLinked',
  GUEST: 'Guest',
  ITOP1: 'EGBITOP1',
  ITOP2: 'EGBITOP2',
  TERMS_ERROR: 'Please accept the Terms and Conditions to continue',
  CUSTOMER_TO_COMPLETE_DKT: 'CUSTOMER_TO_COMPLETE_DKT',
  EGB_TUTD_APP_NAME: 'elect-good-behaviour',
  DATE_UI_FORMAT: 'D MMMM YYYY',
  INVALID_SUSPENSION_LETTER_ID:
    'Letter ID does not match our records. Check the details entered and try again.',
  HTTP_RESPONSE_CODE_UNAUTHORIZED: 401,
  HTTP_RESPONSE_CODE_FORBIDDEN: 403,
  loggedOutUrl: '/error/logged-out',
  LOGOUT_ERROR_MESSAGE_HEADER: 'You have been logged out',
  LOGOUT_ERROR_MESSAGE_DESCRIPTION:
    'If you still want to apply for a good behaviour period, you can log in and start a new application. Need help? Call us on 13 77 88 or visit a service centre.',
  TECHNICAL_ERROR_PAGE_HEADER: "We're sorry about this...",
  LOGOUT_ERROR_PAGE_HEADER: 'Good behaviour period incomplete',
  SILENTAUTHURL: `${Config.apiHost}${Config.apiSilentLoginPath}?state=EGB`,
  LOGGED_OUT_ERROR_PAGE_URL: '/error/logged-out',
  CUSTOMER_NOT_FOUND_INLINE_ERROR_TITLE:
    'The details you entered do not match our records',
  CUSTOMER_NOT_FOUND_INLINE_ERROR_DESCRIPTION:
    'Check your details and try again. Need help? Call us on 13 77 88 or visit a [service centre].',
  CUST_SUBJECTED_TO_ITOP2: 'CUST_SUBJECTED_TO_ITOP2',
};
