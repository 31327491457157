import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EligibilityDetails {
  suspensionLetterId: string | null;
  suspensionStartDate: string;
  suspensionEndDate: string;
}

export const initialState: EligibilityDetails = {
  suspensionLetterId: null,
  suspensionStartDate: '',
  suspensionEndDate: '',
};

export const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState,
  reducers: {
    setSuspensionLetterId: (state, action: PayloadAction<string>) => {
      state.suspensionLetterId = action.payload;
    },
    setSuspensionDate: (
      state,
      action: PayloadAction<{
        suspensionStartDate: string;
        suspensionEndDate: string;
      }>
    ) => {
      state.suspensionStartDate = action.payload.suspensionStartDate;
      state.suspensionEndDate = action.payload.suspensionEndDate;
    },
    resetEligibilityDetails: () => initialState,
  },
});

export const eligibilitySliceActions = eligibilitySlice.actions;
export const eligibilityReducer = eligibilitySlice.reducer;
