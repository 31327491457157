import React, { useState, useEffect, useRef } from 'react';
import { Spacer, UserSession, helpers } from '@rmstransactions/components';
import { ReactComponent as NoticeOfSuspension } from 'assets/notice-of-suspension.svg';
import {
  TextWrapper,
  ImgWrapper,
  DivWrapper,
  StyleHorizontalRule,
  SuspensionIdWrapper,
  StyledFormGroup,
} from './styled';
import { validationData, validateAlphaNumericCharacters } from 'utils/helper';
import {
  ContentContainer,
  Col,
  Row,
  ComponentLoader,
  H2,
  Input,
  Button,
  TextLink,
} from '@snsw/react-component-library';
import { Constants } from 'constants/constants';
import {
  checkEligibilityApi,
  handleButtonClickGA,
  interceptors,
} from 'services/ElectGoodBehaviourService';
import { useHistory } from 'react-router-dom';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { declarationFormSliceActions } from 'redux/slice/declarationForm';
import { eligibilitySliceActions } from 'redux/slice/eligibility';
import ProgressStepperComp from 'components/ProgressStepper/ProgressStepperComp';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import { handleErrorInResponse } from 'services/EGBErrorService';
import { handleResponseData } from 'utils/api/httpClient';
import { ButtonGroup, ContentWrapper } from 'utils/styling/styled';
import InlineNotificationMessage from 'components/NotificationMessage/InlineNotificationMessage';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { handleEligibilityDetails } from './suspensionDetails.action';
import RefreshAlert from 'components/RefreshAlert/RefreshAlert';

const SuspensionLetterDetailsPage = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [suspensionId, setSuspensionId] = useState('');
  const [inPageAlert, setInPageAlert] = useState(false);
  const [showSuspensionIdInvalid, setShowSuspensionIdInvalid] = useState(false);
  const [suspensionIdInvalidMessage, setSuspensionIdInvalidMessage] =
    useState('');
  const [notificationMessage, setNotificationMessage] =
    useState<DriveMessageDetails>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const errorAlertRef = useRef<HTMLDivElement>(null);
  const [errorCountOnNextButton, updateErrorCountOnNextButton] =
    useState<number>(0);

  const {
    familyName,
    givenName,
    otherGivenNames,
    licenceNumber,
    suspension_letter_id,
  } = useStoreSelector((state) => ({
    familyName: state.validate.customerName.familyName,
    givenName: state.validate.customerName.givenName,
    otherGivenNames: state.validate.customerName.otherGivenNames,
    licenceNumber: state.validate.licenceNumber,
    suspension_letter_id: state.eligibility.suspensionLetterId,
  }));

  const loadingText = (
    <p>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </p>
  );
  const onChangeSuspensionID = (suspensionId: string) => {
    setInPageAlert(false);
    suspensionId = suspensionId.toUpperCase();
    setSuspensionId(suspensionId);
    validateSuspensionLetterID(suspensionId);
  };

  const validateSuspensionLetterID = (suspensionId: string) => {
    suspensionId = suspensionId ?? '';
    const validateSuspensionIdError = validateAlphaNumericCharacters(
      suspensionId,
      'SUSPENSION_ID'
    );
    if (
      validateSuspensionIdError === 'SUSPENSION_ID_INVALID_FORMAT' ||
      validateSuspensionIdError === 'MAX_LENGTH_REACHED' ||
      validateSuspensionIdError === 'SUSPENSION_ID_EMPTY'
    ) {
      setShowSuspensionIdInvalid(true);
      setSuspensionIdInvalidMessage(validationData[validateSuspensionIdError]);
      return true;
    } else if (!suspensionId.startsWith('D')) {
      setShowSuspensionIdInvalid(true);
      setSuspensionIdInvalidMessage(validationData['SUSPENSION_ID_INVALID']);
      return true;
    } else {
      setShowSuspensionIdInvalid(false);
      setSuspensionId(suspensionId);
      return false;
    }
  };

  const handleNextButtonClick = async () => {
    handleButtonClickGA(
      Constants.NEXT_BUTTON,
      Constants.BUTTON_PRIMARY,
      pageUrl
    );
    setInPageAlert(true);
    window.scrollTo(0, 0);
    dispatch(eligibilitySliceActions.setSuspensionLetterId(suspensionId));
    if (validateSuspensionLetterID(suspensionId)) {
      setShowSuspensionIdInvalid(true);
      updateErrorCountOnNextButton((prevErrorCount) => prevErrorCount + 1);
      return;
    }
    // Invoke Eligibility API
    setIsLoading(true);
    const responseData = await checkEligibilityApi({
      suspensionId: suspensionId,
    });
    setIsLoading(false);

    const data = handleResponseData(responseData);
    if (!data) {
      return;
    }
    const { response, systemMessages } = data;
    let inlineMessage = handleErrorInResponse(history, systemMessages);
    if (inlineMessage) {
      const errorCode = inlineMessage.identifier;
      if (errorCode === 'LETTER_ID_CUSTOMER_ID_MISMATCH')
        inlineMessage = {
          ...inlineMessage,
          messageDescription: inlineMessage.messageDescription.replace(
            '[licence]',
            licenceNumber
          ),
        };
      setSuspensionIdInvalidMessage(
        validationData[errorCode] ?? Constants.INVALID_SUSPENSION_LETTER_ID
      );
      setShowSuspensionIdInvalid(true);
      setNotificationMessage(inlineMessage);
    } else if (response) {
      const { eligibility, declaration } = response;
      const {
        businessTransaction,
        suspensionStartDate,
        suspensionEndDate,
        questions,
      } = handleEligibilityDetails(eligibility, declaration, licenceNumber);

      dispatch(
        eligibilitySliceActions.setSuspensionDate({
          suspensionStartDate,
          suspensionEndDate,
        })
      );
      dispatch(
        declarationFormSliceActions.setBusinessTransaction(businessTransaction)
      );
      dispatch(declarationFormSliceActions.setQuestions(questions));

      const customerSubjectedToItop2Message = systemMessages?.find(
        (item) => item.identifier === Constants.CUST_SUBJECTED_TO_ITOP2
      );
      if (customerSubjectedToItop2Message) {
        dispatch(
          declarationFormSliceActions.setCustomerSubjectedToItop2(
            customerSubjectedToItop2Message
          )
        );
      }

      history.push(Constants.DECLARATION_PAGE_URL);
      return;
    }
  };

  const handleBackClick = async () => {
    handleButtonClickGA(
      Constants.BACK_BUTTON,
      Constants.BUTTON_SECONDARY,
      pageUrl
    );
    history.push(Constants.PERSONAL_DETAILS_PAGE_URL);
    window.scrollTo(0, 0);
  };
  const handleEdit = () => {
    if (inputRef.current !== null) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const getFieldLabel = (fieldName: string, id: string) => {
    return (
      <TextLink data-testid='errLink' href={id} onClick={() => handleEdit()}>
        {fieldName}
      </TextLink>
    );
  };

  const loadSessionData = () => {
    if (suspension_letter_id) setSuspensionId(suspension_letter_id);
  };

  const handleQuit = async () => {
    handleButtonClickGA(
      Constants.QUIT_BUTTON,
      Constants.BUTTON_SECONDARY,
      pageUrl
    );
    return (window.location.href = Constants.DRUPAL_LINK_EGB);
  };

  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.SUSPENSION_DETAILS_PAGE_URL;

  useEffect(() => {
    interceptors(history);
    //condition to check the store value for refresh scenario
    if (familyName === '') {
      history.push('/');
    }
    setIsLoading(false);
    loadSessionData();
  }, []);

  useEffect(() => {
    if (errorCountOnNextButton > 0 && errorAlertRef.current) {
      errorAlertRef.current.focus();
    }
  }, [errorCountOnNextButton]);

  const ValidationError = () => {
    return (
      <>
        <p>Please check the following item:</p>
        <ul>
          {showSuspensionIdInvalid &&
            (suspensionIdInvalidMessage.includes(
              'Enter your suspension letter ID to continue'
            ) ? (
              <li>
                {getFieldLabel(
                  suspensionIdInvalidMessage,
                  '#suspension_letter_id'
                )}
              </li>
            ) : (
              <li>
                {getFieldLabel(
                  'Suspension letter ID:',
                  '#suspension_letter_id'
                )}
                {' ' + suspensionIdInvalidMessage}
              </li>
            ))}
        </ul>
      </>
    );
  };

  const isValidationError =
    inPageAlert && showSuspensionIdInvalid && !notificationMessage;

  return (
    <>
      <ComponentLoader fullPage label={loadingText} active={isLoading} />
      <ProgressStepperComp
        steps={1}
        label='Apply for a good behaviour period'
        title='Suspension letter'
      />
      <ContentContainer>
        <ContentWrapper>
          {isValidationError ? (
            <InlineNotificationMessage
              errorAlertRef={errorAlertRef}
              MessageComp={ValidationError}
            />
          ) : (
            <NotificationMessage
              message={notificationMessage}
              marginBottom={true}
            />
          )}
          <Row>
            <Col span={12}>
              <Spacer mt={-1} mb={-1}>
                <p>
                  {helpers.sentenceCase(givenName)}{' '}
                  {otherGivenNames &&
                    helpers.sentenceCase(otherGivenNames) + ' '}
                  <strong>{helpers.convertToUpperCase(familyName)}</strong>
                </p>
                <Spacer mt={-0.5} />
                <p>
                  NSW Driver Licence: <strong> {licenceNumber}</strong>
                </p>
              </Spacer>
              <StyleHorizontalRule marginTop='3.5rem' marginBottom='3.5rem' />
              <H2> Notice of suspension letter</H2>
              <TextWrapper>
                Enter your letter ID exactly as it appears on your notice of
                suspension letter.{' '}
              </TextWrapper>
              <Spacer mb={2} />
              <ImgWrapper>
                <NoticeOfSuspension></NoticeOfSuspension>
              </ImgWrapper>
              <DivWrapper>
                <StyledFormGroup
                  className='suspension_letter_id'
                  id='suspension_letter_id'
                  name='suspension_letter_id'
                  label='Suspension letter ID'
                  helpMessage={
                    <SuspensionIdWrapper>
                      The ID starts with the letter &apos;D&apos;. This can be
                      found in the top left corner of your notice of suspension
                      letter. Make sure you include the letter &apos;D&apos; in
                      this section.
                    </SuspensionIdWrapper>
                  }
                  errorMessage={suspensionIdInvalidMessage}
                  hasError={showSuspensionIdInvalid}
                >
                  <Input
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeSuspensionID(event.target.value)
                    }
                    inputWidth='xxl'
                    inputRef={inputRef}
                    value={suspensionId}
                  />
                </StyledFormGroup>
              </DivWrapper>
              <ButtonGroup>
                {showSuspensionIdInvalid && <Spacer mt={3} mb={1.5} />}
                <Button
                  style={{ marginRight: '1rem' }}
                  data-testid='nextButton'
                  onClick={handleNextButtonClick}
                >
                  Next
                </Button>
                <Spacer mt={1} className='mobile-spacer' />
                {!UserSession.isLoginUser() ? (
                  <Button
                    onClick={handleBackClick}
                    data-testid='backButton'
                    theme='secondary'
                  >
                    Back
                  </Button>
                ) : (
                  <Button
                    id='quitButton'
                    onClick={handleQuit}
                    data-testid='quitButton'
                    theme='secondary'
                  >
                    <b>Quit</b>
                  </Button>
                )}
              </ButtonGroup>
            </Col>
          </Row>
        </ContentWrapper>
      </ContentContainer>
      <RefreshAlert />
    </>
  );
};

export default SuspensionLetterDetailsPage;
