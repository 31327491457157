import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import { errorDescriptionSystemFailure } from '../../utils/error/ErrorConstants';
import { Constants } from 'constants/constants';
export const systemFailureError: DriveMessageDetails = {
  identifier: 'SYSTEM_FAILURE',
  classification: 'SYSTEM',
  severity: 'ERROR',
  messageTitle: 'Our system is temporarily unavailable',
  messageDescription: errorDescriptionSystemFailure,
  message: '',
};

export const reviewSubmitInfoMessage: DriveMessageDetails = {
  identifier: 'REVIEW_SUBMIT_INFO',
  classification: 'BUSINESS',
  severity: 'INFORMATION',
  message: 'You cannot make changes after submitting',
  messageTitle: 'You cannot make changes after submitting',
  messageDescription:
    'Make sure all your details are correct. You will not be able to withdraw, revoke or make any changes after submitting.',
};

export const EGBConfirmationMessage: DriveMessageDetails = {
  identifier: 'EGB_SUCCESS',
  classification: 'BUSINESS',
  severity: 'SUCCESS',
  message: 'SUCCESS',
  messageTitle: 'Your good behaviour period application has been successful',
  messageDescription: '',
};

export const emailUpdateInfoMessage: DriveMessageDetails = {
  identifier: 'UPDATE_EMAIL_ALERT',
  classification: 'BUSINESS',
  severity: 'INFORMATION',
  message: 'Your automatic emails may be going to the wrong address',
  messageTitle: 'Your automatic emails may be going to the wrong address',
  messageDescription:
    '[Update your email|' +
    Constants.UPDATE_EMAIL_MYACCT_LINK +
    '] to make sure you receive important information from us',
};

export const drivingLicenceExpiryMessage: DriveMessageDetails = {
  identifier: 'DRIVING_LICENCE_EXPIRY_ALERT',
  classification: 'BUSINESS',
  severity: 'INFORMATION',
  message: 'Driver licence expiring soon',
  messageTitle: 'Driver licence expiring soon',
  messageDescription: '',
};
