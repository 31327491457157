import { createGlobalStyle } from 'styled-components';

/**
 * Use this to override any existing `Gotham` fonts to the new `Public Sans`
 * This does create the new font, but overrides the old `Gotham` to the new font values
 * NOTE: remember to remove the old gotham style sheets and add the `Public Sans`
 */

const Gel3OverrideGothamFont = createGlobalStyle`
@font-face{
  font-display:swap;
  font-family:Gotham; 
  font-style:italic;
  font-weight:400;
  src:url(https://fonts.service.nsw.gov.au/public-sans-italic.woff2) format("woff2");
  unicode-range:u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}
@font-face{
  font-display:swap;
  font-family:Gotham;
  font-style:normal;
  font-weight:400;
  src:url(https://fonts.service.nsw.gov.au/public-sans-regular.woff2) format("woff2");
  unicode-range:u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}
@font-face{
  font-display:swap;
  font-family:Gotham;
  font-style:normal;
  font-weight:500;
  src:url(https://fonts.service.nsw.gov.au/public-sans-medium.woff2) format("woff2");
  unicode-range:u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}
@font-face{
  font-display:swap;
  font-family:Gotham;
  font-style:normal;
  font-weight:700;
  src:url(https://fonts.service.nsw.gov.au/public-sans-bold.woff2) format("woff2");
  unicode-range:u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}
`;

export default Gel3OverrideGothamFont;
