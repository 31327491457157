import React from 'react';

const EMAIL_INPUT_LABEL = (
  <span>
    <strong>Send receipt to this email address</strong>{' '}
    <span style={{ fontWeight: 'normal' }}>(optional)</span>
  </span>
);

const DOC_ID_EMAIL_INPUT = 'emailInput';
const HTTP_RESPONSE_CODE_UNAUTHORIZED = 401;
const HTTP_RESPONSE_CODE_FORBIDDEN = 403;

const KIOSK_PLUGIN_ID = 'obicpkkbfhkiglmhejebnncjbplldahb';
const TECHNICAL_ERROR_PAGE_HEADER = "We're sorry about this...";
const TECHNICAL_ERROR_PAGE_MESSAGE_HEADLINE =
  'Our system is temporarily unavailable';
const TECHNICAL_ERROR_PAGE_MESSAGE =
  'You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>. We apologise for any inconvenience.';
const LOGOUT_ERROR_MESSAGE_HEADER = 'Access error occurred';
const LOGOUT_ERROR_MESSAGE_DESCRIPTION =
  'You have been logged out or do not have access to this page. Please log back into your account and try again. Need help? Call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>.';

export const Constants = {
  EMAIL_INPUT_LABEL,
  DOC_ID_EMAIL_INPUT,
  HTTP_RESPONSE_CODE_UNAUTHORIZED,
  HTTP_RESPONSE_CODE_FORBIDDEN,
  KIOSK_PLUGIN_ID,
  LOGOUT_ERROR_MESSAGE_HEADER,
  LOGOUT_ERROR_MESSAGE_DESCRIPTION,
  TECHNICAL_ERROR_PAGE_HEADER,
  TECHNICAL_ERROR_PAGE_MESSAGE_HEADLINE,
  TECHNICAL_ERROR_PAGE_MESSAGE,
};
