const email = (value: string) => {
  return value.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,63}))$/
  );
};

const invalidPlateNumberCharacters = (value: string) => {
  return Boolean(value.match(/[^a-zA-Z0-9]/g));
};

const invalidPlateNumberMaxLength = (value: string) => {
  return value.length > 6;
};

export const validation = {
  email,
  invalidPlateNumberCharacters,
  invalidPlateNumberMaxLength,
};
