import { Device } from '@rmstransactions/components/src/utils/DeviceUtils/DeviceUtils';

export interface IProcessRequest {
  emailAddress: string;
  channel?: Channel;
  deviceUsed?: Device;
  declarations?: IDeclaration[];
}

export enum Channel {
  UNASSISTED = 'UNASSISTED',
  SELF_SERVICE = 'SELFSERVICE',
  STAFF_ASSISTED = 'STAFFASSISTED',
}

export enum ExistingDeclaration {
  Y = 'Y',
  N = 'N',
}

export interface IDeclaration {
  question: IQuestion;
  response: IQuestionResponse;
  subQuestionResponses: IQuestionResponse[];
}

export interface IQuestion {
  questionId: number;
  questionText: string;
  displaySequence: number;
  displayOrder: number;
}

export interface IQuestionResponse {
  answerTitle: string;
  answer: string[];
}
