import React from 'react';
import { colors } from '@snsw/react-component-library';

const Reminder = ({ color = colors.black, width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22 24"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    focusable="false"
    aria-hidden="true"
  >
    <title>Reminder</title>
    <path
      d="m21 19.5c.4374998 0 .796875-.1484377 1.078125-.4453125s.421875-.6406252.421875-1.03125-.140625-.7421873-.421875-1.0546875c-.0312502-.0312502-.078125-.09375-.140625-.1875-.7187498-.75-1.2500002-1.4843752-1.59375-2.203125-.5625-1.2500002-.84375-2.859375-.84375-4.828125 0-1.81250024-.5625-3.39062476-1.6875-4.734375s-2.5625002-2.18750024-4.3125-2.53125v-.984375c0-.40625024-.1484377-.75781274-.4453125-1.0546875s-.6484373-.4453125-1.0546875-.4453125-.7578127.14843774-1.0546875.4453125-.4453125.64843726-.4453125 1.0546875v.984375c-1.74999976.34374976-3.1875 1.18749976-4.3125 2.53125s-1.6875 2.92187476-1.6875 4.734375c0 1.96875-.28125 3.5781248-.84375 4.828125-.34374976.7187498-.87500024 1.453125-1.59375 2.203125l-.140625.1875c-.28125.3125002-.421875.6640627-.421875 1.0546875s.140625.7343752.421875 1.03125.64062524.4453125 1.078125.4453125zm-1.5-2.25h-14.625c1.37499976-1.8437498 2.0625-4.3281248 2.0625-7.453125v-.046875c0-.9375.234375-1.81250024.703125-2.625s1.10937524-1.453125 1.921875-1.921875c.8125-.46875 1.6875-.703125 2.625-.703125s1.8125002.234375 2.625.703125 1.453125 1.10937524 1.921875 1.921875.703125 1.6875.703125 2.625v.046875c0 3.1250002.6875002 5.6093752 2.0625 7.453125zm-7.5 6.75c.8124998 0 1.5156248-.2890627 2.109375-.8671875s.890625-1.2890625.890625-2.1328125h-6c0 .84375.29687476 1.5546877.890625 2.1328125.5937502.5781248 1.2968752.8671875 2.109375.8671875z"
      fill="#2e5299"
      fillRule="evenodd"
      transform="translate(-1)"
    />
  </svg>
);

export default Reminder;
