export const Config: AppConfig = {
  apiHost: process.env.REACT_APP_API_HOST || '',
  globalNavEnv: process.env.REACT_APP_GLOBAL_NAV_ENV || '',
  logLevel: process.env.REACT_APP_LOG_LEVEL || 'DEBUG',
  myAccDashboardUrl: process.env.REACT_APP_MYACC_DASHBOARD || '',
  apiSilentLoginPath: '/silentLogin',
  apiLoginPath: '/login',
  apiCheckLoginPath: '/auth/checkLogin',
  apiMyAccCreateAccountPath: '/api/createaccount',
  apiGetCustomerLicenceDetailsAuthPath: '/auth/egb/customerInfo',
  apiValidateCustomerAnonPath: '/anon/egb/validateCustomer',
  apiValidateCustomerAuthPath: '/auth/egb/validateCustomer',
  apiEligibilityAuthPath: '/auth/egb/checkEligibility',
  apiEligibilityAnonPath: '/anon/egb/checkEligibility',
  apiSendEmailAuthPath: '/auth/egb/send-email',
  apiSendEmailAnonPath: '/anon/egb/send-email',
  apiSendEmailSupportPath: '/support/egb/send-email',
  apiUserDetails: '/auth/userDetails',
  apiUserTypePath: '/anon/userType',
  apiProcessRequestAuthPath: '/auth/egb/processEGB',
  apiProcessRequestAnonPath: '/anon/egb/processEGB',
  apiConfirmationAuthPath: '/auth/egb/confirmation',
  apiConfirmationAnonPath: '/anon/egb/confirmation',
  apiConfirmationSupportPath: '/support/egb/confirmation',
};

export interface AppConfig {
  apiHost: string;
  globalNavEnv: string;
  logLevel: string;
  myAccDashboardUrl: string;
  apiSilentLoginPath: string;
  apiLoginPath: string;
  apiCheckLoginPath: string;
  apiMyAccCreateAccountPath: string;
  apiGetCustomerLicenceDetailsAuthPath: string;
  apiValidateCustomerAnonPath: string;
  apiValidateCustomerAuthPath: string;
  apiEligibilityAuthPath: string;
  apiEligibilityAnonPath: string;
  apiSendEmailAuthPath: string;
  apiSendEmailAnonPath: string;
  apiSendEmailSupportPath: string;
  apiUserDetails: string;
  apiUserTypePath: string;
  apiProcessRequestAuthPath: string;
  apiProcessRequestAnonPath: string;
  apiConfirmationAuthPath: string;
  apiConfirmationAnonPath: string;
  apiConfirmationSupportPath: string;
}
