import styled from 'styled-components';
import PropTypes from 'prop-types';

const SlidingTransition = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  z-index: 2000;
  @keyframes slidein {
    from {
      margin-left: 100%;
    }

    to {
      margin-left: 0;
    }
  }

  @keyframes slideout {
    from {
      margin-left: 0;
    }

    to {
      margin-left: 100%;
    }
  }

  animation-duration: 0.4s;
  animation-name: ${(props) => props.animation || ''};

  @media (max-width: 768px) {
    left: 30%;
    width: 70%;
  }
`;

SlidingTransition.propTypes = {
  alignment: PropTypes.string,
};

export default SlidingTransition;
