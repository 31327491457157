import axios from "axios";

const setCommonApiKeyHeader = () => {
    axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_GATEWAY_PROXY_API_KEY;
};

const setCommonContentType = (contentType: string) => {
    axios.defaults.headers.get['Content-Type'] = contentType;
};

const appendApiKeyAsParam = (urlString: string): string => {
    let url = new URL(urlString);
    url.searchParams.set('apiKey', process.env.REACT_APP_GATEWAY_PROXY_API_KEY + "")
    return url.toString();
};

export const HttpRequestHandler = {
    setCommonApiKeyHeader,
    setCommonContentType,
    appendApiKeyAsParam,
};