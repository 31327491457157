import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import {
  tokens,
  HorizontalRule,
  InPageAlert,
  FormGroup,
} from '@snsw/react-component-library';

export const TextWrapper = styled.p`
  white-space: normal;
  ${Breakpoint('sm')`
  white-space: nowrap;
  `}
`;

export const SuspensionIdWrapper = styled.p`
  color: #646974;
  font-size: 0.875rem;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ImgWrapper = styled.div`
  @media screen and (max-width: ${tokens.breakpoints.md}px) {
    display: flex;
  }
`;

export const DivWrapper = styled.div`
  width: 36.375rem;
  height: 2.5rem;
  margin-bottom: 4.5rem;
  @media screen and (max-width: ${tokens.breakpoints.sm}px) {
    margin-bottom: 6.5rem;
    width: 100%;
  }
`;

export const StyleHorizontalRule = styled(HorizontalRule)`
  width: 48rem;
  @media screen and (max-width: ${tokens.breakpoints.md}px) {
    width: 100%;
  }
`;

export const StyledInPageAlert = styled(InPageAlert)`
  width: 100%;
  ${Breakpoint('lg')`
  width: 48rem;
  `}
`;

export const StyledFormGroup = styled(FormGroup)`
  label {
    font-weight: 500;
    font-size: 1rem;
  }
`;
