import React from 'react';
import moment from 'moment';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { drivingLicenceExpiryMessage } from 'config/errorMessages';
import { Constants } from 'constants/constants';

const LicenceWarning = ({ licenceExpiryDate }) => {
  const expiryDate = moment(licenceExpiryDate, 'YYYY-MM-DD').format(
    'D MMMM YYYY'
  );
  const messageDescription = `Your driver licence expires on ${expiryDate}. You’re still required to renew your licence even during a good behaviour period. [Renew online|${Constants.RENEW_UPGRADE_LICENCE_LINK}] or in a [service centre].`;

  return (
    <NotificationMessage
      message={{
        ...drivingLicenceExpiryMessage,
        messageDescription: messageDescription,
      }}
    />
  );
};

export default LicenceWarning;
