import React from 'react';

export const questionWithBold = (question: string) => (
  <>
    {question.substring(0, question.lastIndexOf('if I incur'))}
    <span style={{ fontWeight: 'bold' }}>
      {question.substring(
        question.lastIndexOf('if I incur'),
        question.lastIndexOf('during the')
      )}
    </span>
    {question.substring(
      question.lastIndexOf('during the'),
      question.lastIndexOf('twice the period')
    )}
    <span style={{ fontWeight: 'bold' }}> twice the period </span>
    {question.substring(question.lastIndexOf('indicated on'))}
  </>
);

export const declaration2 = (question: string) => (
  <>
    {question.substring(0, question.lastIndexOf('12 months'))}
    <span style={{ fontWeight: 'bold' }}>
      {question.substring(
        question.lastIndexOf('12 months'),
        question.lastIndexOf('rather than')
      )}
    </span>
    {question.substring(question.lastIndexOf('rather than'))}
  </>
);

export const declarationDKTBold = (question3: string) => (
  <>
    {question3.substring(0, question3.lastIndexOf('pass a driver'))}
    <span style={{ fontWeight: 'bold' }}>pass a driver knowledge test</span>
    {question3.substring(question3.lastIndexOf('before') - 1)}
  </>
);
