import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ContentContainer,
  FormGroup,
  Input,
  Button,
  TextLink,
  tokens,
} from '@snsw/react-component-library';
import { ComponentLoader } from '@snsw/react-component-library';
import { Svg, Spacer, UserSession } from '@rmstransactions/components';
import {
  validateName,
  validationData,
  validateNumber,
  validateAlphaNumericCharacters,
} from 'utils/helper';
import {
  SubHeading,
  MainHeading,
  StyledFormRadioGroup,
  TitleHeading,
} from './PersonalDetailsPage.styled';
import { Constants } from 'constants/constants';
import {
  validateCustomer,
  handleButtonClickGA,
  interceptors,
} from 'services/ElectGoodBehaviourService';
import { IValidateRequest } from 'interfaces/IValidateCustomer';
import { validateSliceActions } from 'redux/slice/validate';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import ProgressStepperComp from 'components/ProgressStepper/ProgressStepperComp';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import InlineNotificationMessage from 'components/NotificationMessage/InlineNotificationMessage';
import { ButtonGroup, ContentWrapper } from 'utils/styling/styled';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import {
  handleErrorInResponse,
  redirectToErrorPage,
} from 'services/EGBErrorService';
import { handleResponseData } from 'utils/api/httpClient';
import { personalDetailsActions } from 'redux/slice/personDetails';
import RefreshAlert from 'components/RefreshAlert/RefreshAlert';

const personalDetailsOptions = [
  { value: 'NSW Driver Licence', label: 'NSW Driver Licence' },
  { value: 'Photo Card', label: 'Photo Card' },
];

const PersonalDetailsPage = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [isMobile, setMobile] = useState(
    window.innerWidth < tokens.breakpoints.md
  );
  const [lastName, setLastName] = useState('');
  const [showLastNameInvalid, setShowLastNameInvalid] = useState(false);
  const [lastNameInvalidMessage, setLastNameInvalidMessage] = useState('');
  const [licenceNumber, setLicenceNumber] = useState('');
  const [showLicenceNumberInvalid, setShowLicenceNumberInvalid] =
    useState(false);
  const [licenceNumberInvalidMessage, setLicenceNumberInvalidMessage] =
    useState('');
  const [pcNumber, setPCNumber] = useState('');
  const [showPCNumberInvalid, setShowPCNumberInvalid] = useState(false);
  const [pcNumberInvalidMessage, setPCNumberInvalidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [cardNumber, setCardNumber] = useState('');
  const [showCardNumberInvalid, setShowCardNumberInvalid] = useState(false);
  const [cardNumberInvalidMessage, setCardNumberInvalidMessage] = useState('');
  const [count, setCount] = useState(0);
  const [inPageAlert, setInPageAlert] = useState(false);
  const [notificationMessage, setNotificationMessage] =
    useState<DriveMessageDetails>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const errorAlertRef = useRef<HTMLDivElement>(null);
  const [errorCountOnNextButton, updateErrorCountOnNextButton] =
    useState<number>(0);

  const {
    userType,
    cardSelection,
    lastNameState,
    licenceNumberState,
    cardNumberState,
    pcNumberState,
  } = useStoreSelector((state) => ({
    userType: state.userType.type,
    cardSelection: state.personalDetails.cardSelection,
    lastNameState: state.personalDetails.lastName,
    licenceNumberState: state.personalDetails.licenceNumber,
    cardNumberState: state.personalDetails.cardNumber,
    pcNumberState: state.personalDetails.pcNumber,
  }));

  const loadingText = (
    <p>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </p>
  );

  const handleQuit = () => {
    handleButtonClickGA(
      Constants.QUIT_BUTTON,
      Constants.BUTTON_SECONDARY,
      pageUrl
    );
    window.location.href = Constants.DRUPAL_LINK_EGB;
  };

  const updateMedia = () => {
    setMobile(window.innerWidth < tokens.breakpoints.md);
  };

  const handleEdit = () => {
    if (inputRef.current !== null) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const getFieldLabel = (fieldName: string, id: string) => {
    return (
      <TextLink data-testid='errLink' href={id} onClick={() => handleEdit()}>
        {fieldName}
      </TextLink>
    );
  };

  const onChangeLastName = (lastName: string) => {
    lastName = lastName.toUpperCase();
    setLastName(lastName);
    validateLastName(lastName);
    getErrorCount();
  };

  const validateLastName = (lastName: string) => {
    lastName = lastName ?? '';
    const validateLastNameError = validateName(lastName, 'LAST_NAME');
    if (
      validateLastNameError === 'SPECIAL_CHARACTER' ||
      validateLastNameError === 'LAST_NAME_EMPTY' ||
      validateLastNameError === 'MAX_LENGTH_NAME'
    ) {
      setShowLastNameInvalid(true);
      setLastNameInvalidMessage(validationData[validateLastNameError]);
      return true;
    } else {
      setShowLastNameInvalid(false);
      return false;
    }
  };

  const onChangePCNumber = (pcNumber: string) => {
    setShowPCNumberInvalid(false);
    setPCNumberInvalidMessage('');
    setPCNumber((pcNumber = pcNumber.toUpperCase()));
    validatePCNumber(pcNumber);
    getErrorCount();
  };

  const validatePCNumber = (pcNumber: string) => {
    pcNumber = pcNumber ?? '';
    const validatePCNumberError = validateNumber(pcNumber, 'PC_NUMBER');
    if (
      validatePCNumberError === 'SPECIAL_CHARACTER' ||
      validatePCNumberError === 'PC_NUMBER_EMPTY' ||
      validatePCNumberError === 'MAX_LENGTH_REACHED'
    ) {
      setShowPCNumberInvalid(true);
      setShowLicenceNumberInvalid(false);
      setPCNumberInvalidMessage(validationData[validatePCNumberError]);
      return true;
    } else {
      setShowPCNumberInvalid(false);
      return false;
    }
  };

  const onChangeLicenceNumber = (licenceNumber: string) => {
    licenceNumber = licenceNumber.toUpperCase();
    setLicenceNumber(licenceNumber);
    validateLicenceNumber(licenceNumber);
    getErrorCount();
  };

  const validateLicenceNumber = (licenceNumber: string) => {
    licenceNumber = licenceNumber ?? '';
    const validateLicenceNumberError = validateAlphaNumericCharacters(
      licenceNumber,
      'LICENCE_NUMBER'
    );
    if (
      validateLicenceNumberError === 'SPECIAL_CHARACTER' ||
      validateLicenceNumberError === 'LICENCE_NUMBER_EMPTY' ||
      validateLicenceNumberError === 'MAX_LENGTH_REACHED'
    ) {
      setShowLicenceNumberInvalid(true);
      setShowPCNumberInvalid(false);
      setLicenceNumberInvalidMessage(
        validationData[validateLicenceNumberError]
      );
      return true;
    } else {
      setShowLicenceNumberInvalid(false);
      return false;
    }
  };

  const onChangeCardNumber = (cardNumber: string) => {
    setCardNumber(cardNumber);
    validateCardNumber(cardNumber);
    getErrorCount();
  };

  const validateCardNumber = (cardNumber: string) => {
    cardNumber = cardNumber ?? '';
    const validateCardNumberError = validateNumber(cardNumber, 'CARD_NUMBER');
    if (
      validateCardNumberError === 'SPECIAL_CHARACTER' ||
      validateCardNumberError === 'STOCK_NUMBER_EMPTY' ||
      validateCardNumberError === 'MAX_LENGTH_REACHED'
    ) {
      setShowCardNumberInvalid(true);
      setCardNumberInvalidMessage(validationData[validateCardNumberError]);
      return true;
    } else {
      setShowCardNumberInvalid(false);
      return false;
    }
  };

  const inputValidationMessage = (
    errorMessage: string,
    fieldName: string,
    id: string
  ): JSX.Element => {
    return errorMessage.includes('Format') ||
      errorMessage.includes('The name entered') ? (
      <li>
        {getFieldLabel(fieldName, id)}
        {' ' + errorMessage}
      </li>
    ) : (
      <li>{getFieldLabel(errorMessage, id)}</li>
    );
  };

  const ValidationError = () => {
    return (
      count > 0 && (
        <>
          <p>Please check the following {count} items:</p>
          <ul>
            {showLastNameInvalid &&
              inputValidationMessage(
                lastNameInvalidMessage,
                'Last name:',
                '#last_name'
              )}
            {showLicenceNumberInvalid &&
              inputValidationMessage(
                licenceNumberInvalidMessage,
                'Licence number:',
                '#licence_number'
              )}
            {showPCNumberInvalid &&
              inputValidationMessage(
                pcNumberInvalidMessage,
                'Photo card number:',
                '#pc_number'
              )}

            {showCardNumberInvalid &&
              inputValidationMessage(
                cardNumberInvalidMessage,
                'Card Number:',
                '#card_number'
              )}
          </ul>
        </>
      )
    );
  };

  const getErrorCount = (): number => {
    let counter = 0;
    if (showLastNameInvalid) counter++;
    if (showLicenceNumberInvalid) counter++;
    if (showPCNumberInvalid) counter++;
    if (showCardNumberInvalid) counter++;
    setCount(counter);

    return counter;
  };

  const isValidationError =
    inPageAlert &&
    (showLastNameInvalid ||
      showLicenceNumberInvalid ||
      showPCNumberInvalid ||
      showCardNumberInvalid);

  const callValidateCustomerApi = async (
    cardNumber: string,
    cardStockNumber: string,
    familyName: string
  ) => {
    cardNumber = cardNumber.replace(/\s/g, '');
    cardStockNumber = cardStockNumber.replace(/\s/g, '');
    setIsLoading(true);
    const validateRequest: IValidateRequest = {
      familyName: familyName,
      cardNumber: cardNumber,
      cardStockNumber: cardStockNumber,
    };
    const validateData = await validateCustomer(validateRequest);
    setIsLoading(false);

    const data = handleResponseData(validateData);
    if (!data) {
      redirectToErrorPage(history);
      return;
    }
    const { response, systemMessages } = data;
    const inlineMessage = handleErrorInResponse(history, systemMessages);

    if (inlineMessage) {
      if (inlineMessage.identifier === 'CUSTOMER_NOT_FOUND') {
        inlineMessage.messageTitle =
          Constants.CUSTOMER_NOT_FOUND_INLINE_ERROR_TITLE;
        inlineMessage.messageDescription =
          Constants.CUSTOMER_NOT_FOUND_INLINE_ERROR_DESCRIPTION;
      }
      setNotificationMessage(inlineMessage);
    } else if (response) {
      dispatch(validateSliceActions.setValidateData(response));
      history.push(Constants.SUSPENSION_DETAILS_PAGE_URL);
    }
  };

  const handleNextButtonClick = async () => {
    handleButtonClickGA(
      Constants.NEXT_BUTTON,
      Constants.BUTTON_PRIMARY,
      pageUrl
    );
    window.scrollTo(0, 0);
    setInPageAlert(true);
    dispatch(
      personalDetailsActions.setDetails({
        lastName,
        cardNumber,
        licenceNumber,
        pcNumber,
      })
    );
    let showInvalidMessage = false;
    showInvalidMessage = validateLastName(lastName) || showInvalidMessage;
    showInvalidMessage = validateCardNumber(cardNumber) || showInvalidMessage;
    showInvalidMessage =
      cardSelection === 'NSW Driver Licence'
        ? validateLicenceNumber(licenceNumber) || showInvalidMessage
        : validatePCNumber(pcNumber) || showInvalidMessage;

    if (!showInvalidMessage) {
      setIsLoading(true);
      setNotificationMessage(null);
      callValidateCustomerApi(
        cardSelection === 'NSW Driver Licence' ? licenceNumber : pcNumber,
        cardNumber,
        lastName
      );
    } else {
      updateErrorCountOnNextButton((prevErrorCount) => prevErrorCount + 1);
    }
    getErrorCount();
  };

  const resetErrorsFromPreviousSelection = () => {
    setShowLastNameInvalid(false);
    setShowLicenceNumberInvalid(false);
    setShowPCNumberInvalid(false);
    setShowCardNumberInvalid(false);
    setInPageAlert(false);
    setLicenceNumber('');
    setPCNumber('');
    setCardNumber('');
  };

  const loadSessionData = () => {
    if (cardSelection !== null) {
      setLastName(lastNameState);
      setLicenceNumber(licenceNumberState);
      setPCNumber(pcNumberState);
      setCardNumber(cardNumberState);
    }
  };

  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.PERSONAL_DETAILS_PAGE_URL;

  useEffect(() => {
    interceptors(history);
    if (
      (UserSession.isLoginUser() &&
        userType === Constants.AUTHENTICATED_LINKED) ||
      userType === null //condition to check the store value for refresh scenario
    ) {
      history.push(Constants.LANDING_PAGE_URL);
      return;
    }
    setIsLoading(false);
    window.addEventListener('resize', updateMedia);
    getErrorCount();
    return () => window.removeEventListener('resize', updateMedia);
  }, [
    showLastNameInvalid,
    showLicenceNumberInvalid,
    showCardNumberInvalid,
    showPCNumberInvalid,
  ]);

  useEffect(() => {
    if (errorCountOnNextButton > 0 && errorAlertRef.current) {
      errorAlertRef.current.focus();
    }
  }, [errorCountOnNextButton]);

  useEffect(() => {
    loadSessionData();
  }, []);
  return (
    <>
      <ComponentLoader fullPage label={loadingText} active={isLoading} />
      <ProgressStepperComp
        steps={0}
        label='Apply for a good behaviour period'
        title='Personal details'
      />
      <ContentContainer>
        <ContentWrapper>
          {isValidationError ? (
            <InlineNotificationMessage
              MessageComp={ValidationError}
              errorAlertRef={errorAlertRef}
            />
          ) : (
            <NotificationMessage
              message={notificationMessage}
              marginBottom={true}
            />
          )}
          <MainHeading level={2}>
            Enter your NSW Driver Licence or Photo Card details
          </MainHeading>
          <SubHeading level={4}>
            Choose your ID type from the options below
          </SubHeading>
          Enter your details as they appear on your physical driver licence or
          Photo Card
          <StyledFormRadioGroup
            id='Choose_your_ID_type_from_the_options_below'
            className='Choose_your_ID_type_from_the_options_below'
            legend='ID type options'
            vertical={isMobile}
            name='Choose_your_ID_type_from_the_options_below'
            options={personalDetailsOptions}
            value={cardSelection}
            onChange={(selectedValue: string) => {
              dispatch(personalDetailsActions.setCardSelection(selectedValue));
              cardSelection === 'NSW Driver Licence'
                ? dispatch(personalDetailsActions.setPCNumber(''))
                : dispatch(personalDetailsActions.setLicenceNumber(''));
              resetErrorsFromPreviousSelection();
            }}
          />
          <TitleHeading level={3}>Front of the card</TitleHeading>
          {cardSelection === 'NSW Driver Licence' ? (
            <Svg.FrontOfLicenceCard width={231} height={116} />
          ) : (
            <Svg.FrontOfPhotoCard width={231} height={116} />
          )}
          <FormGroup
            className='last_name'
            id='last_name'
            label='Last name'
            name='last_name'
            helpMessage='Your last name shows in uppercase letters'
            errorMessage={lastNameInvalidMessage}
            hasError={showLastNameInvalid}
          >
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeLastName(event.target.value)
              }
              inputWidth='xl'
              inputRef={inputRef}
              value={lastName}
            />
          </FormGroup>
          {cardSelection === 'NSW Driver Licence' ? (
            <FormGroup
              className='licence_number'
              id='licence_number'
              label='Licence number'
              name='licence_number'
              helpMessage='This number appears above your signature'
              errorMessage={licenceNumberInvalidMessage}
              hasError={showLicenceNumberInvalid}
            >
              <Input
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeLicenceNumber(event.target.value)
                }
                inputWidth='xl'
                value={licenceNumber}
              />
            </FormGroup>
          ) : (
            <FormGroup
              className='pc_number'
              id='pc_number'
              label='PC number'
              name='pc_number'
              helpMessage='This number appears above your signature'
              errorMessage={pcNumberInvalidMessage}
              hasError={showPCNumberInvalid}
            >
              <Input
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangePCNumber(event.target.value)
                }
                inputWidth='xl'
                value={pcNumber}
              />
            </FormGroup>
          )}
          <TitleHeading level={3}>Back of the card</TitleHeading>
          <Spacer mt={-0.5} />
          <p>Turn your physical card over to find your stock number</p>
          <Svg.BackOfLicenceCard width={239} height={116} />
          <FormGroup
            className='card_number'
            id='card_number'
            label='Stock number'
            name='card_number'
            helpMessage='This number appears on the top left corner on the back of your card'
            errorMessage={cardNumberInvalidMessage}
            hasError={showCardNumberInvalid}
          >
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeCardNumber(event.target.value)
              }
              inputWidth='xl'
              value={cardNumber}
            />
          </FormGroup>
          <ButtonGroup>
            <Button
              style={{ marginRight: '1rem' }}
              data-testid='nextButton'
              onClick={handleNextButtonClick}
            >
              Next
            </Button>
            <Spacer mt={1} className='mobile-spacer' />
            <Button
              onClick={handleQuit}
              data-testid='quitButton'
              theme='secondary'
            >
              Quit
            </Button>
          </ButtonGroup>
        </ContentWrapper>
      </ContentContainer>
      <RefreshAlert />
    </>
  );
};

export default PersonalDetailsPage;
