import React from 'react';
import { NavLink, NavLinkMyAccount } from '@snsw/react-global-nav';
import { UserSession } from '@rmstransactions/components';
const myAccountEnvUrl = process.env.REACT_APP_MY_ACCOUNT_BASE_URL + '/overview';
export const logoutUrl =
  process.env.REACT_APP_MY_ACCOUNT_BASE_URL + '/api/auth/logout';

const MyAccountNav = () => {
  let authenticated = UserSession.isLoginUser();
  if (authenticated) {
    return (
      <>
        <NavLinkMyAccount title='MyServiceNSW Account' url={myAccountEnvUrl} />
        <NavLink title='Logout' url={logoutUrl} />
      </>
    );
  }
  return <NavLink title='MyServiceNSW Account' url={myAccountEnvUrl} />;
};

export default MyAccountNav;
