import styled from 'styled-components';
import { space } from 'styled-system';

const Row = styled.div`
  ${space};
  display: flex;
  flex-wrap: wrap;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};
  margin-right: -14px;
  margin-left: -14px;

  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;

export default Row;
