import React from 'react';
import {Constants} from '../Constants/Constants';
import {ErrorService} from '../Services/ErrorService';
import {useLocation} from 'react-router-dom';
import {Header} from "@rmstransactions/components";
import {Button, Col, ContentContainer, InPageAlert, Row} from "@snsw/react-component-library";
import {ButtonGroup, ErrorMessageContainer} from "../../Styled/ErrorMessageContainer";

const LoggedOutErrorPage = () => {
    window.scrollTo(0, 0);
    const location = useLocation();
    let errorMessageObject, firstButton, secondButton, header;
    if (location && location.state) {
        errorMessageObject = location.state.errorMessageObject;
        firstButton = location.state.firstButton;
        secondButton = location.state.secondButton;
        header = location.state.header;
    }
    if (location && location.state) {
        errorMessageObject = location.state.errorMessageObject;
        firstButton = location.state.firstButton;
        secondButton = location.state.secondButton;
        header = location.state.header;
    }

    let displayMessage = ErrorService.getAccessibleMessage(errorMessageObject
        ? errorMessageObject.messageDescription
        : Constants.TECHNICAL_ERROR_PAGE_MESSAGE);

    const handleClick = (url) => {
        window.location.href = url;
    }

    return (
        <>
            <Header title={header ? header : Constants.TECHNICAL_ERROR_PAGE_HEADER} data-testid='header' />
            <ContentContainer>
                <Row>
                    <Col span={8}>
                        <InPageAlert
                            variant='error'
                            title={errorMessageObject
                                ? errorMessageObject.messageTitle
                                : Constants.TECHNICAL_ERROR_PAGE_MESSAGE_HEADLINE}
                            data-testid='tech-error'
                        >
                            <ErrorMessageContainer dangerouslySetInnerHTML={{ __html: displayMessage }} />
                        </InPageAlert>

                        <ButtonGroup>
                            {
                                firstButton && <Button
                                    onClick={()=>handleClick(firstButton.btnLink)}
                                    theme={firstButton.btnType}
                                    className='buttons'
                                >
                                    {firstButton.btnText}
                                </Button>
                            }

                            {secondButton && (
                                <Button
                                    variant={secondButton.btnType}
                                    className='buttons button-n-plus-1'
                                    onClick={()=>handleClick(secondButton.btnLink)}
                                >
                                    {secondButton.btnText}
                                </Button>
                            )}

                        </ButtonGroup>
                    </Col>
                </Row>
            </ContentContainer>
        </>
    );
};

export default LoggedOutErrorPage;
