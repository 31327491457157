import styled from 'styled-components';
import { tokens } from '@snsw/react-component-library';

export const IconLabelWrapper = styled.div`
  display: flex;
  margin: 0;
  .iconWrapper,
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
    margin-top: 0.2rem;
  }
  p,
  button {
    margin: 0.25rem 0 1.25rem 0.25rem;
  }
`;

export const IconLabelDownload = styled.div`
  display: flex;
  margin-bottom: -0.25rem;

  .iconWrapper,
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  p,
  a {
    margin: 0;
  }
`;

export const ColWrapper = styled.div`
  width: 70%;
  padding-top: 1.5rem;
  @media screen and (max-width: ${tokens.breakpoints.lg - 1}px) {
    width: 100%;
  }
`;
