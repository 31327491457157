import styled from 'styled-components';
import {
  tokens,
  ContentContainer as SNSWContentContainer,
} from '@snsw/react-component-library';

export const ContentContainer = styled(SNSWContentContainer)`
  padding: 1.5rem 0;
  background-color: ${tokens.colors.grey.iceBlue};
`;

export const DisclaimerText = styled.p`
  margin: 0;
  font-size: 0.875rem;
`;

// focus is not working as intended, focus blue is not in GEL 1 tokens
const gel3FocusBlue = '#0085B3';
export const ExternalAnchor = styled.a`
  &:focus,
  :focus-within {
    outline: 0.1875rem solid ${gel3FocusBlue};
    outline-offset: 0.1875rem;
  }
`;
