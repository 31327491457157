import PropTypes from 'prop-types';
import { variant, compose } from 'styled-system';
import spaceVariants from './spaceVariants';

const responsiveVariantValues = {
  [spaceVariants.COMPACT]: {
    mx: [0.5, 0.5, 0.5, 1, 1.5, 1.5],
    my: [0.5, 0.5, 0.5, 1, 1.5, 1.5],
  },
  [spaceVariants.NORMAL]: {
    mx: [1, 1, 1, 1.5, 2, 2],
    my: [1, 1, 1, 1.5, 2, 2],
  },
  [spaceVariants.COMFORT]: {
    mx: [1.5, 1.5, 1.5, 2, 2.5, 2.5],
    my: [1.5, 1.5, 1.5, 2, 2.5, 2.5],
  },
};

const margin = variant({
  prop: 'mv',
  variants: responsiveVariantValues,
});
margin.propTypes = {
  mv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const marginT = variant({
  prop: 'mtv',
  variants: {
    [spaceVariants.COMPACT]: {
      mt: responsiveVariantValues[spaceVariants.COMPACT].my,
    },
    [spaceVariants.NORMAL]: {
      mt: responsiveVariantValues[spaceVariants.NORMAL].my,
    },
    [spaceVariants.COMFORT]: {
      mt: responsiveVariantValues[spaceVariants.COMFORT].my,
    },
  },
});
marginT.propTypes = {
  mtv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const marginB = variant({
  prop: 'mbv',
  variants: {
    [spaceVariants.COMPACT]: {
      mb: responsiveVariantValues[spaceVariants.COMPACT].my,
    },
    [spaceVariants.NORMAL]: {
      mb: responsiveVariantValues[spaceVariants.NORMAL].my,
    },
    [spaceVariants.COMFORT]: {
      mb: responsiveVariantValues[spaceVariants.COMFORT].my,
    },
  },
});
marginB.propTypes = {
  mbv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const marginX = variant({
  prop: 'mxv',
  variants: {
    [spaceVariants.COMPACT]: {
      mx: responsiveVariantValues[spaceVariants.COMPACT].mx,
    },
    [spaceVariants.NORMAL]: {
      mx: responsiveVariantValues[spaceVariants.NORMAL].mx,
    },
    [spaceVariants.COMFORT]: {
      mx: responsiveVariantValues[spaceVariants.COMFORT].mx,
    },
  },
});
marginX.propTypes = {
  mxv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const marginY = variant({
  prop: 'myv',
  variants: {
    [spaceVariants.COMPACT]: {
      my: responsiveVariantValues[spaceVariants.COMPACT].my,
    },
    [spaceVariants.NORMAL]: {
      my: responsiveVariantValues[spaceVariants.NORMAL].my,
    },
    [spaceVariants.COMFORT]: {
      my: responsiveVariantValues[spaceVariants.COMFORT].my,
    },
  },
});
marginY.propTypes = {
  myv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const marginL = variant({
  prop: 'mlv',
  variants: {
    [spaceVariants.COMPACT]: {
      ml: responsiveVariantValues[spaceVariants.COMPACT].mx,
    },
    [spaceVariants.NORMAL]: {
      ml: responsiveVariantValues[spaceVariants.NORMAL].mx,
    },
    [spaceVariants.COMFORT]: {
      ml: responsiveVariantValues[spaceVariants.COMFORT].mx,
    },
  },
});
marginL.propTypes = {
  mlv: PropTypes.oneOf(Object.values(spaceVariants)),
};

const marginR = variant({
  prop: 'mrv',
  variants: {
    [spaceVariants.COMPACT]: {
      mr: responsiveVariantValues[spaceVariants.COMPACT].mx,
    },
    [spaceVariants.NORMAL]: {
      mr: responsiveVariantValues[spaceVariants.NORMAL].mx,
    },
    [spaceVariants.COMFORT]: {
      mr: responsiveVariantValues[spaceVariants.COMFORT].mx,
    },
  },
});
marginR.propTypes = {
  mrv: PropTypes.oneOf(Object.values(spaceVariants)),
};

export default compose(
  margin,
  marginX,
  marginY,
  marginT,
  marginR,
  marginB,
  marginL
);
