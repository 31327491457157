import React from 'react';
import styled from 'styled-components';

const SkipToContentWrapper = styled.a`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;

  &:focus {
    top: 0;
    left: 0;
    background: #fff;
    clip: auto;
    overflow: visible;
    height: auto;
    width: auto;
  }
`;

const SkipToContent = () => {
  return (
    <SkipToContentWrapper href="#main-content">
      Skip to content
    </SkipToContentWrapper>
  );
};

export default SkipToContent;
