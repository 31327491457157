import React from 'react';
import { UserSession, Spacer } from '@rmstransactions/components';
import { Constants } from 'constants/constants';
import { ActivityAlertText, TextLink } from './ActivityAlert.styled';
import { useStoreSelector } from 'redux/store';

interface ActivityAlertProp {
  inputRef: any;
}

const ActivityAlert: React.FC<ActivityAlertProp> = ({ inputRef }) => {
  const {
    emailStored,
    emailUpdateRequiredStored,
    isGuest,
    emailAddress,
    userType,
  } = useStoreSelector((state) => ({
    emailStored: state.emailDetails.email,
    emailUpdateRequiredStored: state.emailDetails.emailUpdateRequired,
    isGuest: state.confirmation.isGuest,
    emailAddress: state.confirmation.emailAddress,
    userType: state.userType.type,
  }));
  const emailUpdateRequired = emailUpdateRequiredStored === 'Yes';
  const emailAddressToUse = emailUpdateRequired ? emailStored : emailAddress;
  const handleEdit = () => {
    if (inputRef.current !== null) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
      inputRef.current.focus({ preventScroll: true });
    }
  };
  return (
    <div>
      <Spacer mt='-1.5rem' />
      {isGuest &&
      (!UserSession.isLoginUser() ||
        userType === Constants.AUTHENTICATED_UNLINKED) &&
      !emailUpdateRequired ? (
        <>
          <ActivityAlertText>
            An activity alert has been sent to the email address we have on
            record.{' '}
            <a
              className='fontColor'
              href={
                'https://www.service.nsw.gov.au/transaction/change-email-address-or-mobile-number-transport-nsw'
              }
              target={'_blank'}
              rel='noopener noreferrer'
            >
              Update email address
            </a>
          </ActivityAlertText>
          <ActivityAlertText>
            If you haven’t received the activity alert, please call us on 13 77
            88.
          </ActivityAlertText>
        </>
      ) : (
        <>
          <ActivityAlertText>
            A receipt has been emailed to <strong>{emailAddressToUse}</strong>{' '}
            {!emailUpdateRequired && (
              <TextLink
                target='_blank'
                rel='noopener noreferrer'
                data-testid='Send to another email'
                onClick={() => handleEdit()}
                tabIndex={0} // tabIndex is added to make link focus for taborder
              >
                Send to another email
              </TextLink>
            )}
          </ActivityAlertText>
          <ActivityAlertText>
            If you haven’t received your receipt, please call us on 13 77 88.
          </ActivityAlertText>
        </>
      )}
      <ActivityAlertText>
        A formal letter of confirmation will arrive in the mail within{' '}
        <b>14 business days.</b>
      </ActivityAlertText>
    </div>
  );
};

export default ActivityAlert;
