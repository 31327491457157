import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';

interface FormWrapperProps {
  maxWidth?: string;
}

const FormWrapper = styled.div<FormWrapperProps>`
  max-width: 100%;
  min-height: 1px;

  ${Breakpoint('md')<FormWrapperProps>`
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '570px')};
  `}
`;

export default FormWrapper;
