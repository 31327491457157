import styled from 'styled-components';

import { Breakpoint } from '@rmstransactions/components/Styled';

export default styled.div`
  & #onegov-feedback-modal #ogf-navigation.ogf-static {
    box-shadow: none !important;
    display: flex !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    background: rgb(46, 82, 153) !important;
  }

  & #onegov-feedback-modal #ogf-navigation.ogf-static #ogf-feedback-label {
    padding: 8px 0 2px !important;
    white-space: normal !important;
    width: 100% !important;
    line-height: 1.2em !important;
    margin: 0 !important;
    font-size: 16px !important;
    font-weight: 500 !important;

    ${Breakpoint('md')`
      width: auto !important
      margin-right: 4px !important;
      padding: 8px 0 !important;
      white-space: normal !important;
      `}
  }

  & #onegov-feedback-modal .ogffont-thumbs-down:before,
  #onegov-feedback-modal #ogf-navigation .ogffont-current:before,
  & #onegov-feedback-modal .ogffont-thumbs-up:before {
    position: absolute !important ;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  & #onegov-feedback-modal #ogf-navigation.ogf-static .ogffont-feedback {
    top: unset !important;

    :before {
      font-size: 20px !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  & #onegov-feedback-modal #ogf-navigation.ogf-static button {
    padding: 6px 0 16px !important;
    margin: 0 !important;
    box-sizing: border-box !important;

    ${Breakpoint('md')`
      height: 45px !important;
      padding: 0 !important;
      margin: 0 0 0 11px !important;
    `}
  }

  & #onegov-feedback-modal #ogf-navigation .ogffont-thumbs-down,
  & #onegov-feedback-modal #ogf-navigation .ogffont-thumbs-up {
    width: 32px !important;

    ${Breakpoint('md')`
      margin-top: 4px !important;
      width: 30px !important;
    `}
  }

  & #onegov-feedback-modal #ogf-navigation #ogf-feedback-label,
  & #onegov-feedback-modal #ogf-comment-form #ogf-feedback-textbox-label,
  & #onegov-feedback-modal #ogf-comment-form #ogf-feedback-submit,
  & #onegov-feedback-modal #ogf-comment-form #ogf-feedback-textbox {
    font-family: "Gotham", Arial, sans-serif !important;
  }
`;
