import React from 'react';
import {
  CardStyle,
  CardTextStyle,
  PrivacyWrapper,
  NoWrap,
  AddressBold,
} from './KeepYourDetails.styled';
import {
  H3,
  BrandIconPersonalDataSecurity,
} from '@snsw/react-component-library';
import { Spacer, UserSession, helpers } from '@rmstransactions/components';
import { Constants } from 'constants/constants';
interface KeepYourDetailsProp {
  emailAddress: string;
  mailingAddress: string;
  userType: string;
}

const KeepYourDetails: React.FC<KeepYourDetailsProp> = ({
  emailAddress,
  mailingAddress,
  userType,
}) => {
  const convertToCamelCase = (address: string) => {
    {
      if (address === null) address = '';
      const regex = /,/g;
      address = address.replace(regex, ', ');
      const indexAtNSW = address.indexOf('NSW');
      return address
        .substring(0, indexAtNSW)
        .split(' ')
        .map((str) => helpers.sentenceCase(str))
        .join(' ')
        .concat(address.substring(indexAtNSW));
    }
  };

  return (
    <CardStyle>
      <PrivacyWrapper>
        <div className='iconWrapper'>
          <BrandIconPersonalDataSecurity />
        </div>
        <div>
          <CardTextStyle>
            <H3>Keep your details up to date</H3>
            <p>
              Keep your mail and email addresses up to date with Transport for
              NSW for important activity related to your licences and
              registrations.
            </p>
            <Spacer mt='1.5rem' />
            {!UserSession.isLoginUser() ||
            userType === Constants.AUTHENTICATED_UNLINKED ? (
              <>
                <NoWrap
                  href={Constants.UPDATE_DETAILS_LINK}
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  Update your details
                </NoWrap>
              </>
            ) : (
              <>
                <AddressBold>{convertToCamelCase(mailingAddress)}</AddressBold>
                <NoWrap
                  href={Constants.SERVICE_NSW_SHARING_CENTER_LINK}
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  Update your mail address
                </NoWrap>
                <Spacer mt='0.5rem' />
                <AddressBold>{emailAddress}</AddressBold>
                <NoWrap
                  href={Constants.UPDATE_EMAIL_ADDRESS_LINK}
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  Update your email address
                </NoWrap>
              </>
            )}
          </CardTextStyle>
        </div>
      </PrivacyWrapper>
    </CardStyle>
  );
};

export default KeepYourDetails;
