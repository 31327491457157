import { tokens, IconNotificationError } from '@snsw/react-component-library';

import styled from 'styled-components';

interface ChallengeContainerProps {
  $visible: boolean;
}

export const ChallengeContainer = styled.div<ChallengeContainerProps>`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
`;

export const ErrorMessageContainer = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr;
  gap: 0.75rem;
  color: ${tokens.colors.text.error};
`;

export const ErrorNotificationIcon = styled(IconNotificationError)`
  margin: 0.25rem 0;
`;
