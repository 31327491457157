import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { Modal, Card } from '@snsw/react-component-library';
import { Size } from '@rmstransactions/components/Styled';
import { tokens } from '@snsw/react-component-library';

export const ModalStyle = styled(Modal)`
  width: 100%;
`;

export const CardStyle = styled(Card)`
  display: flex;
  align-items: flex-start;
  margin: 0rem;
  padding: 2rem;
  height: 12rem;
  @media only screen and (max-width: ${Size.lg}px) {
    height: fit-content;
    margin: 0rem 0rem 0rem 0rem;
  }
`;

export const HeadingStyle = styled.p`
  font-weight: 450;
`;

export const CardTextStyle = styled.p`
  margin: 0;
`;

export const TermsDescription = styled.p`
  margin-top: 0;
`;

export const TermsLink = styled.a`
  cursor: pointer;
`;

export const PrivacyWrapper = styled.div`
  color: rgb(36, 41, 52);
  background-color: white;
  margin-bottom: 0rem;
  position: relative;
  padding: 0rem;

  @media only screen and (max-width: ${Size.md}px) {
    flex-flow: row wrap;
    margin: 0rem 0rem 0rem 0rem;
  }

  ${Breakpoint('sm')`
  display: contents;
  `}

  .iconWrapper,
  svg {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }
`;

export const DivEmailWrapper = styled.div`
  width: 31.063rem;
  
  @media screen and (max-width: ${tokens.breakpoints.sm}px) {
    width: 100%;
`;
