import styled from 'styled-components'
import { color, layout, typography } from 'styled-system'

const Box = styled.span(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  color,
  layout,
  typography
)

export default Box