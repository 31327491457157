import styled from 'styled-components';

const Table = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead > tr {
    background: rgb(222, 227, 229);
    border-radius: 0px;
    height: 57px;
    border: 1px solid rgb(222, 227, 229);
    font-weight: 300;
  }

  td,
  th {
    padding: 12px;
    text-align: left;
    font-weight: 300;
  }

  th {
    vertical-align: middle;
    font-weight: 500;
  }
  td {
    vertical-align: top;
  }
  tbody > tr {
    background: none;
    border-radius: 0px;
    border: 1px solid rgb(222, 227, 229);
  }

  tr > td:nth-child(odd),
  th:nth-child(odd) {
    width: 30%;
  }
`;
export default Table;
