import React from 'react';
import {BrandIconLocked, tokens} from '@snsw/react-component-library';
import styled from "styled-components";
import {Size} from "@rmstransactions/components/Styled";

const PrivacyStatementContainer = styled.div`
  display: flex;
  color: rgb(36, 41, 52);
  background: var(--Colour-Fill-White, #FFF);
  position: relative;
  padding: 2rem;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);

    @media only screen and (max-width: ${Size.md}px) {
        flex-flow: row wrap;
        padding: 2rem 1.5rem;
    }

    .iconWrapper, svg{
        width: 36px;
        height: 36px;
        margin-right: 0.5rem;
        
        @media only screen and (max-width: ${Size.md}px) {
            margin-bottom: 1rem;
        }
    }
`;


const PrivacyStatement = () => {
    return (
        <PrivacyStatementContainer>
            <div className="iconWrapper">
                <BrandIconLocked/>
            </div>
            <div>
                <span>
                    Your access and personal information is recorded so
                    technical assistance can be provided to you. For further
                    information about how your personal information is
                    handled, see the{' '}
                    <a
                        className="fontColor"
                        href={'https://www.service.nsw.gov.au/privacy'}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        Service NSW Privacy Statement
                    </a>{' '}
                    and the{' '}
                    <a
                        className="fontColor"
                        href={
                            'https://www.transport.nsw.gov.au/privacy-statement'
                        }
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        Transport for NSW Privacy Statement
                    </a>
                    .
                </span>
            </div>
        </PrivacyStatementContainer>
    );
};

export default PrivacyStatement;
