import { IProgressBarStep } from '../../interfaces/IProgressBarStep';

export const stepsListAuth: IProgressBarStep[][] = [
  [
    {
      content: 'Suspension ID',
      status: 'current',
    },
    {
      content: 'Declaration',
      status: 'todo',
    },
    {
      content: 'Review & Submit',
      status: 'todo',
    },
  ],
  [
    {
      content: 'Suspension ID',
      status: 'completed',
    },
    {
      content: 'Declaration',
      status: 'current',
    },
    {
      content: 'Review & Submit',
      status: 'todo',
    },
  ],
  [
    {
      content: 'Suspension ID',
      status: 'completed',
    },
    {
      content: 'Declaration',
      status: 'completed',
    },
    {
      content: 'Review & Submit',
      status: 'current',
    },
  ],
];
