import { setGlobalNavUri } from '@snsw/react-global-nav';
import { UserSession } from '@rmstransactions/components';

const apiKey = process.env.REACT_APP_GLOBAL_NAV_API_KEY;

const envLinks = [
  {
    env: 'it1',
    url: `https://api-it1.g.testservicensw.net`,
    key: apiKey,
  },
  {
    env: 'staging',
    url: `https://api-psm.g.testservicensw.net`,
    key: apiKey,
  },
  {
    env: 'prod',
    url: `https://api.g.service.nsw.gov.au`,
    key: apiKey,
  },
];

const MyAccountNavConfig = {
  setupGlobalNavUri(env) {
    const getEnvDetails = envLinks.filter((envLink) => envLink.env === env);
    const envURL = getEnvDetails[0].url;
    const key = getEnvDetails[0].key;
    setGlobalNavUri(envURL, { 'x-api-key': key });
  },
  setLoggedInUser() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isLoginRequired = params.get('isLoginRequired');
    if (isLoginRequired === 'false') {
      UserSession.setLoggedInUser();
    } else if (isLoginRequired === 'true') {
      UserSession.resetLoggedInUser();
    }
  },
};
export default MyAccountNavConfig;
