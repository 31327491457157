import React, { ComponentType, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Generic error handler for catching all react failures,
 *
 * @component
 * @param {string} errorPath Error path to redirect to
 * @example
 * const CatchAllError = GenericErrorHandler('/my-error-page');
 * <CatchAllError>
 *   <AnyComponent />
 * </CatchAllError>
 *
 */
export const GenericErrorHandler = (errorPath: string) => {
  const Wrapper: FunctionComponent = (props: any) => {
    const history = useHistory();
    try {
      return (
        <>
          <ErrorBoundary
            {...props}
            history={history}
            catchAllErrorPath={errorPath}
          ></ErrorBoundary>
        </>
      );
    } catch (error) {
      history.push(errorPath);
      return <></>;
    }
  };

  return Wrapper;
};

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { history, catchAllErrorPath } = this.props;
    if (this.state.hasError) {
      try {
        if (history) {
          history.push(catchAllErrorPath);
        }
      } catch (e) {
        // catch any error in the history as well
      }
      // The following signifies that no routing is available
      // or no history and errorPath is supplied
      // Users should not see this path usually
      return (
        <>We have encountered an unexpected error, please try again later</>
      );
    }

    return this.props.children;
  }
}
