import React from 'react';
import { Header, Container, FormWrapper, Spacer } from './../../index';
import { Notification, Button, colors } from '@snsw/react-component-library';
import errorPageMessages from './errorPageMessages';
import { Breakpoint } from './../../Styled';
import styled from 'styled-components';

const ErrorPageWrapper = styled.div`
  .error-page-btn {
    text-decoration: none;
    font-weight: 700;
  }

  .notice-wrapper {
    a {
      color: ${colors.blue} !important;
    }

    p {
      margin-top: 0;

      ${Breakpoint('sm')`
        margin-top: -1.75rem;
      `}
    }
  }
`;

const ErrorPage = ({
  title = "We're sorry about this...",
  errorCode = 'SYSTEM_FAILURE',
  maxWidth = '760px',
  className = '',
  buttonText = 'Quit',
  url = 'https://www.service.nsw.gov.au/services/driving-and-transport',
}) => {
  const getErrorMessage = (errCode = '') => {
    const filteredError = errorPageMessages.filter(
      (content) => content.id === errCode
    );
    return filteredError[0].message;
  };

  return (
    <ErrorPageWrapper className={className}>
      <Header title={title} />
      <Container pt={4}>
        <FormWrapper maxWidth={maxWidth}>
          <Spacer mb={2.5}>
            <Notification
              className="notice-wrapper"
              variant="error"
              role="alert"
              title=""
            >
              <p
                dangerouslySetInnerHTML={{ __html: getErrorMessage(errorCode) }}
              />
            </Notification>
          </Spacer>
          <Button
            className="error-page-btn"
            theme="secondary"
            href={url}
            data-testid="error-page-btn"
          >
            {buttonText}
          </Button>
        </FormWrapper>
      </Container>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
