import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import PersonalDetailsPage from 'pages/PersonalDetailsPage/PersonalDetailsPage';
import {
  RMSGlobalStyle,
  MyAccountNav,
  MyAccountNavConfig,
  LoggedOutErrorPage,
} from '@rmstransactions/components';
import { GlobalNav } from '@snsw/react-global-nav';
import { theme, GlobalStyle } from '@snsw/react-component-library';
import { ThemeProvider } from 'styled-components';
import { Config } from 'config/env';
import { LandingPage } from 'pages/LandingPage/LandingPage';
import DeclarationPage from 'pages/DeclarationPage/DeclarationPage';
import SuspensionDetailsPage from 'pages/SuspensionDetailsPage/SuspensionDetailsPage';
import ReviewAndSubmitPage from 'pages/ReviewAndSubmitPage/ReviewAndSubmitPage';
import { history } from 'utils/history';
import BridgingPage from 'pages/BridgingPage/BridgingPage';
import GoodBehaviourPage from 'pages/GoodBehaviourPage/GoodBehaviourPage';
import { Provider } from 'react-redux';
import store from 'redux/store';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import ErrorBoundaryHandler from 'components/ErrorBoundaryHandler/ErrorBoundaryHandler';
import GoogleAnalyticsMonitor from 'components/GoogleAnalyticsMonitor/GoogleAnalyticsMonitor';
import TimeoutHandler from './components/TimeoutHandler/TimeoutHandler';

const { globalNavEnv } = Config;
MyAccountNavConfig.setupGlobalNavUri(globalNavEnv);
MyAccountNavConfig.setLoggedInUser();

const App = () => {
  return (
    <>
      <RMSGlobalStyle />
      <TimeoutHandler />
      <GlobalNav accountNavLinks={<MyAccountNav />}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <main>
              <ErrorBoundaryHandler>
                <Router history={history}>
                  <GoogleAnalyticsMonitor />
                  <Switch>
                    <Route exact={true} path='/error' component={ErrorPage} />
                    <Route
                      path='/error/logged-out'
                      component={LoggedOutErrorPage}
                    />
                    <Route exact={true} path='/' component={LandingPage} />
                    <Route
                      path='/suspension-details'
                      component={SuspensionDetailsPage}
                    />
                    <Route
                      path='/personal-details'
                      component={PersonalDetailsPage}
                    />
                    <Route path='/bridging-page' component={BridgingPage} />
                    <Route
                      path='/declaration-details'
                      component={DeclarationPage}
                    />
                    <Route
                      path='/review-submit'
                      component={ReviewAndSubmitPage}
                    />

                    <Route
                      path='/view-receipt/:receiptUUID'
                      component={GoodBehaviourPage}
                    />
                    <Route
                      path='/good-behaviour'
                      component={GoodBehaviourPage}
                    />
                  </Switch>
                </Router>
              </ErrorBoundaryHandler>
            </main>
          </ThemeProvider>
        </Provider>
      </GlobalNav>
    </>
  );
};

export default App;
