const errorMessages = {
  email: {
    isRequired: 'Email address is required.',
    isValid: 'Please enter a valid email address',
  },
  download: {
    failed:
      'There was a problem downloading your receipt, please try again. Need help? Call us on 13 77 88 or visit a service centre.',
  },
  sendEmailError: {
    failed:
      'We were unable to send your email, please try again. Need help? Call us on 13 77 88 or visit a service centre.',
  },
};

export default errorMessages;
