import React from 'react';
import { SectionPage } from '@snsw/react-component-library';
import { Header } from '@rmstransactions/components';
import * as Styled from './ErrorPage.styled';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { useHistory, useLocation } from 'react-router-dom';
import { ContentContainer } from '@snsw/react-component-library';
import { ContentWrapper } from 'utils/styling/styled';
import { Constants } from 'constants/constants';
import {
  errorHistoryState,
  getErrorMessageFromHistoryState,
} from 'utils/route/stateUtils';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import { systemFailureError } from 'config/errorMessages';

export const genericErrorHeading = 'Apply for a good behaviour period';
export const systemFailureErrorHeading = "We're sorry about this...";

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const state = location.state as errorHistoryState;
  let errorMessage: DriveMessageDetails | null =
    getErrorMessageFromHistoryState(state);
  const isBusinessError = errorMessage?.classification === 'BUSINESS';
  const headingTitle = isBusinessError
    ? genericErrorHeading
    : systemFailureErrorHeading;
  if (
    errorMessage &&
    !errorMessage.messageTitle &&
    !errorMessage.messageDescription
  ) {
    errorMessage = {
      ...errorMessage,
      messageTitle: defaultMessageTitle,
      messageDescription: defaultMessageDescription,
    };
  }
  const history = useHistory();
  const backLink = () => {
    history.goBack();
  };

  return (
    <>
      <Header
        title={headingTitle}
        backLink={backLink}
        data-testid='header'
        linkText={isBusinessError && 'Previous'}
      />
      <ContentContainer>
        <ContentWrapper>
          <SectionPage>
            <NotificationMessage
              message={errorMessage ?? systemFailureError}
              marginBottom={true}
            />
          </SectionPage>

          <SectionPage>
            <Styled.ButtonContainer>
              <Styled.StyledButtonLink
                variant='secondary'
                href={Constants.DRUPAL_LINK_EGB}
              >
                Quit
              </Styled.StyledButtonLink>
            </Styled.ButtonContainer>
          </SectionPage>
        </ContentWrapper>
      </ContentContainer>
    </>
  );
};

export default ErrorPage;

const defaultMessageTitle = 'You cannot proceed online';
const defaultMessageDescription =
  'We could not complete your transaction online due to a technical error. Please visit a [service centre] or call us on 13 77 88. We apologise for any inconvenience.';
