import React, { ComponentType } from 'react';
import { StyledInPageAlert } from './NotificationMessage.styled';

const InlineNotificationMessage: React.FC<NotificationMessageProps> = ({
  messageTitle,
  MessageComp,
  errorAlertRef,
}) => {
  const title = messageTitle ?? 'Your page has errors';
  const variant = 'error';

  return (
    <StyledInPageAlert
      variant={variant}
      title={title}
      role='alert'
      innerRef={errorAlertRef}
      tabIndex={0}
    >
      <MessageComp />
    </StyledInPageAlert>
  );
};

export default InlineNotificationMessage;

interface NotificationMessageProps {
  messageTitle?: string | null;
  MessageComp: ComponentType;
  errorAlertRef?: React.RefObject<HTMLDivElement> | null;
}
