import { ProgressStepper } from '@rmstransactions/components';
import { Constants } from 'constants/constants';
import React from 'react';
import { useStoreSelector } from 'redux/store';
import { stepsList } from 'utils/progressBar/stepsList';
import { stepsListAuth } from 'utils/progressBar/stepsListAuth';

const ProgressStepperComp: React.FC<ProgressStepperProps> = ({
  steps,
  label,
  title,
}) => {
  const { userType } = useStoreSelector((state) => ({
    userType: state.userType.type,
  }));
  const authSteps = steps < 1 ? 0 : steps - 1;
  return (
    <ProgressStepper
      stepsList={
        userType === Constants.AUTHENTICATED_LINKED
          ? stepsListAuth[authSteps]
          : stepsList[steps]
      }
      label={label}
      title={title}
    />
  );
};

export default ProgressStepperComp;

interface ProgressStepperProps {
  steps: number;
  label: string;
  title: string;
}
