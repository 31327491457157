import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import pushAnalyticsNewPage from 'utils/session/pushAnalyticsNewPage';

const GoogleAnalyticsMonitor: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    pushAnalyticsNewPage();

    history.listen(pushAnalyticsNewPage);
  }, [history]);

  return null;
};

export default GoogleAnalyticsMonitor;
