import { AnalyticsSessionManager } from '@rmstransactions/components';

const transactionCleanupEvents = (): void => {
  // Google Analytics
  AnalyticsSessionManager.clearSessionID();
  AnalyticsSessionManager.clearUserID();
  AnalyticsSessionManager.clearUserLoginType();
  AnalyticsSessionManager.clearTransactionStartTime();
};

export default transactionCleanupEvents;
