import styled from 'styled-components';
import { Button } from '@snsw/react-component-library';

export const StyledButtonLink = styled(Button)`
  text-decoration: none !important;
`;

export const ButtonContainer = styled.div`
  margin-top: 3.5rem;
`;
