import React, { useEffect, useState } from 'react';
import {
  ContentContainer,
  H2,
  H4,
  Col,
  Row,
  Button,
} from '@snsw/react-component-library';
import { Header, Spacer } from '@rmstransactions/components';
import { ButtonGroup } from './styled';
import { useHistory } from 'react-router-dom';
import {
  callLogin,
  myAccCreateAccount,
  handleButtonClickGA,
} from 'services/ElectGoodBehaviourService';
import {
  GuestWrapper,
  RowWrapper,
  StyledCol,
  AccordionWrapper,
  StyleHorizontalRule,
  StyleVerticalRule,
  StyledRow,
  Styledli,
} from './styled';
import {
  Accordion,
  AccordionItem,
} from '@snsw/react-component-library/build/Components';
import { Constants } from 'constants/constants';

const BridgingPage = () => {
  const history = useHistory();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1023);

  const handlecontinueAsGuestBtn = (eventName: string, eventAction: string) => {
    const redirectUrl = Constants.PERSONAL_DETAILS_PAGE_URL;
    history.push(redirectUrl);
    handleButtonClickGA(eventName, eventAction, pageUrl);
    return;
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1023);
  };
  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.BRIDGING_PAGE_URL;

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const WhyCreateAcctDiv = (
    <>
      <H4>Why create an account?</H4>
      <RowWrapper>
        <StyledCol>
          <Spacer mt='1.7rem' />
          <img
            src='https://strapi-content-prod.s3.ap-southeast-2.amazonaws.com/licences_4558e33dfc.png'
            width='32'
            alt=''
          />
        </StyledCol>
        <Col span={12}>
          <Spacer mt='1.5rem' />
          <p>
            <b>Renew and check licences and registrations </b>such as driver
            licences, boat licences and vehicle registrations.{' '}
          </p>
        </Col>
      </RowWrapper>
      <RowWrapper>
        <StyledCol>
          <img
            src='https://strapi-content-prod.s3.ap-southeast-2.amazonaws.com/rebates_55a0b719ad.png'
            width='32'
            alt=''
          />
        </StyledCol>
        <Col span={12}>
          <Spacer mt='-1rem' />
          <p>
            <b>Claim vouchers and rebates</b> including Active Kids, Creative
            Kids, Toll Relief and more.{' '}
          </p>
        </Col>
      </RowWrapper>
      <RowWrapper>
        <StyledCol>
          <img
            src='https://strapi-content-prod.s3.ap-southeast-2.amazonaws.com/handy_info_bdf6220a31.png'
            width='32'
            alt=''
          />
        </StyledCol>
        <Col span={12}>
          <Spacer mt='-1rem' />
          <p>
            <b>Get personalised information </b>such as your demerit points,
            toll spend and more.
          </p>
        </Col>
      </RowWrapper>
    </>
  );

  const AccordionComponent = (
    <AccordionWrapper>
      <Accordion id='acc1'>
        <AccordionItem title='Why create an account?'>
          <ul style={{ padding: '0' }}>
            <Styledli>
              <img
                src='https://strapi-content-prod.s3.ap-southeast-2.amazonaws.com/licences_4558e33dfc.png'
                width='32'
                height='24'
              />
              <p style={{ margin: '0 0 0 1.125rem' }}>
                <b>Renew and check licences and registrations </b>such as driver
                licences, boat licences and vehicle registrations.
              </p>
            </Styledli>
            <Styledli>
              <img
                src='https://strapi-content-prod.s3.ap-southeast-2.amazonaws.com/rebates_55a0b719ad.png'
                width='32'
                height='24'
              />
              <p style={{ margin: '0 0 0 1.125rem' }}>
                <b>Claim vouchers and rebates</b> including Active Kids,
                Creative Kids, Toll Relief and more.
              </p>
            </Styledli>
            <Styledli>
              <img
                src='https://strapi-content-prod.s3.ap-southeast-2.amazonaws.com/handy_info_bdf6220a31.png'
                width='32'
                height='32'
              />
              <p style={{ margin: '0 0 0 1.125rem' }}>
                <b>Get personalised information </b>such as your demerit points,
                toll spend and more.
              </p>
            </Styledli>
          </ul>
        </AccordionItem>
      </Accordion>
    </AccordionWrapper>
  );

  const ContinueAsGuest = (
    <GuestWrapper>
      <H2>Continue as guest</H2>
      <p>
        We will ask you to provide proof of your identity if you choose to
        continue this transaction as a guest.
      </p>
      <Spacer mt={2} mb={2}>
        <Button
          id='continueAsGuestBtn'
          data-testid='continueAsGuestBtn'
          theme='secondary'
          onClick={() =>
            handlecontinueAsGuestBtn('Continue as guest', 'button_secondary')
          }
        >
          <b>Continue as guest</b>
        </Button>
      </Spacer>
    </GuestWrapper>
  );

  return (
    <>
      <Header
        data-testid='header'
        title='Log in, create an account or continue as guest'
        linkText='Previous'
        backLink={() => (window.location.href = Constants.DRUPAL_LINK_EGB)}
      />
      <ContentContainer style={{ padding: '0rem' }}>
        <StyledRow id='1'>
          <Col span={12}>
            <H2>MyServiceNSW Account</H2>
            <p>
              Connect online with NSW Government services, quicky and securely.
              This can help you save time filling out this transaction.
            </p>
            <ButtonGroup>
              <Spacer mt={2} mb={2}>
                <Button
                  id='logInBtn'
                  data-testid='logInBtn'
                  className='logInBtn'
                  onClick={() =>
                    callLogin('Log in', Constants.BUTTON_PRIMARY, pageUrl)
                  }
                >
                  <b>Log in</b>
                </Button>
                <Spacer mt={1} className='mobile-spacer' />
                <Button
                  id='createAccountBtn'
                  className='createAccountBtn'
                  data-testid='createAccountBtn'
                  theme='secondary'
                  onClick={() =>
                    myAccCreateAccount(
                      'Create Account',
                      Constants.BUTTON_SECONDARY,
                      pageUrl
                    )
                  }
                >
                  <b>Create Account</b>
                </Button>
              </Spacer>
            </ButtonGroup>
            {AccordionComponent}
          </Col>
          {isDesktop ? (
            <StyleVerticalRule></StyleVerticalRule>
          ) : (
            <StyleHorizontalRule />
          )}
          <Col span={9}>{ContinueAsGuest}</Col>
        </StyledRow>
        <Row id='2'>
          <Col span='6'>
            <Spacer mt='2rem' />
            {isDesktop && WhyCreateAcctDiv}
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
};

export default BridgingPage;
