import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import {
  tokens,
  HorizontalRule,
  Row,
  Col,
} from '@snsw/react-component-library';

export const ButtonGroup = styled.div`
  .logInBtn {
    ${Breakpoint('sm')`
    width:12.5rem;
    padding: 0.625rem;
    `}
    margin-right: 1.25rem;
  }
  .createAccountBtn {
    ${Breakpoint('sm')`
    width:12.5rem;
    `}
  }
  .mobile-spacer {
    display: block;
    ${Breakpoint('sm')`
    display: none;
  `}
  }
`;

export const GuestWrapper = styled.div`
  padding-left: 0;
  ${Breakpoint('lg')`
    padding-left: 6rem;
  `}
`;
export const RowWrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledRow = styled(Row)`
  @media screen and (max-width: 1023px) {
    display: contents;
  }
`;

export const StyledCol = styled(Col)`
  max-width: 10%;
  @media screen and (max-width: ${tokens.breakpoints.sm}px) {
    min-width: 15%;
  }
`;

export const AccordionWrapper = styled.div`
  @media screen and (min-width: ${tokens.breakpoints.lg}px) {
    display: none;
  }
`;

export const CreateAcctWrapper = styled.div`
  @media screen and (max-width: ${tokens.breakpoints.md}px) {
    display: none;
  }
`;
export const StyleVerticalRule = styled(HorizontalRule)`
  border-left: 0.125rem solid #dee3e5;
  left: 53%;
  position: absolute;
  height: 31.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.375rem;
`;
export const StyleHorizontalRule = styled(HorizontalRule)`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  @media screen and (min-width: ${tokens.breakpoints.lg}px) {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
`;

export const Styledli = styled.li`
  list-style-type: none;
  display: flex;
`;
