import styled from 'styled-components';

const ListStyle = styled.ul`
  list-style: disc;
  padding-left: 30px;
  margin-bottom: 1.5rem;

  li {
    padding: 0 0 0 10px;
    margin: 0 0 0.75rem 0;
  }
`;

export default ListStyle;
