import React from 'react';
import { colors } from '@snsw/react-component-library';

const Info = ({ color = colors.black, width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill={color}
    width={width}
    height={height}
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    focusable="false"
    aria-hidden="true"
  >
    <title>infomation</title>
    <path
      d="M54.63,9.37C48.62,3.37,40.49,0,32,0S15.37,3.37,9.37,9.37C3.37,15.38,0,23.51,0,32
      c0,8.49,3.37,16.63,9.37,22.63c6,6,14.14,9.37,22.63,9.37s16.63-3.37,22.63-9.37c6-6,9.37-14.14,9.37-22.63
      C64,23.51,60.63,15.37,54.63,9.37z M33,15.22c1.62,0,3.08,0.95,3.7,2.4c0.62,1.45,0.28,3.13-0.87,4.24
      c-1.14,1.11-2.86,1.45-4.36,0.84C29.97,22.1,29,20.68,29,19.11C29,16.96,30.79,15.22,33,15.22z M37,50h-8V28.61h8V50z"
    />
  </svg>
);

export default Info;
