import React from 'react';
import { SectionPage } from '@snsw/react-component-library';
import { systemFailureError } from 'config/errorMessages';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { ContentContainer } from '@snsw/react-component-library';
import { ContentWrapper } from 'utils/styling/styled';
import { Header } from '@rmstransactions/components';

const ErrorBoundaryPage: React.FC = () => {
  return (
    <>
      <Header
        title={genericTransactionErrorHeading}
        id='main-content'
        data-testid='content-header'
        backLink={() => window.history.back()}
      />
      <ContentContainer>
        <ContentWrapper>
          <SectionPage>
            <NotificationMessage message={systemFailureError} />
          </SectionPage>
        </ContentWrapper>
      </ContentContainer>
    </>
  );
};
export default ErrorBoundaryPage;
export const genericTransactionErrorHeading = "We're sorry about this...";
