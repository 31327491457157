import { default as FrontOfLicenceCard } from './FrontOfLicenceCard';
import { default as BackOfLicenceCard } from './BackOfLicenceCard';
import { default as FrontOfPhotoCard } from './FrontOfPhotoCard';
import { default as Calendar } from './Calendar';
import { default as Car } from './Car';
import { default as Email } from './Email';
import { default as Reminder } from './Reminder';
import { default as Info } from './Info';
import { default as Motorcycle } from './Motorcycle';
import { default as Phone } from './Phone';
import { default as Print } from './Print';
import { default as Spinner } from './Spinner';
import { default as Truck } from './Truck';
import { default as Warning } from './Warning';
import { default as Pdf } from './Pdf';
import { default as MotorBike } from './MotorBike';
import { default as NewCar } from './NewCar';
import { default as HeavyVehicle } from './HeavyVehicle';
import { default as RigidVehicle } from './RigidVehicle';
import { default as BrandEyeTest } from './BrandEyeTest';

const Svg = {
  FrontOfLicenceCard,
  BackOfLicenceCard,
  FrontOfPhotoCard,
  Calendar,
  Car,
  Email,
  Reminder,
  Info,
  Motorcycle,
  Phone,
  Print,
  Spinner,
  Truck,
  Warning,
  Pdf,
  MotorBike,
  NewCar,
  HeavyVehicle,
  RigidVehicle,
  BrandEyeTest,
};

export default Svg;
