import React from 'react';

const Warning = ({ width = 24, height = 20, color = '#DC5800' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Warning</title>
    <g
      id="Warning-Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Component/Check-Rego/Rego-Status/Expired/M,-L,-XL"
        transform="translate(0.000000, -5.000000)"
        fillRule="nonzero"
      >
        <g id="Icons/Status/Warning" transform="translate(0.000000, 3.000000)">
          <path
            d="M11.9998699,2.25 L0,21.9221311 L24,21.9221311 L11.9998699,2.25 Z M13.2540984,19.3607763 C13.2540984,19.4885294 13.1210084,19.5922131 12.956358,19.5922131 L10.7977404,19.5922131 C10.6330899,19.5922131 10.5,19.4885294 10.5,19.3607763 L10.5,17.8564368 C10.5,17.7286837 10.6330899,17.625 10.7977404,17.625 L12.956358,17.625 C13.1210084,17.625 13.2540984,17.7286837 13.2540984,17.8564368 L13.2540984,19.3607763 Z M12.956358,16.4938525 L10.7977404,16.4938525 C10.6330899,16.4938525 10.5,16.3743526 10.5,16.2271117 L10.5,8.89174076 C10.5,8.74449986 10.6330899,8.625 10.7977404,8.625 L12.956358,8.625 C13.1210084,8.625 13.2540984,8.74449986 13.2540984,8.89174076 L13.2540984,16.2271117 C13.2540984,16.3743526 13.1210084,16.4938525 12.956358,16.4938525 Z"
            id="Warning"
            fill={color}
          />
          <rect id="Rectangle" x="0" y="0" width={width} height={height} />
        </g>
      </g>
    </g>
  </svg>
);

export default Warning;
