import styled, { css } from 'styled-components';
import { FormGroup, Input, tokens } from '@snsw/react-component-library';

export const EmailFormGroup = styled(FormGroup)<EmailFormGroupProps>`
  width: 100%;
  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    max-width: 24rem;
  }

  ${({ $noLabel }) =>
    $noLabel &&
    css`
      margin-top: 0;

      > input {
        margin-top: 0;
      }
    `}
`;

interface EmailFormGroupProps {
  $noLabel?: boolean;
}

// the FormGroup component requires both props "hasError" AND "errorMessage" prop to colour,
// this fix is a bypass to not show a message but still have a red border
interface StyledInputProps {
  $hasError: boolean;
}

export const TextInput = styled(Input)<StyledInputProps>`
  ${(props) =>
    props.$hasError &&
    css`
      border-color: ${tokens.colors.borders.error};
    `}
`;
