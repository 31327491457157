import { sha256 } from 'js-sha256';

export const sessionIDStorageKey = 'sessionId';
export const userIDStorageKey = 'userId';
export const loginStatusStorageKey = 'userLoginType';
export const transactionStartTimeStorageKey = 'transactionStartTime';
export const userReturnStatusKey = 'userReturnStatus';

export type userLoginType =
  | 'fully authenticated'
  | 'transaction authenticated'
  | 'guest';

export type userReturnStatusType = 'new' | 'returning';

const AnalyticsSessionManager = {
  getSessionID: (): string | null => {
    return window.sessionStorage.getItem(sessionIDStorageKey);
  },
  setSessionID: (sessionID: string): void => {
    window.sessionStorage.setItem(sessionIDStorageKey, sessionID);
  },
  clearSessionID: (): void => {
    window.sessionStorage.removeItem(sessionIDStorageKey);
  },

  getUserID: (): string | null => {
    return window.sessionStorage.getItem(userIDStorageKey);
  },
  setUserID: (userID: string): void => {
    const encryptedUserID = sha256(userID);
    window.sessionStorage.setItem(userIDStorageKey, encryptedUserID);
  },
  clearUserID: (): void => {
    window.sessionStorage.removeItem(userIDStorageKey);
  },

  getUserLoginType: (): userLoginType | null => {
    return window.sessionStorage.getItem(
      loginStatusStorageKey
    ) as userLoginType | null;
  },
  setUserLoginType: (loginType: userLoginType): void => {
    window.sessionStorage.setItem(loginStatusStorageKey, loginType);
  },
  clearUserLoginType: (): void => {
    window.sessionStorage.removeItem(loginStatusStorageKey);
  },

  getTransactionStartTime: (): Date | null => {
    const dateTimeString = window.sessionStorage.getItem(
      transactionStartTimeStorageKey
    );
    return dateTimeString ? new Date(Number.parseInt(dateTimeString)) : null;
  },
  setTransactionStartTime: (): void => {
    const currentTime = new Date().getTime();
    window.sessionStorage.setItem(
      transactionStartTimeStorageKey,
      currentTime.toString()
    );
  },
  clearTransactionStartTime: (): void => {
    window.sessionStorage.removeItem(transactionStartTimeStorageKey);
  },

  getUserReturnStatus: (): userReturnStatusType | null => {
    return window.sessionStorage.getItem(
      userReturnStatusKey
    ) as userReturnStatusType | null;
  },
  setUserReturnStatus: (returnStatus: userReturnStatusType): void => {
    window.sessionStorage.setItem(userReturnStatusKey, returnStatus);
  },
  clearUserReturnStatus: (): void => {
    window.sessionStorage.removeItem(userReturnStatusKey);
  },
};

export default AnalyticsSessionManager;
