import dayjs from 'dayjs';
import numeral from 'numeral';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

// YYYY-MM-DD ISO date to dd/mm/yyyy Australian date
const AUDate = (date = '') => dayjs(date).format('D/M/YYYY');

// date to your desire date format with dayjs
const dateFormat = (date = '', format = '') => {
  if (date === '') return dayjs(new Date()).format(format);
  return dayjs(date).format(format);
};

const dateFromNow = (date = '') => dayjs(date).fromNow();

const smoothScrollToTop = (offsetTop = 0, offset = 0) => {
  window.scrollTo({
    top: offsetTop + offset,
    behavior: 'smooth',
  });
};

const AUD = (value) => numeral(value).format('$0,0[.]00');

const AUDdecimal = (value) => numeral(value).format('$0,0.00');

const numeric = (value) => numeral(value).format('0,0');

const sessionTime = 900000; // 15 minutes

function getCookieValue(name) {
  return (
    (name = new RegExp(
      '(?:^|;\\s*)' +
        ('' + name).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') +
        '=([^;]*)'
    ).exec(document.cookie)) && name[1]
  );
}

const removeCookie = (name) =>
  (document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);

const isDateTodayOrInFuture = (date = '') =>
  dayjs(date).isAfter(new Date(), 'day') ||
  dayjs(date).isSame(new Date(), 'day');

const ctpMonths = (term = '') => {
  switch (term) {
    case 'Annual':
      return 12;
    case 'Half Year':
      return 6;
    case 'Quarterly':
      return 3;
  }
};

const isNotEmptyValue = (value) => {
  return (
    value !== '' &&
    value !== null &&
    value !== undefined &&
    typeof value !== 'undefined'
  );
};

const isNotEmptyObject = (value) => {
  return value !== null && value !== undefined && typeof value !== 'undefined';
};

const sentenceCase = (value) => {
  if (isNotEmptyValue(value) && value.length > 1) {
    return (
      value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase()
    );
  } else return value;
};

const spellOutNumbers = (value) => {
  if (isNotEmptyValue(value) && value.length > 1) {
    let spellNumbers = '';
    for (let i = 0; i < value.length; i++) {
      spellNumbers += value.charAt(i) + ' ';
    }
    return spellNumbers;
  } else return value;
};

const convertToUpperCase = (value) => {
  if (isNotEmptyValue(value) && value.length > 1) {
    return value.toString().toUpperCase();
  } else return value;
};

export const helpers = {
  AUDate,
  dateFormat,
  isDateTodayOrInFuture,
  dateFromNow,
  smoothScrollToTop,
  AUD,
  AUDdecimal,
  numeric,
  sessionTime,
  getCookieValue,
  removeCookie,
  ctpMonths,
  isNotEmptyValue,
  isNotEmptyObject,
  sentenceCase,
  spellOutNumbers,
  convertToUpperCase,
};
