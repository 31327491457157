import styled from 'styled-components';
import { colors, tokens, FormGroup } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';

export const EmailContainer = styled.div`
  a {
    color: ${colors.blue};
  }
`;

export const EmailInlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (min-width: ${tokens.breakpoints.sm + 1}px) {
    flex-direction: row;
    gap: 0;
  }
  .formInputEmail {
    margin: 0 0 0 0;
    @media screen and (min-width: ${tokens.breakpoints.sm + 1}px) {
      min-width: 21.875rem;
    }
    @media screen and (max-width: ${tokens.breakpoints.sm}px) {
      max-width: 100%;
    }
    ${Breakpoint('sm')`
    margin: 0 1.25rem 0 0 ;
    `}
  }
  .sendButton {
    height: 3.375rem;
    width: 100%;
    @media screen and (min-width: 30em) {
      min-width: 7.125rem;
    }
    ${Breakpoint('sm')`
      height: 3.375rem;
      max-width: 7.125rem;
      margin: 0 1.25rem 0 0 ;
    `}
  }
`;

export const TextWrap = styled.p`
  .h-word-break {
    word-break: keep-all;
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1rem;
    margin: -1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1rem;
  }
`;
