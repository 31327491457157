import React, { Component, ReactNode } from 'react';
import ErrorBoundaryPage from './ErrorBoundaryPage/ErrorBoundaryPage';

class ErrorBoundaryHandler extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorBoundaryPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryHandler;

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}
