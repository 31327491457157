import styled, { css } from 'styled-components';
import { FormGroup, Input, tokens } from '@snsw/react-component-library';

interface ModifiedFormGroupProps {
  $hideLabel: boolean;
}

export const ModifiedFormGroup = styled(FormGroup)<ModifiedFormGroupProps>`
  ${({ $hideLabel }) =>
    $hideLabel &&
    css`
      > div {
        display: none;
      }
    `}
`;

export const ModifiedInput = styled(Input)`
  @media screen and (max-width: ${tokens.breakpoints.sm}px) {
    max-width: unset;
  }
`;
