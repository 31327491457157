import {
  IDeclarationDetails,
  IEligibilityDetails,
} from 'interfaces/IEligibilityDetails';
import { Question } from 'redux/slice/declarationForm';
import moment from 'moment';
import { Constants } from 'constants/constants';

export const handleEligibilityDetails = (
  eligibility: IEligibilityDetails,
  declaration: IDeclarationDetails,
  licenceNumber: string
): {
  businessTransaction: string;
  suspensionStartDate: string;
  suspensionEndDate: string;
  questions: Question[];
} => {
  const suspensionStartDateCurrent = eligibility.suspensionStartDate;
  const suspensionStartDate = suspensionStartDateCurrent
    ? moment(suspensionStartDateCurrent, 'YYYY-MM-DD').format(
        Constants.DATE_UI_FORMAT
      )
    : '';
  const suspensionEndDate = suspensionStartDateCurrent
    ? moment(suspensionStartDateCurrent, 'YYYY-MM-DD')
        .add(1, 'years')
        .subtract(1, 'days')
        .format(Constants.DATE_UI_FORMAT)
    : '';

  const businessTransaction = declaration.businessTransaction;
  const questions: {
    id: number;
    displayOrder: number;
    text: string;
  }[] = declaration.questions;

  const declarationQuestions: Question[] = questions.map(
    ({ id, displayOrder, text }) => {
      switch (displayOrder) {
        case 1:
          return {
            id,
            text: text.replace('|licenceNumber|', licenceNumber),
            answer: false,
          };
        case 2:
          return {
            id,
            text: text.replace('|commencementDate|', suspensionStartDate),
            answer: false,
          };
        case 3:
          return {
            id,
            text: text.replace('|suspensionStartDate|', suspensionStartDate),
            answer: false,
          };
        case 4:
          return {
            id,
            text,
            answer: false,
          };
      }
    }
  );

  return {
    businessTransaction,
    suspensionStartDate,
    suspensionEndDate,
    questions: declarationQuestions,
  };
};
