import React, { useState, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { Modal } from '@snsw/react-component-library';

const InactiveTimeout: React.FC<InactiveTimeOutProps> = ({
  timeoutInMinutes = 15,
  modalTitle = defaultModalTitle,
  modalContent = defaultModalContent,
  modalButtons = [],
}) => {
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const idleTimerRef = useRef<IdleTimer | null>(null);
  const timeoutInMillis = timeoutInMinutes * oneMinuteInMillis;

  const handleOnActive = () => {
    if (!hasTimedOut && idleTimerRef.current) {
      idleTimerRef.current.reset();
    }
  };

  const handleOnIdle = () => {
    setHasTimedOut(true);
    if (idleTimerRef.current) idleTimerRef.current.pause();
  };

  return (
    <>
      <IdleTimer
        ref={
          // this is an intended `any`, react-idle-timer does not support natively support the React useRef type
          idleTimerRef as any
        }
        timeout={timeoutInMillis}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        crossTab={{
          emitOnAllTabs: true,
        }}
      />
      {hasTimedOut && (
        <Modal title={modalTitle} buttons={modalButtons}>
          {modalContent}
        </Modal>
      )}
    </>
  );
};

export default InactiveTimeout;

interface InactiveTimeOutProps {
  timeoutInMinutes?: number;
  modalTitle?: string;
  modalContent?: JSX.Element;
  modalButtons?: { text: string; onClick: () => void }[];
}

const oneMinuteInMillis = 60 * 1000;
const defaultModalTitle = 'Your session has timed out';
const defaultModalContent = (
  <p>
    There has been no activity for the last 15 minutes or more. For your
    security, your session has ended.
  </p>
);
