import React from 'react';
import { InactiveTimeout } from '@rmstransactions/components';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';
import { Constants } from '../../constants/constants';
import { onUnloadShowWarning } from '../RefreshAlert/RefreshAlert';

const TimeoutHandler: React.FC = () => {
  return <InactiveTimeout modalButtons={modalButtons} />;
};

export default TimeoutHandler;

export const modalButtons = [
  {
    text: 'Start again',
    onClick: (): void => {
      transactionCleanupEvents();
      window.removeEventListener('beforeunload', onUnloadShowWarning);
      window.location.pathname = '/bridging-page';
    },
  },
  {
    text: 'Quit',
    onClick: (): void => {
      transactionCleanupEvents();
      window.removeEventListener('beforeunload', onUnloadShowWarning);
      window.location.href = Constants.DRUPAL_LINK_EGB;
    },
  },
];
