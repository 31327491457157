import { Constants } from '../Constants/Constants';

const KioskService = {
  sendMessage() {
    if (
      window.chrome &&
      chrome &&
      chrome.runtime &&
      chrome.runtime.sendMessage
    ) {
      chrome.runtime.sendMessage(
        Constants.KIOSK_PLUGIN_ID,
        { getid: true },
        function (response) {
          window.sessionStorage.setItem('kioskId', response.id);
        }
      );
    }
  },
  getKioskId() {
    return window.sessionStorage.getItem('kioskId');
  },
  isKiosk() {
    return window.sessionStorage.getItem('kioskId') !== null;
  },
  getServiceCenterId() {
    let siteRegexp = /S=(.*)/;
    let userAgentString = window.navigator.userAgent;
    let scId = siteRegexp.exec(userAgentString);
    var serviceCenterId;
    if (scId && scId.length > 1) {
      serviceCenterId = scId[1];
    } else {
      serviceCenterId = '';
    }
    return serviceCenterId;
  },
};
export default KioskService;
