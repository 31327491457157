import { AxiosError, AxiosResponse } from 'axios';

export const handleResponseData = <T>(
  response: AxiosResponse<T> | AxiosError
): T | null => {
  if (response && 'data' in response && !('isAxiosError' in response)) {
    return response.data;
  }
  return null;
};

export const handleResponseHeaders = (
  response: AxiosResponse | AxiosError
): Record<string, any> | null => {
  if (response && 'headers' in response && !('isAxiosError' in response)) {
    return response.headers;
  } else {
    return null;
  }
};
