import styled, { css } from 'styled-components';
import { InPageAlert } from '@snsw/react-component-library';

export const StyledInPageAlert = styled(InPageAlert)`
  margin-bottom: 3rem;
`;

export const StyledInPageAlertMessage = styled(InPageAlert)<InPageAlertProps>`
  margin: 0rem;

  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: 3rem;
    `}

  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: 3rem;
    `}
`;

interface InPageAlertProps {
  $marginTop?: boolean;
  $marginBottom?: boolean;
}
