import { Spacer, UserSession } from '@rmstransactions/components';
import {
  Button,
  Callout,
  ComponentLoader,
  ContentContainer,
  Col,
  FormCheckbox,
  H2,
  Row,
  TextLink,
} from '@snsw/react-component-library';
import { Constants } from 'constants/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  checkLogin,
  handleButtonClickGA,
} from 'services/ElectGoodBehaviourService';
import { validationData } from 'utils/helper';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { declarationFormSliceActions } from 'redux/slice/declarationForm';
import {
  declaration2,
  declarationDKTBold,
  questionWithBold,
} from './DeclarationHelpers';
import ProgressStepperComp from 'components/ProgressStepper/ProgressStepperComp';
import { ButtonGroup, ContentWrapper } from 'utils/styling/styled';
import InlineNotificationMessage from 'components/NotificationMessage/InlineNotificationMessage';
import RefreshAlert from 'components/RefreshAlert/RefreshAlert';
import {
  handleEGBAPIErrors,
  redirectToErrorPage,
} from 'services/EGBErrorService';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';

const DeclarationPage = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [inPageAlert, setInPageAlert] = useState(false);
  const [count, setCount] = useState(0);
  const [isDeclarationNotChecked, setIsDeclarationNotChecked] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const errorAlertRef = useRef(null);
  const [errorCount, updateErrorCount] = useState(0);
  const { businessTransaction, questions, customerSubjectedToItop2Message } =
    useStoreSelector((state) => ({
      businessTransaction: state.declarationForm.businessTransaction,
      questions: state.declarationForm.questions,
      customerSubjectedToItop2Message:
        state.declarationForm.customerSubjectedToItop2Message,
    }));

  const loadingText = (
    <p>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </p>
  );

  useEffect(() => {
    if (errorCount > 0 && errorAlertRef.current) {
      errorAlertRef.current.focus();
    }
  }, [errorCount]);

  const handleOnChange = (questionId: number, updatedAnswer: boolean) => {
    dispatch(
      declarationFormSliceActions.setAnswer({
        id: questionId,
        answer: updatedAnswer,
      })
    );

    if (updatedAnswer) {
      if (count <= 1) setIsDeclarationNotChecked(false);
      setCount(count - 1);
    } else {
      setCount(count + 1);
    }
  };

  const handleNextButtonClick = async () => {
    handleButtonClickGA(
      Constants.NEXT_BUTTON,
      Constants.BUTTON_PRIMARY,
      pageUrl
    );
    window.scrollTo(0, 0);
    setInPageAlert(true);
    let errorCount = 0;
    questions.forEach((question) => {
      if (!question.answer) {
        errorCount = errorCount + 1;
        setIsDeclarationNotChecked(true);
      }
    });
    setCount(errorCount);
    if (errorCount > 0) {
      updateErrorCount((prevKey) => prevKey + 1);
      return;
    }
    setIsLoading(false);
    if (UserSession.isLoginUser()) {
      checkLogin()
        .then((res) => {
          if (res.status === 200 || res.status === 307) {
            history.push(Constants.REVIEW_AND_SUBMIT_PAGE_URL);
          } else if (res.status === 401) {
            //logged out error page
            const error = { message: '401 Unauthorized' };
            handleEGBAPIErrors(error, history);
          } else {
            redirectToErrorPage(history);
          }
        })
        .catch(() => {
          redirectToErrorPage(history);
        });
    } else {
      history.push(Constants.REVIEW_AND_SUBMIT_PAGE_URL);
    }
  };

  const handleBackClick = async () => {
    handleButtonClickGA(
      Constants.BACK_BUTTON,
      Constants.BUTTON_SECONDARY,
      pageUrl
    );
    history.push(Constants.SUSPENSION_DETAILS_PAGE_URL);
    window.scrollTo(0, 0);
  };

  const handleEdit = () => {
    if (inputRef.current !== null) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const getFieldLabel = (fieldName: string, id: string) => {
    return (
      <TextLink data-testid='errLink' href={id} onClick={() => handleEdit()}>
        {fieldName}
      </TextLink>
    );
  };

  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.DECLARATION_PAGE_URL;

  useEffect(() => {
    //condition to check the store value for refresh scenario
    if (businessTransaction === '') {
      history.push('/');
    }
    setIsLoading(false);
  });

  const declarationOptions = questions?.map((question, index) => {
    return {
      id: 'checkbox' + (index + 1),
      questionId: question.id,
      label: question.text,
      value: 'Declaration' + (index + 1),
      checked: question.answer,
    };
  });

  const getDeclarationLabel = (questionText: string, index: number) => {
    switch (index) {
      case 0:
        return questionText;
      case 1:
        return declaration2(questionText);
      case 2: {
        return businessTransaction === Constants.ITOP1
          ? questionWithBold(questionText)
          : declarationDKTBold(questionText);
      }
      case 3:
        return questionWithBold(questionText);
    }
  };

  const ValidationError = () => {
    return (
      <>
        <p>Please check the following {count} items:</p>
        <ul>
          {isDeclarationNotChecked &&
            questions.map(({ answer }, index) => {
              if (!answer) {
                return (
                  <>
                    <li>
                      {getFieldLabel(
                        'Declaration ' + (index + 1),
                        '#checkbox' + (index + 1)
                      )}
                    </li>
                  </>
                );
              }
            })}
        </ul>
      </>
    );
  };

  const isValidationError = inPageAlert && isDeclarationNotChecked;
  return (
    <>
      <ComponentLoader fullPage label={loadingText} active={isLoading} />
      <ProgressStepperComp
        steps={2}
        label={'Apply for a good behaviour period'}
        title={'Declaration details'}
      />
      <ContentContainer>
        <ContentWrapper>
          {isValidationError && (
            <InlineNotificationMessage
              MessageComp={ValidationError}
              errorAlertRef={errorAlertRef}
            />
          )}
          <Row>
            <Col>
              To submit this application, you must declare that you agree to all
              of the statements.
              <Callout title='This is a legal declaration'>
                <p>
                  Read the statements carefully before proceeding. You will not
                  be able to withdraw, revoke or make any changes after
                  submission.
                </p>
              </Callout>
              <Spacer mt='2rem' />
              <NotificationMessage
                message={customerSubjectedToItop2Message}
                marginBottom={true}
              />
              <H2>I declare that</H2>
              <Spacer mt='2rem' />
              {declarationOptions.map((item, index) => {
                return (
                  <FormCheckbox
                    key={item.questionId}
                    id={item.id}
                    class={item.id}
                    name={item.value}
                    label={getDeclarationLabel(item.label, index)}
                    value={item.value}
                    testId={item.value}
                    errorMessage={
                      validationData['DECLARATION_CHECKBOX_UNCHECKED']
                    }
                    hasError={!item.checked && inPageAlert}
                    checked={item.checked}
                    onChange={() =>
                      handleOnChange(item.questionId, !item.checked)
                    }
                    isRequired
                  />
                );
              })}
              <Spacer mt='3rem' />
              If any details are incorrect, call us as soon as possible on 13 77
              88.
            </Col>
          </Row>
          <ButtonGroup>
            <Button
              id='nextButton'
              style={{ marginRight: '1rem' }}
              data-testid='nextButton'
              onClick={handleNextButtonClick}
            >
              Next
            </Button>
            <Spacer mt={1} className='mobile-spacer' />
            <Button
              onClick={handleBackClick}
              data-testid='backButton'
              theme='secondary'
            >
              Back
            </Button>
          </ButtonGroup>
        </ContentWrapper>
      </ContentContainer>
      <RefreshAlert />
    </>
  );
};

export default DeclarationPage;
