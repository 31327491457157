import React from 'react';

const BackOfLicenceCard = ({ width = 48, height = 35 }) => (
  <svg
    width={width}
    height={height}
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 243 114'
    style={{
      enableBackground: 'new 0 0 243 114',
    }}
    xmlSpace='preserve'
  >
    <style>{'.st2{fill:#242934}'}</style>
    <path
      style={{
        fill: 'none',
      }}
      d='M0 0h242.9v114H0z'
    />
    <path
      d='M79.5 2h146c6.1 0 11 4.9 11 11v87c0 6.1-4.9 11-11 11h-146c-6.1 0-11-4.9-11-11V13c0-6.1 4.9-11 11-11z'
      style={{
        fill: '#fff',
        stroke: '#979797',
      }}
    />
    <path
      className='st2'
      d='M3.9 35.4h1.7v-4.2c0-1.1.7-1.8 1.7-1.8s1.6.7 1.6 1.8v4.2h1.7v-4.7c0-1.7-1-2.8-2.6-2.8-1.1 0-1.8.6-2.3 1.3V28H3.9v7.4zm11.3.1c1.1 0 1.8-.6 2.3-1.3v1.1h1.7V28h-1.7v4.2c0 1.1-.7 1.8-1.7 1.8s-1.6-.7-1.6-1.8V28h-1.7v4.7c0 1.7 1 2.8 2.7 2.8zm6.1-.1H23v-4.2c0-1.1.6-1.8 1.6-1.8s1.5.6 1.5 1.8v4.2h1.7v-4.2c0-1.2.7-1.8 1.6-1.8 1 0 1.5.6 1.5 1.8v4.2h1.7v-4.7c0-1.8-1-2.8-2.6-2.8-1.1 0-1.9.5-2.5 1.3-.4-.8-1.1-1.3-2.2-1.3s-1.8.6-2.3 1.3V28h-1.7v7.4zm17.5.1c1.8 0 3.4-1.4 3.4-3.8 0-2.5-1.7-3.8-3.4-3.8-1.2 0-2 .6-2.5 1.4v-4.1h-1.7v10.2h1.7v-1.1c.5.6 1.3 1.2 2.5 1.2zm-.4-1.4c-1.1 0-2.2-.9-2.2-2.4 0-1.4 1-2.4 2.2-2.4 1.1 0 2.1.9 2.1 2.4s-.9 2.4-2.1 2.4zm9 1.5c1.4 0 2.3-.5 3-1.4l-1-.9c-.6.6-1.2.9-2 .9-1.1 0-1.9-.7-2.1-1.9h5.4v-.5c0-2.1-1.2-4-3.5-4-2.1 0-3.6 1.7-3.6 3.8 0 2.4 1.6 4 3.8 4zm-2.1-4.4c.2-1.1.9-1.9 1.9-1.9 1.1 0 1.8.9 1.9 1.9h-3.8zm7.1 4.2h1.7v-2.8c0-2 1-2.9 2.5-2.9h.1v-1.8c-1.3-.1-2.2.7-2.6 1.8V28h-1.7v7.4z'
    />
    <path
      d='m76.8 17.5-16.6 8.3V9.2l16.6 8.3z'
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#b81237',
      }}
    />
    <path
      className='st2'
      d='m83.6 13.9-1.5.4-.3-1.2 2.3-.7h1v8.5h-1.5v-7zM86.8 19.7l2.9-2.5c1.2-1 1.6-1.5 1.6-2.3 0-.8-.6-1.3-1.4-1.3s-1.3.4-1.9 1.3l-1-.8c.8-1.1 1.6-1.8 3.1-1.8 1.6 0 2.8 1 2.8 2.5 0 1.3-.7 2-2.2 3.2L89 19.4h4v1.3h-6.1v-1zM97.7 19.5l1-1c.6.7 1.3 1.1 2.2 1.1.8 0 1.5-.5 1.5-1.3s-.8-1.3-1.9-1.3h-.7l-.2-.9 2.3-2.4h-3.6v-1.3h5.6v1.1l-2.3 2.4c1.3.2 2.5.8 2.5 2.4 0 1.5-1.2 2.7-2.9 2.7s-2.8-.6-3.5-1.5zM109.6 19h-4.2l-.3-1.1 4.7-5.5h1.3v5.4h1.2V19H111v1.9h-1.4V19zm0-1.2v-3.3l-2.8 3.3h2.8zM113.4 19.7l.9-1.1c.7.6 1.4 1 2.2 1 1 0 1.7-.6 1.7-1.5s-.7-1.4-1.7-1.4c-.6 0-1.1.2-1.5.4l-.9-.6.2-4.1h5v1.3h-3.8l-.1 2c.4-.1.8-.2 1.3-.2 1.6 0 2.9.9 2.9 2.6 0 1.7-1.3 2.8-3.1 2.8-1.3.1-2.3-.4-3.1-1.2zM125.6 20.1c-.6-.6-1-1.5-1-3.2 0-2.6 1.2-4.6 3.6-4.6 1.1 0 1.8.3 2.6.9l-.8 1.1c-.6-.5-1.1-.7-1.8-.7-1.5 0-2 1.5-2 2.7.5-.5 1.1-.9 2-.9 1.6 0 2.9 1 2.9 2.6 0 1.7-1.4 2.9-3.1 2.9-1.1.1-1.9-.2-2.4-.8zm4-1.9c0-.9-.7-1.5-1.7-1.5s-1.7.7-1.7 1.5c0 .9.7 1.5 1.7 1.5s1.7-.6 1.7-1.5zM136.6 13.7h-4.2v-1.3h5.9v1.1l-3.8 7.3h-1.7l3.8-7.1zM139.3 18.6c0-1 .6-1.7 1.5-2.1-.7-.4-1.2-.9-1.2-1.9 0-1.3 1.2-2.3 2.9-2.3s2.9 1 2.9 2.3c0 .9-.5 1.5-1.2 1.9.9.4 1.5 1 1.5 2 0 1.5-1.4 2.4-3.2 2.4s-3.2-.9-3.2-2.3zm5-.1c0-.8-.8-1.3-1.8-1.3s-1.8.5-1.8 1.3c0 .7.6 1.3 1.8 1.3 1.1-.1 1.8-.6 1.8-1.3zm-.3-3.7c0-.7-.6-1.2-1.5-1.2s-1.5.5-1.5 1.2.6 1.2 1.5 1.2 1.5-.5 1.5-1.2z'
    />
    <path
      style={{
        fill: '#ebf2f7',
        stroke: '#979797',
      }}
      d='M68.5 29.2h168v20.4h-168z'
    />
    <path
      className='st2'
      d='m4.5 19 1-1.2c.9.8 1.8 1.2 3 1.2 1 0 1.7-.5 1.7-1.2s-.4-1-2.1-1.4c-2-.5-3.1-1.1-3.1-2.8 0-1.6 1.3-2.7 3.2-2.7 1.4 0 2.5.4 3.4 1.2l-.9 1.3c-.8-.6-1.7-1-2.6-1-1 0-1.5.5-1.5 1.1 0 .7.4 1.1 2.3 1.5 2 .5 3 1.2 3 2.8 0 1.8-1.4 2.8-3.4 2.8-1.5-.1-2.9-.6-4-1.6zM13.9 18.4v-3.8H13v-1.4h.9v-2h1.6v2h1.9v1.4h-1.9v3.5c0 .6.3.9.9.9.4 0 .7-.1 1-.2v1.3c-.4.2-.8.4-1.5.4-1.2 0-2-.5-2-2.1zM18.5 16.9c0-2.1 1.6-3.8 3.8-3.8 2.2 0 3.8 1.7 3.8 3.7s-1.6 3.7-3.8 3.7c-2.2 0-3.8-1.6-3.8-3.6zm5.9 0c0-1.3-.9-2.3-2.2-2.3-1.3 0-2.1 1-2.1 2.3 0 1.2.9 2.3 2.2 2.3 1.3-.1 2.1-1.1 2.1-2.3zM27.3 16.9c0-2.1 1.6-3.8 3.7-3.8 1.3 0 2.2.5 2.8 1.2l-1 1.1c-.5-.5-1-.9-1.8-.9-1.2 0-2.1 1-2.1 2.3 0 1.3.9 2.3 2.1 2.3.8 0 1.3-.4 1.8-.9l1 1c-.7.8-1.5 1.3-2.9 1.3-2 0-3.6-1.6-3.6-3.6zM35.4 10.5H37v5.9l3-3.2h2l-2.8 2.9 2.9 4.2h-1.9l-2.1-3.1-1 1.1v2h-1.6v-9.8z'
    />
  </svg>
);
export default BackOfLicenceCard;
