import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';


export const AccordionTitleContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;

    .accordion-title {
      color: #2e5299;
      margin: 0 1.2rem 0 0;
      font-weight: bold;
      ${Breakpoint('sm')`
      margin: 0 2.5rem 0 0;
    `}
    
    ${Breakpoint('xxs')`
        font-size: 16;
        margin: 0 1rem 0 0;
    `}  
   }
  `;
