import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 10px solid #333;
  box-sizing: border-box;
  animation: rms-sweep 1s linear alternate infinite,
    rms-rotate 0.8s linear infinite;

  @keyframes rms-rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rms-rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes rms-sweep {
    0% {
      -webkit-clip-path: polygon(
        0% 0%,
        0% 0%,
        0% 0%,
        50% 50%,
        0% 0%,
        0% 0%,
        0% 0%
      );
      clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
    }
    50% {
      -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        0% 100%,
        50% 50%,
        100% 0%,
        100% 0%,
        0% 0%
      );
      clip-path: polygon(
        0% 0%,
        0% 100%,
        0% 100%,
        50% 50%,
        100% 0%,
        100% 0%,
        0% 0%
      );
    }
    100% {
      -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        100% 100%,
        50% 50%,
        100% 100%,
        100% 0%,
        0% 0%
      );
      clip-path: polygon(
        0% 0%,
        0% 100%,
        100% 100%,
        50% 50%,
        100% 100%,
        100% 0%,
        0% 0%
      );
    }
  }

  @-webkit-keyframes rms-sweep {
    0% {
      -webkit-clip-path: polygon(
        0% 0%,
        0% 0%,
        0% 0%,
        50% 50%,
        0% 0%,
        0% 0%,
        0% 0%
      );
    }
    50% {
      -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        0% 100%,
        50% 50%,
        100% 0%,
        100% 0%,
        0% 0%
      );
    }
    100% {
      -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        100% 100%,
        50% 50%,
        100% 100%,
        100% 0%,
        0% 0%
      );
    }
  }
`;

const Spinner = ({ visible, color, stroke, radius, ...rest }) => {
  if (!visible) {
    return null;
  }

  return (
    <SpinnerWrapper
      {...rest}
      className="rms-icon-spinner"
      style={{
        width: radius,
        height: radius,
        borderColor: color,
        borderWidth: stroke,
      }}
    />
  );
};

Spinner.defaultProps = {
  visible: true,
  color: '#646974',
  stroke: 2,
  radius: 24,
};

Spinner.propTypes = {
  visible: PropTypes.bool,
  radius: PropTypes.number,
  color: PropTypes.string,
  stroke: PropTypes.number,
};

export default Spinner;
