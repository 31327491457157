import React from 'react';

const RigidVehicle = ({ width = 48, height = 36 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    focusable='false'
    aria-hidden='true'
  >
    <title>RigidVehicle</title>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M40.2801 23.8478C40.2801 24.1366 40.046 24.3707 39.7572 24.3707H32.2801C31.6173 24.3707 31.0801 23.8334 31.0801 23.1707V18.6707C31.0801 18.008 31.6173 17.4707 32.2801 17.4707H33.4128C33.7235 17.4707 34.0221 17.5912 34.2458 17.8069L40.1201 23.4714C40.2223 23.5699 40.2801 23.7058 40.2801 23.8478V23.8478Z'
      stroke='#202D60'
      stroke-width='2'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M35.7404 38.8402C33.9997 38.8402 32.5869 37.4273 32.5869 35.6863C32.5869 33.9453 33.9997 32.5332 35.7404 32.5332C37.4812 32.5332 38.8939 33.9453 38.8939 35.6863C38.8939 37.4273 37.4812 38.8402 35.7404 38.8402Z'
      stroke='#202D60'
      stroke-width='2'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12.4078 39.6066C10.5334 39.6066 9.01172 38.0856 9.01172 36.2102C9.01172 34.3355 10.5334 32.8145 12.4078 32.8145C14.2823 32.8145 15.8039 34.3355 15.8039 36.2102C15.8039 38.0856 14.2823 39.6066 12.4078 39.6066Z'
      fill='#D61E3A'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M45.8498 31.0908H44.6996V23.0778C44.6996 22.1365 44.3334 21.2326 43.6867 20.5656L36.5064 13.1608C35.8588 12.4938 34.9823 12.1153 34.0696 12.1153H30.8996V8.55767C30.8996 6.59316 29.3547 5 27.4498 5H4.44978C2.54571 5 1 6.59316 1 8.55767V32.277C1 34.2406 2.54571 35.8347 4.44978 35.8347H5.6C5.6 39.7628 8.69056 42.95 12.4996 42.95C16.3094 42.95 19.4 39.7628 19.4 35.8347H28.6C28.6 39.7628 31.6906 42.95 35.4996 42.95C39.3094 42.95 42.4 39.7628 42.4 35.8347H45.8498C46.4819 35.8347 47 35.3012 47 34.6485V32.277C47 31.6242 46.4819 31.0908 45.8498 31.0908Z'
      stroke='#202D60'
      stroke-width='2'
    />
  </svg>
);

export default RigidVehicle;
