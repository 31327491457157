import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { Size } from '@rmstransactions/components/Styled';
import { Card } from '@snsw/react-component-library';

export const CardStyle = styled(Card)`
  display: flex;
  align-items: flex-start;
  margin: 0rem;
  padding: 2rem;
  border-radius: 0.5rem;
  height: fit-content;
  box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
`;

export const PrivacyWrapper = styled.div`
  color: rgb(36, 41, 52);
  background-color: white;
  margin-bottom: 0rem;
  position: relative;
  padding: 0rem;

  @media only screen and (max-width: ${Size.md}px) {
    flex-flow: row wrap;
    margin: 0 0 0 0;
  }

  ${Breakpoint('sm')`
  display: contents;
  `}

  .iconWrapper,
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

export const CardTextStyle = styled.p`
  margin: 0;
`;

export const NoWrap = styled.a`
  white-space: nowrap;
  font-size: 0.875rem;
`;

export const AddressBold = styled.span`
  font-weight: bold;
  display: inline-flex;
  padding-right: 0.5rem;
`;
