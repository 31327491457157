import { KioskService } from '@rmstransactions/components';
import { Question } from 'redux/slice/declarationForm';
import { processSubmitApi } from 'services/ElectGoodBehaviourService';
import {
  Channel,
  IDeclaration,
  IProcessRequest,
} from 'interfaces/IDeclarationDetails';
import { DeviceUtils } from '@rmstransactions/components';
import { handleResponseData } from 'utils/api/httpClient';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';

export const handleProcessRequestApi = async (
  questions: Question[],
  emailAddress: string
): Promise<DriveMessageDetails[] | null> => {
  const declarationSubmitRequest: IProcessRequest = {
    emailAddress: emailAddress,
    channel: KioskService.isKiosk() ? Channel.UNASSISTED : Channel.SELF_SERVICE,
    declarations: getDeclarationResponses(questions),
    deviceUsed: DeviceUtils.deviceUsed(),
  };

  const responseData = await processSubmitApi(declarationSubmitRequest);
  const data = handleResponseData(responseData);
  if (data) {
    return data.systemMessages;
  }
  return null;
};

const getDeclarationResponses = (questions: Question[]) => {
  const questionJson = questions.map(({ id, text }, index) => {
    return {
      displayOrder: index + 1,
      displaySequence: index + 1,
      questionId: id,
      questionText: text,
    };
  });

  const declarationResponse1: IDeclaration = {
    question: questionJson[0],
    response: {
      answer: questions[0].answer ? ['Yes'] : ['No'],
      answerTitle: 'DriverLicenceNumberFlag',
    },
    subQuestionResponses: null,
  };
  const declarationResponse2: IDeclaration = {
    question: questionJson[1],
    response: {
      answer: questions[1].answer ? ['Yes'] : ['No'],
      answerTitle:
        questions[1].id === 19
          ? 'EGBPeriodStartFlag'
          : 'EGBPeriodStartOrDateTestFlag',
    },
    subQuestionResponses: null,
  };
  const declarationResponse3: IDeclaration = {
    question: questionJson[2],
    response: {
      answer: questions[2].answer ? ['Yes'] : ['No'],
      answerTitle:
        questions[2].id === 20
          ? 'MultipleDemeritPointsOffencesFlag'
          : 'DriverTestBeforeSuspensionDueFlag',
    },
    subQuestionResponses: null,
  };
  const response: IDeclaration[] = [
    declarationResponse1,
    declarationResponse2,
    declarationResponse3,
  ];

  if (questions.length === 4) {
    const declarationResponse4: IDeclaration = {
      question: questionJson[3],
      response: {
        answer: questions[3].answer ? ['Yes'] : ['No'],
        answerTitle: 'MultipleDemeritPointsOffencesFlag',
      },
      subQuestionResponses: null,
    };
    response.push(declarationResponse4);
  }
  return response;
};
