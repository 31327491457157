import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
export type errorHistoryState = undefined | Record<string, any>;

export const getErrorMessageFromHistoryState = (
  state: errorHistoryState
): null | DriveMessageDetails => {
  if (state) {
    return state.errorMessage as DriveMessageDetails;
  }
  return null;
};
