import React from 'react';
// import { H1, Icons, colors } from '@snsw/react-component-library';
// import { Breakpoint } from '@rmstransactions/components/Styled';
import { colors } from '@snsw/react-component-library';
import { useHistory } from 'react-router-dom';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

const HeaderProgressBar = ({ progressBar = [], step = 0 }) => {
  const ProgressBarWrapper = styled.div`
    display: flex;
    margin-bottom: 1.5rem;

    .progressbar__item {
      display: none;
      margin: 0 0.15rem;
      text-align: left;
      width: 100%;

      &:nth-of-type(${step + 1}) {
        display: block;
      }

      ${Breakpoint('md')`
        display: block;
        flex-grow: 1;
        width: auto;
        text-align: center;
      `}

      ${Breakpoint('lg')`
        width: ${(1 * 100) / progressBar.length}%
      `}

      &.is-active {
        &:hover,
        &:focus {
          cursor: pointer;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .is-active {
      .progressbar__label {
        color: ${colors.blue}
      }

      .progressbar__indicator {
        background-color: ${colors.blue}
      }
    }

    .progressbar__label {
      color: ${colors.lightGrey3}
      font-weight: 600;
      font-size: 0.9rem;
      display: inline-block;
      margin-bottom: 0.35rem;
    }

    .progressbar__indicator {
      background-color: ${colors.lightGrey4}
      height: 8px;
      width: 100%;
    }
  `;

  const history = useHistory();

  return (
    <ProgressBarWrapper>
      {progressBar.map((item, i) => (
        <div
          key={i}
          role="button"
          className={`progressbar__item ${step >= i ? ' is-active' : null}`}
          onClick={step > i ? () => history.push(item.url) : null}
          tabIndex={step >= i ? 0 : null}
        >
          <span className="progressbar__label">
            {isMobileOnly ? (
              <span>
                Step {step + 1} of {progressBar.length || ''}
              </span>
            ) : (
              item.label
            )}
          </span>
          <div className="progressbar__indicator" />
        </div>
      ))}
    </ProgressBarWrapper>
  );
};

export default HeaderProgressBar;
