import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EmailDetails {
  email: string | null;
  secondaryEmail: string | null;
  emailUpdateRequired: string | null;
}

export const initialState: EmailDetails = {
  email: null,
  secondaryEmail: null,
  emailUpdateRequired: null,
};

export const emailDetailsSlice = createSlice({
  name: 'emailDetails',
  initialState,
  reducers: {
    setEmailDetails: (
      state,
      action: PayloadAction<{
        email: string;
        secondaryEmail: string;
        emailUpdateRequired: string;
      }>
    ) => {
      state.email = action.payload.email;
      state.secondaryEmail = action.payload.secondaryEmail;
      state.emailUpdateRequired = action.payload.emailUpdateRequired;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setSecondaryEmail: (state, action: PayloadAction<string>) => {
      state.secondaryEmail = action.payload;
    },
    setEmailUpdateRequired: (state, action: PayloadAction<string>) => {
      state.emailUpdateRequired = action.payload;
    },
    resetEmailDetails: () => initialState,
  },
});

export const emailDetailsSliceActions = emailDetailsSlice.actions;
export const emailDetailsReducer = emailDetailsSlice.reducer;
