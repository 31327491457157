import React from 'react';
import { colors } from '@snsw/react-component-library';

const Phone = ({ color = colors.black, width = 40, height = 64 }) => (
  <svg
    viewBox="0 0 40 64"
    version="1.1"
    fill={color}
    width={width}
    height={height}
    focusable="false"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>mobile device</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-12.000000, 0.000000)">
        <g transform="translate(12.000000, 0.000000)">
          <g transform="translate(-12.000000, 0.000000)">
            <rect x="0" y="0" width="64" height="64" />
            <path
              d="M46,0 L18,0 C14.6875,0 12,2.6875 12,6 L12,58 C12,61.3125 14.6875,64 18,64 L46,64 C49.3125,64 52,61.3125 52,58 L52,6 C52,2.6875 49.3125,0 46,0 Z M32,60 C29.7875,60 28,58.2125 28,56 C28,53.7875 29.7875,52 32,52 C34.2125,52 36,53.7875 36,56 C36,58.2125 34.2125,60 32,60 Z M46,46.5 C46,47.325 45.325,48 44.5,48 L19.5,48 C18.675,48 18,47.325 18,46.5 L18,7.5 C18,6.675 18.675,6 19.5,6 L44.5,6 C45.325,6 46,6.675 46,7.5 L46,46.5 Z"
              fill="#242934"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Phone;
