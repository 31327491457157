import React, { useEffect, useState } from 'react';
import { ComponentLoader } from '@snsw/react-component-library';
import { useHistory } from 'react-router-dom';
import {
  UserSession,
  UserToken,
  AnalyticsSessionManager,
  analyticsServiceV2,
} from '@rmstransactions/components';
import {
  silentLogin,
  getUserType,
  getCustomerLicenceDetails,
  interceptors,
} from 'services/ElectGoodBehaviourService';
import { Constants } from 'constants/constants';
import { validateSliceActions } from 'redux/slice/validate';
import { userTypeSliceActions } from 'redux/slice/userType';
import { useStoreDispatch } from 'redux/store';
import {
  handleResponseData,
  handleResponseHeaders,
} from 'utils/api/httpClient';
import {
  handleErrorInResponse,
  redirectToErrorPage,
} from 'services/EGBErrorService';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';

export const LandingPage = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isLoginRequired =
    params.has('isLoginRequired') && params.get('isLoginRequired') === 'true';
  const [isLoading, setIsLoading] = useState(true);

  const loadingText = (
    <p>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </p>
  );

  const handleUserType = async () => {
    const response = await getUserType();
    const data = handleResponseData(response);
    const headers = handleResponseHeaders(response);
    if (!headers || headers['x-token'] === undefined || !data) {
      setIsLoading(false);
      redirectToErrorPage(history);
      return;
    }

    UserToken.setValue(response.headers['x-token']);
    if (data.type) {
      const userType = data.type;
      dispatch(userTypeSliceActions.setType(userType));
      // setup Google analytics
      AnalyticsSessionManager.setSessionID(response.data.sessionId);
      if (response.data.myAccountId) {
        AnalyticsSessionManager.setUserID(response.data.myAccountId);
      }
      const analyticsUserLoginType =
        analyticsServiceV2.analyticsUserLoginMapedValues[response.data.type];
      AnalyticsSessionManager.setUserLoginType(analyticsUserLoginType);
      AnalyticsSessionManager.setTransactionStartTime();

      if (isLoginRequired) {
        history.push(Constants.BRIDGING_PAGE_URL);
      } else if (
        !isLoginRequired &&
        userType === Constants.AUTHENTICATED_UNLINKED
      ) {
        setIsLoading(false);
        history.push(Constants.PERSONAL_DETAILS_PAGE_URL);
      } else if (!isLoginRequired) {
        setIsLoading(false);
        callGetCustomerLicenceDetails();
      }
    } else {
      setIsLoading(false);
      redirectToErrorPage(history);
    }
  };

  useEffect(() => {
    interceptors(history);
    if (UserSession.isSilentLoginCalled()) {
      UserSession.resetSilentLoginCalled();
      handleUserType();
    } else {
      UserSession.setSilentLoginCalled();
      // reset Google Analytics session values
      transactionCleanupEvents();
      silentLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, isLoginRequired]);

  const callGetCustomerLicenceDetails = async () => {
    setIsLoading(true);
    const responseData = await getCustomerLicenceDetails();
    setIsLoading(false);

    const data = handleResponseData(responseData);
    if (!data) {
      redirectToErrorPage(history);
      return;
    }
    const { response, systemMessages } = data;

    if (response) {
      dispatch(validateSliceActions.setValidateData(response));
      setIsLoading(false);
      history.push(Constants.SUSPENSION_DETAILS_PAGE_URL);
    } else {
      const inlineError = handleErrorInResponse(history, systemMessages);
      if (inlineError) {
        redirectToErrorPage(history, inlineError);
        return;
      }
    }
  };
  return <ComponentLoader fullPage label={loadingText} active={isLoading} />;
};
