const iPadAgentRegex = RegExp(/iPad/i);
const iPhoneAgentRegex = RegExp(/iPhone/i);
const webkitAgentRegex = RegExp(/WebKit/i);
const crIOSAgentRegex = RegExp(/CriOS/i); // Chrome
const fxIOSAgentRegex = RegExp(/FxiOS/i); // Firefox

const isDeviceIOS = (): boolean => {
  const { userAgent } = window.navigator;
  const iOS =
    iPadAgentRegex.exec(userAgent) ?? iPhoneAgentRegex.exec(userAgent);
  return Boolean(iOS);
};

const isDeviceIOSSafari = (): boolean => {
  const { userAgent } = window.navigator;
  const webkit = webkitAgentRegex.exec(userAgent);
  const iOSSafari =
    isDeviceIOS() &&
    webkit &&
    !crIOSAgentRegex.exec(userAgent) &&
    !fxIOSAgentRegex.exec(userAgent);
  return Boolean(iOSSafari);
};

const isDeviceIOSFirefox = (): boolean => {
  const { userAgent } = window.navigator;
  const webkit = webkitAgentRegex.exec(userAgent);
  const iOSFirefox = isDeviceIOS() && webkit && fxIOSAgentRegex.exec(userAgent);
  return Boolean(iOSFirefox);
};

const detectIOS = {
  isDeviceIOS,
  isDeviceIOSSafari,
  isDeviceIOSFirefox,
};

export default detectIOS;
