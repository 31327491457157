import { createBreakpoint } from 'styled-components-breakpoint';

const Breakpoint = createBreakpoint({
  xxs: 320,
  xs: 414,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1224,
});

export default Breakpoint;
