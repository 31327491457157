import { isMobile } from 'react-device-detect';

const iPadAgentRegex = RegExp(/iPad/i);
const mobileAgentRegex = RegExp(/Mobile/i);

export enum Device {
  MOBILE = 'MOBILE',
  IPAD = 'IPAD',
  DESKTOP = 'DESKTOP',
}

const isIPadDevice = (): boolean => {
  const { userAgent } = window.navigator;
  return iPadAgentRegex.test(userAgent);
};

const isMobileDevice = (): boolean => {
  const { userAgent } = window.navigator;
  return mobileAgentRegex.test(userAgent);
};

const deviceUsed = (): Device => {
  if (isIPadDevice()) return Device.IPAD;
  return isMobile || isMobileDevice() ? Device.MOBILE : Device.DESKTOP;
};

export const DeviceUtils = { isIPadDevice, isMobileDevice, deviceUsed };
