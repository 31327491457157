import React, { useState, useEffect, useRef } from 'react';
import Cross from './Cross';
import { FullScreenOpac } from './Screen.styled';
import { StyledSlidingModal } from './SlidingModal.styled';
import SlidingTransition from './SlidingTransition.styled';
import { getFocusableElement, trapTabKey } from './utils';
import { SROnly } from './utilities.styled';

const SlidingModal = ({ children, open, onClose }) => {
  const [currentAnimation, setCurrentAnimation] = useState('slidein');
  const [nextAnimation, setNextAnimation] = useState('slidein');
  const closeRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setCurrentAnimation(nextAnimation);
    }
  }, [open]); // eslint-disable-line

  const handleAnimationEnd = (animationName) => {
    if (animationName === 'slidein') {
      setNextAnimation('slideout');
    } else {
      setNextAnimation('slidein');
      setCurrentAnimation('slidein');
    }
  };

  const handleTabKey = (e) => {
    const focusableElems = getFocusableElement(modalRef.current);
    trapTabKey(e, focusableElems);
  };

  const onEscClose = (e) => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setCurrentAnimation(nextAnimation);
    } else {
      closeRef.current.focus();
    }
  }, [open]); // eslint-disable-line

  const keyListenersMap = new Map([
    [27, onEscClose],
    [9, handleTabKey],
  ]);

  useEffect(() => {
    const keyListener = (e) => {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    };

    if (open) {
      document.addEventListener('keydown', keyListener);
    } else {
      document.removeEventListener('keydown', keyListener);
    }

    // check this is removed even without the return
    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, [open, keyListenersMap]);

  const show = open || currentAnimation === 'slideout';

  if (!show) {
    return null;
  }

  return (
    <>
      <FullScreenOpac onClick={onClose} />
      <SlidingTransition
        role="dialog"
        aria-modal="true"
        animation={currentAnimation}
        ref={modalRef}
        onAnimationEnd={(event) => handleAnimationEnd(event.animationName)}
      >
        <StyledSlidingModal>
          <StyledSlidingModal.DismissButton
            onClick={onClose}
            data-test="modal-close-button"
            ref={closeRef}
          >
            <Cross color="#fff" />
            <SROnly>Close</SROnly>
          </StyledSlidingModal.DismissButton>
          {children}
        </StyledSlidingModal>
      </SlidingTransition>
    </>
  );
};

export default SlidingModal;
