const DEFAULT_ID = 'id__';

let globalID;

if (globalID === undefined) {
  globalID = 0;
}

export const getID = (prefix = DEFAULT_ID) => {
  globalID++;
  return `${prefix}${globalID}`;
};

export const focusObjectGenerator = (arr) => {
  const focusableElements = {
    all: arr,
    first: arr[0],
    last: arr[arr.length - 1],
    length: arr.length,
  };

  return focusableElements;
};

export const getFocusableElement = (el) => {
  const elementArr = [].slice.call(
    el.querySelectorAll(`a[href],button:not([disabled]),
    area[href],input:not([disabled]):not([type=hidden]),
    select:not([disabled]),textarea:not([disabled]),
    iframe,object,embed,*:not(.is-draggabe)[tabindex],
    *[contenteditable]`)
  );

  return focusObjectGenerator(elementArr);
};

export const trapTabKey = (event, focusObject) => {
  const { activeElement } = document;
  const focusableElement = focusObject;

  if (event.keyCode !== 9) {
    return false;
  }

  if (focusableElement.length === 1) {
    event.preventDefault();
  } else if (event.shiftKey && activeElement === focusableElement.first) {
    focusableElement.last.focus();
    event.preventDefault();
  } else if (!event.shiftKey && activeElement === focusableElement.last) {
    focusableElement.first.focus();
    event.preventDefault();
  }

  return true;
};

export const pxToRem = (size) => {
  return `${size / 16}rem`;
};

export const pxToEm = (size) => {
  return `${size / 16}em`;
};

const utils = {
  getID,
  getFocusableElement,
  trapTabKey,
  pxToRem,
  pxToEm,
};

export default utils;
