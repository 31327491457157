import React from 'react';
import { Constants } from 'constants/constants';

export const serviceCentreLink = (
  <a
    href={Constants.SERVICE_CENTER_LINK}
    target='_blank'
    rel='noopener noreferrer'
    className='TextLink'
  >
    service centre
  </a>
);

export const renewOnlineLink = (
  <a
    href={Constants.RENEW_UPGRADE_LICENCE_LINK}
    target='_blank'
    rel='noopener noreferrer'
    className='TextLink'
  >
    Renew online
  </a>
);

export const errorDescriptionSystemFailure =
  'You can try again later, call us on 13 77 88 or visit a [service centre]. We apologise for any inconvenience.';

export const errorDescriptionInvalidDetails =
  'Check your details and try again. Need help? Call us on 13 77 88 or visit a [service centre].';
