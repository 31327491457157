import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserTypeDetails {
  type: string | null;
}

export const initialState: IUserTypeDetails = {
  type: null,
};

export const userTypeSlice = createSlice({
  name: 'userType',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    resetUserType: () => initialState,
  },
});

export const userTypeSliceActions = userTypeSlice.actions;
export const userTypeReducer = userTypeSlice.reducer;
