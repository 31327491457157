import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Info from './Info';
import Question from './Question';
import SlidingModal from './SlidingModal';
import StyledModal from './Modal.styled';
import styled from 'styled-components';
import { Button } from '@snsw/react-component-library';
import { SROnly } from './utilities.styled';
import { getID } from './utils';

const IconButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  background: none;
  border: none;
  padding: 0;

  svg {
    margin: 0;
  }
`;

const MoreInfoModal = ({
  id,
  title,
  subTitle,
  helpText,
  questionIconFlag,
  questionHyperLink,
  hyperLinKText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [returnFocus, setReturnFocus] = useState(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);

    if (isOpen) {
      returnFocus.focus();
    } else {
      setReturnFocus(document.activeElement);
    }
  };

  const genID = () => {
    return id || getID();
  };

  const elemID = genID(),
    idTitle = `${elemID}-title`,
    idDesc = `${elemID}-desc`;

  if (!helpText) {
    return null;
  }

  return (
    <>
      {!questionHyperLink && (
        <IconButton type="button" onClick={toggleModal} aria-label="Show help">
          {questionIconFlag && <Info color="#2E5299" />}
          {!questionIconFlag && <Question color="#2E5299" />}
          {hyperLinKText && <SROnly>{hyperLinKText}</SROnly>}
        </IconButton>
      )}
      {questionHyperLink && !questionIconFlag && (
        <Button onClick={toggleModal} theme="link">
          {hyperLinKText}
        </Button>
      )}
      <SlidingModal open={isOpen} onClose={toggleModal}>
        <StyledModal.Wrapper>
          <StyledModal.Header id={idTitle} slidingPanel>
            <StyledModal.Title>{title}</StyledModal.Title>
            {subTitle}
          </StyledModal.Header>
          <StyledModal.Body id={idDesc}>{helpText}</StyledModal.Body>
        </StyledModal.Wrapper>
      </SlidingModal>
    </>
  );
};

MoreInfoModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  helpText: PropTypes.node,
  questionIconFlag: PropTypes.bool,
  questionHyperLink: PropTypes.bool,
  hyperLinKText: PropTypes.string,
};

MoreInfoModal.defaultProps = {
  questionIconFlag: false,
  questionHyperLink: false,
};

export default MoreInfoModal;
