import { Spacer, helpers } from '@rmstransactions/components';
import {
  Button,
  Col,
  H2,
  ComponentLoader,
  ContentContainer,
  FormCheckbox,
  FormGroup,
  HorizontalRule,
  Row,
  Input,
  BrandIconLocked,
  TextLink,
} from '@snsw/react-component-library';
import { Constants } from 'constants/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  handleButtonClickGA,
  interceptors,
} from 'services/ElectGoodBehaviourService';
import {
  HeadingStyle,
  TermsDescription,
  TermsLink,
  ModalStyle,
  PrivacyWrapper,
  CardStyle,
  CardTextStyle,
  DivEmailWrapper,
} from './styled';
import TermsAndConditionModalContent from './TermsAndConditionsModal/TermsAndConditionModalContent';
import { useStoreSelector, useStoreDispatch } from 'redux/store';
import ReviewDeclarations from './ReviewDeclarations/ReviewDeclarations';
import { handleProcessRequestApi } from './ReviewAndSubmitPage.action';
import {
  emailAddressValidationError,
  validationData,
  invalidEmailCharacters,
} from 'utils/helper';
import ProgressStepperComp from 'components/ProgressStepper/ProgressStepperComp';
import { ButtonGroup, ContentWrapper } from 'utils/styling/styled';
import InlineNotificationMessage from 'components/NotificationMessage/InlineNotificationMessage';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';
import { handleErrorInResponse } from 'services/EGBErrorService';
import { reviewSubmitInfoMessage } from 'config/errorMessages';
import { emailDetailsSliceActions } from 'redux/slice/emailDetails';
import RefreshAlert from 'components/RefreshAlert/RefreshAlert';

const ReviewAndSubmitPage = () => {
  const {
    customerName,
    licenceNumber,
    emailAddress,
    suspensionStartDate,
    businessTransaction,
    questions,
    customerSubjectedToItop2Message,
  } = useStoreSelector((state) => ({
    customerName: state.validate.customerName,
    licenceNumber: state.validate.licenceNumber,
    emailAddress: state.validate.emailAddress,
    suspensionStartDate: state.eligibility.suspensionStartDate,
    suspensionEndDate: state.eligibility.suspensionEndDate,
    businessTransaction: state.declarationForm.businessTransaction,
    questions: state.declarationForm.questions,
    customerSubjectedToItop2Message:
      state.declarationForm.customerSubjectedToItop2Message,
  }));

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [inPageAlert, setInPageAlert] = useState(false);
  const [toggleTAndCModal, setToggleTAndCModal] = useState(false);
  const [isChecked, setCheckedState] = useState(false);
  const [isInvalidTermsAndConditions, setIsInvalidTermsAndConditions] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailInput, setEmailInput] = useState('');

  const [showEmailInvalid, setShowEmailInvalid] = useState(false);
  const [emailInvalidMessage, setEmailInvalidMessage] = useState('');
  const [errorCount, setErrorCount] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const errorAlertRef = useRef(null);
  const termsLinkRef = useRef(null);
  const checkBoxRef = useRef(null);
  const [errorCountOnSubmit, updateErrorCountOnSubmit] = useState<number>(0);
  const [isTermsAndConditionsLinkClicked, setTermsAndConditionsLinkClicked] =
    useState<boolean>(false);
  const dispatch = useStoreDispatch();

  const loadingText = (
    <p>
      Processing, please wait.
      <br />
      Do not refresh or navigate away.
    </p>
  );

  useEffect(() => {
    //condition to check the store value for refresh scenario
    if (customerName.familyName === '') {
      history.push('/');
    }
  });

  useEffect(() => {
    if (!emailAddress)
      dispatch(emailDetailsSliceActions.setEmailUpdateRequired('Yes'));
  }, []);

  useEffect(() => {
    if (errorCountOnSubmit > 0 && errorAlertRef.current) {
      errorAlertRef.current.focus();
    }
  }, [errorCountOnSubmit]);

  useEffect(() => {
    if (
      !toggleTAndCModal &&
      !isChecked &&
      isTermsAndConditionsLinkClicked &&
      termsLinkRef.current
    ) {
      termsLinkRef.current.focus();
    }
    if (!toggleTAndCModal && isChecked && checkBoxRef.current) {
      checkBoxRef.current.focus();
    }
  }, [toggleTAndCModal]);

  const handleSubmitButtonClick = async () => {
    dispatch(emailDetailsSliceActions.setEmail(emailInput));
    handleButtonClickGA(
      Constants.NEXT_BUTTON,
      Constants.BUTTON_PRIMARY,
      pageUrl
    );
    window.scrollTo(0, 0);
    let isValidationError = false;
    setInPageAlert(true);
    if (emailAddress == '') {
      isValidationError = validateEmailInput(emailInput);
    }
    if (!isChecked) {
      updateErrorCountOnSubmit((prevErrorCount) => prevErrorCount + 1);
      setIsInvalidTermsAndConditions(true);
      setErrorMessage(Constants.TERMS_ERROR);
      isValidationError = isValidationError || !isChecked;
    }
    if (isValidationError) {
      return;
    }
    setIsLoading(true);
    handleProcessApiResponse();
  };

  const handleBackClick = async () => {
    handleButtonClickGA(
      Constants.BACK_BUTTON,
      Constants.BUTTON_SECONDARY,
      pageUrl
    );
    history.push(Constants.DECLARATION_PAGE_URL);
    window.scrollTo(0, 0);
  };

  const handleProcessApiResponse = async () => {
    const updatedEmail = !emailAddress ? emailInput : emailAddress;
    const systemMessages: DriveMessageDetails[] = await handleProcessRequestApi(
      questions,
      updatedEmail
    );
    setIsLoading(false);

    if (!systemMessages) {
      return;
    }
    handleErrorInResponse(history, systemMessages);
    if (systemMessages[0].severity != 'ERROR') {
      history.push(Constants.GOOD_BEHAVIOUR_PAGE_URL);
    }
  };

  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.DECLARATION_PAGE_URL;

  useEffect(() => {
    interceptors(history);
    setIsLoading(false);
    getErrorCount();
  }, [isInvalidTermsAndConditions, showEmailInvalid, history, errorCount]);

  const acceptTAndCs = () => {
    setToggleTAndCModal(false);
    setCheckedState(true);
    setTermsAndConditionsLinkClicked(true);
    setIsInvalidTermsAndConditions(false);
  };

  const hideTAndCs = () => {
    setTermsAndConditionsLinkClicked(true);
    setToggleTAndCModal(false);
  };

  const onChangeFormCheckBox = () => {
    if (isChecked) {
      setIsInvalidTermsAndConditions(true);
      setErrorMessage(Constants.TERMS_ERROR);
      setErrorCount(errorCount - 1);
    } else {
      setIsInvalidTermsAndConditions(false);
      setErrorCount(errorCount + 1);
    }
    setCheckedState(!isChecked);
  };

  const onChangeEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmailInput(email);
    if (invalidEmailCharacters(email)) {
      setShowEmailInvalid(true);
      setEmailInvalidMessage(validationData['EMAIL_INVALID']);
      setErrorCount(errorCount + 1);
    } else {
      setShowEmailInvalid(false);
      setErrorCount(errorCount - 1);
    }
  };

  function onBlurEmailAddress() {
    validateEmailInput(emailInput);
  }

  const handleEdit = () => {
    if (inputRef.current !== null) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const getFieldLabel = (fieldName: string, id: string) => {
    return (
      <TextLink data-testid='errLink' href={id} onClick={() => handleEdit()}>
        {fieldName}
      </TextLink>
    );
  };

  const validateEmailInput = (email: string) => {
    email = email ?? '';
    const validateEmailError = emailAddressValidationError(email);
    if (
      validateEmailError === 'EMAIL_EMPTY' ||
      validateEmailError === 'EMAIL_INVALID'
    ) {
      setEmailInvalidMessage(validationData[validateEmailError]);
      setShowEmailInvalid(true);
      return true;
    } else {
      setShowEmailInvalid(false);
      return false;
    }
  };

  function getErrorCount(): number {
    let count = 0;
    if (showEmailInvalid) count++;
    if (isInvalidTermsAndConditions) count++;
    setErrorCount(count);
    return count;
  }

  const ValidationError = () => {
    return (
      <>
        <p>Please check the following {errorCount} items:</p>
        <ul>
          {showEmailInvalid && (
            <li>
              {emailInvalidMessage.indexOf('valid') > -1
                ? getFieldLabel(
                    'Enter a valid email address to continue',
                    '#email_address'
                  )
                : getFieldLabel(
                    'Enter an email address to continue',
                    '#email_address'
                  )}
            </li>
          )}
          {isInvalidTermsAndConditions && (
            <>
              <li>
                {getFieldLabel(
                  'Accept the Terms and Conditions to continue',
                  '#termsAndConditions'
                )}
              </li>
            </>
          )}
        </ul>
      </>
    );
  };

  const isValidationError =
    inPageAlert &&
    errorCount > 0 &&
    (isInvalidTermsAndConditions || showEmailInvalid);

  return (
    <>
      <ComponentLoader fullPage label={loadingText} active={isLoading} />
      <ProgressStepperComp
        steps={3}
        label={'Apply for a good behaviour period'}
        title={'Review application and submit'}
      />
      <ContentContainer>
        <ContentWrapper>
          <Row>
            <Col>
              {isValidationError && (
                <InlineNotificationMessage
                  MessageComp={ValidationError}
                  errorAlertRef={errorAlertRef}
                />
              )}
              <NotificationMessage
                message={reviewSubmitInfoMessage}
                marginBottom={true}
              />
              <H2>Review application</H2>
              <Spacer mt={1} mb={3}>
                <HeadingStyle>Your name</HeadingStyle>
                <p>
                  {helpers.sentenceCase(customerName.givenName)}{' '}
                  {customerName.otherGivenNames &&
                    helpers.sentenceCase(customerName.otherGivenNames) + ' '}
                  {helpers.convertToUpperCase(customerName.familyName)}
                </p>
                <HeadingStyle>NSW Driver Licence</HeadingStyle>
                <p> {licenceNumber}</p>
                <HeadingStyle>Good behaviour period</HeadingStyle>

                {businessTransaction === Constants.ITOP1 ||
                customerSubjectedToItop2Message ? (
                  <p>Starts on {suspensionStartDate}</p>
                ) : (
                  <p>
                    Starts on {suspensionStartDate}, or the date I pass a driver
                    knowledge test (whichever is the later)
                  </p>
                )}
                <ReviewDeclarations
                  businessTransaction={businessTransaction}
                  questions={questions}
                />
                {!emailAddress && (
                  <>
                    <HorizontalRule />
                    <DivEmailWrapper>
                      <FormGroup
                        label='Send application receipt to this email address'
                        className='email_address'
                        id='email_address'
                        name='email_address'
                        errorMessage={emailInvalidMessage}
                        hasError={showEmailInvalid}
                      >
                        <Input
                          onBlur={onBlurEmailAddress}
                          onChange={onChangeEmailAddress}
                          inputWidth='xxl'
                          inputRef={inputRef}
                          value={emailInput}
                          data-testId='emailInput'
                        />
                      </FormGroup>
                    </DivEmailWrapper>
                  </>
                )}
                <Spacer mt='3rem' />
                <TermsDescription>
                  Please read and accept the{' '}
                  <TermsLink
                    type='button'
                    data-testid='tAndC-button'
                    onClick={(e) => {
                      e.preventDefault();
                      setToggleTAndCModal(true);
                    }}
                    tabIndex={0}
                    ref={termsLinkRef}
                    href=''
                  >
                    Terms and Conditions
                  </TermsLink>{' '}
                  before proceeding.
                </TermsDescription>
                {toggleTAndCModal && (
                  <>
                    <ModalStyle
                      title='Terms & Conditions: Good behaviour period'
                      isScrollable
                      onClose={hideTAndCs}
                      buttons={[
                        {
                          text: 'Accept',
                          id: 'acceptTAndCs',
                          onClick: acceptTAndCs,
                        },
                        {
                          text: 'Close',
                          id: 'closeTAndCs',
                          onClick: hideTAndCs,
                        },
                      ]}
                    >
                      <TermsAndConditionModalContent />
                    </ModalStyle>
                  </>
                )}
                <FormCheckbox
                  id='termsAndConditions'
                  testId='tAndCCheckBox'
                  name='accept terms and conditions'
                  label='I accept the Terms and Conditions'
                  value='accept'
                  onChange={onChangeFormCheckBox}
                  checked={isChecked}
                  hasError={isInvalidTermsAndConditions}
                  errorMessage={errorMessage}
                  inputRef={checkBoxRef}
                  isRequired
                />
              </Spacer>
              <CardStyle>
                <PrivacyWrapper>
                  <div className='iconWrapper'>
                    <BrandIconLocked />
                  </div>
                  <div>
                    <CardTextStyle>
                      Your access and personal information is recorded by
                      Service NSW so technical assistance can be provided to
                      you. Your details have been collected to be verified by
                      Transport for NSW to access your records. To learn how
                      your personal information is handled, see the{' '}
                      <a
                        className='fontColor'
                        href={'https://www.service.nsw.gov.au/privacy'}
                        target={'_blank'}
                        rel='noopener noreferrer'
                      >
                        Service NSW Privacy Statement
                      </a>{' '}
                      and the{' '}
                      <a
                        className='fontColor'
                        href={
                          'https://www.transport.nsw.gov.au/privacy-statement'
                        }
                        target={'_blank'}
                        rel='noopener noreferrer'
                      >
                        Transport for NSW Privacy Statement
                      </a>
                      .
                    </CardTextStyle>
                  </div>
                </PrivacyWrapper>
              </CardStyle>
            </Col>
          </Row>
          <ButtonGroup>
            <Button
              style={{ marginRight: '2rem' }}
              data-testid='submitButton'
              onClick={handleSubmitButtonClick}
            >
              Submit application
            </Button>
            <Spacer mt={1} className='mobile-spacer' />
            <Button
              onClick={handleBackClick}
              data-testid='backButton'
              theme='secondary'
            >
              Back
            </Button>
          </ButtonGroup>
        </ContentWrapper>
      </ContentContainer>
      <RefreshAlert />
    </>
  );
};

export default ReviewAndSubmitPage;
