import React from 'react';
import { H2 } from '@snsw/react-component-library';
import { Constants } from 'constants/constants';

interface WhatHappenNextProp {
  businessTransaction: string;
  isDKTToComplete: boolean;
  drivingRecordLinkRef: any;
}

const WhatHappensNext: React.FC<WhatHappenNextProp> = ({
  businessTransaction,
  isDKTToComplete,
  drivingRecordLinkRef,
}) => {
  return (
    <>
      <H2>What happens next?</H2>
      <ul>
        {businessTransaction === Constants.ITOP2 && isDKTToComplete && (
          <li>
            You&apos;ll need to pass a driver knowledge test for your good
            behaviour to start.
            <br />
            <strong>
              All licences held by you will be suspended until you pass the
              test.
            </strong>{' '}
            You can{' '}
            <a
              href={Constants.BOOK_LICENCE_TEST_LINK}
              target='_blank'
              rel='noopener noreferrer'
            >
              book your driver knowledge test online
            </a>{' '}
            or call us on 13 77 88.
          </li>
        )}
        <li>
          While serving your good behaviour period, if you incur 2 or more
          demerit points, all licences held by you will be suspended for double
          the original suspension period.
        </li>
        <li>
          After the 12-month period is finished, all demerit points listed in
          your suspension notice letter will be cleared from your licence
          <p>
            <strong>Please note:</strong> If you have any other demerit points
            not listed in your suspension notice and/or incur 1 point during
            your good behaviour period, these points may be transferred to your
            licence after the 12-month period. If they cause you to exceed 13
            demerit points, you may receive a new suspension notice. You can{' '}
            <a
              href={Constants.REQUEST_DRIVING_RECORD}
              target='_blank'
              ref={drivingRecordLinkRef}
              rel='noopener noreferrer'
            >
              buy a driving record
            </a>{' '}
            to see a list of all your offences and when they happened.
          </p>
        </li>
      </ul>
    </>
  );
};

export default WhatHappensNext;
