import React from 'react';
const TermsAndConditionModalContent: React.FC = () => {
  return (
    <ol>
      <li>
        <strong>These Terms Apply:</strong>
        &nbsp;These Terms and Conditions, together with the&nbsp;
        <a
          className='h-hyperlink'
          href='https://www.service.nsw.gov.au/terms-use'
          target='_blank'
          rel='noopener noreferrer'
        >
          Terms of use
        </a>
        , &nbsp;
        <a
          className='h-hyperlink'
          href='https://www.service.nsw.gov.au/copyright-and-disclaimer'
          target='_blank'
          rel='noopener noreferrer'
        >
          Copyright statement
        </a>
        , &nbsp;
        <a
          className='h-hyperlink'
          href='https://www.service.nsw.gov.au/privacy'
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy statement
        </a>
        &nbsp;published on the Service NSW website (&apos;Terms&apos;), set out
        the conditions that apply to your use of the records enquiry service
        (&apos;Service&apos;). By using the Service you agree to comply with
        these Terms together with all relevant guidelines, instructions and
        procedures (&apos;Instructions&apos;) posted on this site. Nothing in
        these Terms limits or qualifies your rights or obligations under any
        legislation, including legislation relating to motor vehicles, traffic,
        roads or road transport.
      </li>
      <li>
        <strong>Changing These Terms:</strong>
        &nbsp;Transport for NSW and Service NSW may change these Terms, and
        their Instructions from time to time by republishing them online. You
        agree that by doing this, you have been provided with sufficient notice
        of the variation. You should read the Terms and Instructions each time
        before you use the Service in case they have changed. If you do not
        agree to any change, you should not continue to use the Service.
      </li>
      <li>
        <strong>Correct information:</strong>
        &nbsp;You warrant that all information, including personal information
        that you provide in your application to Elect to be of Good Behaviour is
        accurate, true and complete.
      </li>
      <li>
        <strong>Incorrect information:</strong>
        &nbsp;You are responsible for any additional expenses you may incur if
        you submit incorrect information and Transport for NSW and Service NSW
        acts upon it.
      </li>
      <li>
        <strong>Security of Information:</strong>
        &nbsp;You are responsible for taking reasonable care in how you access
        the Service, including using appropriate firewalls and anti-virus
        software. No data transmission over the Internet can be guaranteed as
        totally secure and communications to this Service may be intercepted or
        altered in transit. Although Transport for NSW and Service NSW strive to
        protect such information, they do not represent or warrant and cannot
        ensure the security of any information which is transmitted when you use
        the Service. When using this Service you transmit and receive
        information at your own risk and Transport for NSW and Service NSW bear
        no liability.
      </li>
      <li>
        <strong>Unavailability of Service:</strong>
        &nbsp;You acknowledge and agree that from time to time the Service may
        be unavailable.
      </li>
      <li>
        <strong>Jurisdiction:</strong>
        &nbsp;These Terms, and your application to use the Service are governed
        by the laws of New South Wales, Australia, and you irrevocably submit to
        the jurisdiction of the courts of New South Wales.
      </li>
      <li>
        <strong>General:</strong>
        &nbsp;A reference to any legislation includes all delegated legislation
        made under it, including regulations and rules, and amendments,
        consolidations, replacements or re-enactments of any of them. A
        reference to an entity, whether statutory or not, which ceases to exist
        or whose powers or functions are transferred to another body is a
        reference to the body which replaces it or which substantially succeeds
        to its powers or functions. Specifying anything in these Terms after the
        words &apos;include&apos; or &apos;for example&apos; or similar
        expressions does not limit what else is included.
      </li>
      <li>
        <strong>Approved form:</strong>
        &nbsp;This System is an approved form of making an election to be of
        good behaviour for the purposes of section 36(2)(a) of the{' '}
        <i> Road Transport Act 2013.</i>
      </li>
    </ol>
  );
};

export default TermsAndConditionModalContent;
