import styled from 'styled-components';
import {
  Heading,
  InPageAlert,
  FormRadioGroup,
} from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';

export const MainHeading = styled(Heading)`
  margin-top: 1.125rem;
  ${Breakpoint('sm')`
  margin-bottom: 0rem;
  margin-top: 0rem;
  `}
`;

export const SubHeading = styled(Heading)`
  margin-bottom: 0rem;
`;

export const TitleHeading = styled(Heading)`
  font-size: 1.375rem;
`;

export const StyledInPageAlert = styled(InPageAlert)`
  width: 48rem;
`;

export const StyledFormRadioGroup = styled(FormRadioGroup)`
  legend {
    position: absolute;
    clip: rect(0rem, 0rem, 0rem, 0rem);
    padding: 0;
    border: 0;
    height: 1rem;
    width: 0.5rem;
    overflow: hidden;
  }
`;
