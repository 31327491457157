import styled from 'styled-components';
import Breakpoint from './Breakpoint';
import { space } from 'styled-system';
import { isIE } from 'react-device-detect';

interface ContainerProps {
  maxWidth?: string;
  minHeight?: string;
  padding?: string;
}

const Container = styled.div<ContainerProps>`
  ${space};
  max-width: ${(props) => props.maxWidth || '1224px'};
  min-width: 320px;
  min-height: ${(props) => props.minHeight || '1px'};
  padding: 2rem 20px;
  margin-left: auto;
  margin-right: auto;

  ${Breakpoint('sm')`
    padding-bottom: ${isIE ? '8rem' : '3rem'};
  `}

  ${Breakpoint('md')<ContainerProps>`
    padding:  ${(props) => props.padding || '3rem 30px'};
    min-height: ${(props) => props.minHeight || '240px'};
  `}
`;

export default Container;
