import styled from 'styled-components';
import { TextLink } from '@snsw/react-component-library';

export const NoWrapSpan = styled.span`
  white-space: nowrap;
`;

export const NoWrapTextLink = styled(TextLink)`
  white-space: nowrap;
`;
