import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriveMessageDetails } from 'interfaces/IDriveMessageDetails';

export interface Question {
  id: number;
  text: string;
  answer: boolean;
}

export interface DeclarationForm {
  businessTransaction: string;
  questions: Question[];
  customerSubjectedToItop2Message: DriveMessageDetails | null;
}

export const initialState: DeclarationForm = {
  businessTransaction: '',
  questions: [],
  customerSubjectedToItop2Message: null,
};

export const declarationFormSlice = createSlice({
  name: 'declarationForm',
  initialState,
  reducers: {
    setBusinessTransaction: (state, action: PayloadAction<string>) => {
      state.businessTransaction = action.payload;
    },
    setQuestions: (state, action: PayloadAction<Question[]>) => {
      const questions = action.payload;
      state.questions = questions.map((question, index) => {
        return {
          ...question,
          answer: state.questions[index]
            ? state.questions[index].answer
            : false,
        };
      });
    },
    setAnswer: (
      state,
      action: PayloadAction<{ id: number; answer: boolean }>
    ) => {
      const index = state.questions.findIndex(
        (question) => question.id === action.payload.id
      );
      state.questions[index].answer = action.payload.answer;
    },
    setCustomerSubjectedToItop2: (
      state,
      action: PayloadAction<DriveMessageDetails>
    ) => {
      state.customerSubjectedToItop2Message = action.payload;
    },
    resetDeclarationForm: () => initialState,
  },
});

export const declarationFormSliceActions = declarationFormSlice.actions;
export const declarationFormReducer = declarationFormSlice.reducer;
