import { analyticsServiceV2 } from '@rmstransactions/components';
import {
  stepBridgingPage,
  stepPersonalDetailsPage,
  stepSuspensionDetailsPage,
  stepDeclarationPage,
  stepReviewAndSubmitPage,
  stepGoodBehaviourPage,
  stepErrorPage,
} from 'config/steps';

const { defaultAnalyticsNullValue } = analyticsServiceV2;

export interface RoutePathParams {
  receiptUUID?: string;
  errorCode?: string;
}

const transactionName = 'Elect good behaviour';
const transactionType = 'apply';

export const errorPath = '/error';

export type errorHistoryState = undefined | Record<string, any>;

export type confirmationHistoryState = undefined | Record<string, any>;

// Google analytics
export const googleAnalyticsDefaultInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: 'unknown',
  transaction_sub_type: defaultAnalyticsNullValue,
};
export const googleAnalyticsTransactionInfo = {
  [errorPath]: {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepErrorPage,
    transaction_sub_type: 'api',
  },
  '/bridging-page': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepBridgingPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/personal-details': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepPersonalDetailsPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/suspension-details': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepSuspensionDetailsPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/declaration-details': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepDeclarationPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/review-submit': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepReviewAndSubmitPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/good-behaviour': {
    transaction_name: transactionName,
    transaction_type: transactionType,
    transaction_step: stepGoodBehaviourPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
};
export const googleAnalyticsViewReceiptInfo = {
  transaction_name: transactionName,
  transaction_type: transactionType,
  transaction_step: stepGoodBehaviourPage,
  transaction_sub_type: 'view receipt',
};
